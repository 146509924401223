import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CompaniesService } from '@cosmos/angular/api';
import { UsersService } from '@cosmos/angular/api';
import { CompanyDialogComponent } from '../dialogs/company-dialog/company-dialog.component';
import { ActionDefinition, ColumnDefinition } from '@cosmos/angular/ui';
import { TranslateService } from '@ngx-translate/core';
import { ErrorService, StateService } from '@cosmos/angular/utils';
import { Company, User } from '@cosmos/shared';
import { ConfirmationDialogComponent } from '@cosmos/angular/ui';

@Component({
  selector: 'cosmos-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.css']
})

export class CompaniesComponent implements OnInit {

  users: User[] = [];
  companies: Company[] = [];
  user: User;
  confirmDialog: MatDialogRef<ConfirmationDialogComponent>;

  actions: ActionDefinition[] = [
    {name: 'edit', label: 'table.actions.edit', icon: 'edit', sticky: true, role: "superadmin"},
    {name: 'delete', label: 'table.actions.delete', icon: 'delete', sticky: true, role: "superadmin"},
  ];

  columns: ColumnDefinition[] = [
    {name: '_id', label: 'table.labels.id', visible: true, sortable: true, draggable: true},
    {name: 'name', label: 'table.labels.name', visible: true, sortable: true, draggable: true},
    {name: 'parent_company.name', label: 'table.labels.parent-company', visible: true, sortable: true, draggable: true},
    {name: 'account_manager.name', label: 'table.labels.account-manager', visible: true, sortable: true, draggable: true},
  ];

  constructor(
    private companiesService: CompaniesService,
    public companyDialog: MatDialog,
    private usersService: UsersService,
    private stateService: StateService,
    private translateService: TranslateService,
    private errorService: ErrorService,
  ) {

   }

  ngOnInit(): void {
    this.user = this.stateService.getCurrentUser();

    this.getData();
    if(this.user.roles.indexOf("superadmin") > -1){ //If user is superadmin, gets all users and allows edit/delete of company
      this.usersService.find().subscribe({
        next: (response) => {this.users = response.body;},
        error: (e) => this.errorService.handleError(e),
      });
    }
    else {
      this.users.push(this.stateService.getCurrentUser());
    }


  }

  openDialog(id?: string, object?: any): void {
    const companies = this.companies;
    let create = true;
    const users = this.users;
    const company = object;
    const roles = this.user.roles;

    if (id)  {
      create = false;
    }

     const dialogRef = this.companyDialog.open(CompanyDialogComponent, {
       data: {company, companies, create, users, roles }
     });

     dialogRef.afterClosed().subscribe(result => {
       if (result !== undefined) {
         if (create) {
          this.companiesService.create(result).subscribe({
            error: (e) => this.errorService.handleError(e),
            complete: () => { this.getData();}
          });
         } else {
          this.companiesService.update(result).subscribe({
            error: (e) => this.errorService.handleError(e),
            complete: () => { this.getData();}
          });
         }
     }
     });
  }

  getData(): void {

    var query;
    //Checks if the current user is a superadmin, so they see all or their own company
    if(this.stateService.getCurrentUser().roles.indexOf("superadmin") > -1) {
      query = '/?populate_parent_company=name&populate_account_manager=name';
    }
    else query = "?populate_parent_company=name&populate_account_manager=name";
    this.companiesService.find(query).subscribe({
      next: (response) => {this.companies = response.body;},
      error: (e) => this.errorService.handleError(e),
    });


  }
  deleteElement(row: any): void {
    this.confirmDialog = this.companyDialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });

    this.confirmDialog.componentInstance.confirmMessage = this.translateService.instant("dialog.subtitle.delete.company") + ' ' + row.name;

    this.confirmDialog.afterClosed().subscribe(result => {
      if(result) {
        this.companiesService.delete(row._id).subscribe({
          error: (e) => this.errorService.handleError(e),
          complete: () => this.getData()
        })
      }
    })
  }

  rowAction(event: any): void {
    if (event.action === 'edit') {
      this.openDialog(event.row._id, event.row);
    } else if (event.action === 'delete') {
      this.deleteElement(event.row);
    }
  }

}
