import { NgModule } from '@angular/core';
import { CommonModule, DatePipe, DecimalPipe, JsonPipe } from '@angular/common';

import { MaterialModule } from './material/material.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FormsModule } from '@angular/forms';
import { LoginModule } from './login/login.module';
import { DataTableComponent } from './data-table/data-table.component';
import { UserDropdownComponent } from './navigation/user-dropdown/user-dropdown.component';
import { LanguageDropdownComponent } from './navigation/language-dropdown/language-dropdown.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BreadcrumbsComponent } from './navigation/breadcrumbs/breadcrumbs.component';
import { NotificationDropdownComponent } from './navigation/notification-dropdown/notification-dropdown.component';
import { DataPropertyGetterPipe } from './data-table/data-property-getter-pipe/data-property-getter.pipe';
import { DynamicFormModule } from './dynamic-form/dynamic-form.module';
import { RouterModule } from '@angular/router';
// import { FileUploadComponent } from './file-upload/file-upload.component';
import { DragndropDirective } from './file-upload/dragndrop.directive';
import { PasswordRecoveryComponent } from './password-recovery/password-recovery.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { GwCardComponent } from './cards/gw-card/gw-card.component';
import { DeviceCardComponent } from './cards/device-card/device-card.component';
import { ProjectCardComponent } from './cards/project-card/project-card.component';
import { StageCardComponent } from './cards/stage-card/stage-card.component';
import { MeasurementCardComponent } from './cards/measurement-card/measurement-card.component';
import { ChartComponent } from './graphs/chart/chart.component';
import { NgChartsModule } from 'ng2-charts';
import { StylesDropdownComponent } from './navigation/styles-dropdown/styles-dropdown.component';
// import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatBadgeModule } from '@angular/material/badge';
import { NotificationSettingsComponent } from './navigation/notification-settings/notification-settings.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { PredictiveChartComponent } from './graphs/predictive-chart/predictive-chart.component';
import { ForgeViewerModule } from './forge-viewer/ng2-adsk-forge-viewer.module';
import { StrengthInsightsCardComponent } from './cards/stength-insights-card/stength-insights-card.component';
import { LineChartComponent } from './graphs/line-chart/line-chart.component';
import { UserGenericComponent } from './pages/user/user.component';
import { SettingsGenericComponent } from './pages/settings/settings.component';
import { SupportSimulatorComponent } from './pages/support-simulator/support-simulator.component';
import { ProjectComponent } from './pages/project/project.component';
import { DialogComponent } from './dialog/dialog.component';
import { StageComponent } from './pages/stage/stage.component';
import { MeasurementComponent } from './pages/measurement/measurement.component';
import { SimDetailsComponent } from './pages/sim-details/sim-details.component';
import { SimulationQuoteDialogComponent } from './pages/sim-details/simulation-quote-dialog/simulation-quote-dialog.component';
import { PoolChartComponent } from './graphs/pool-chart/pool-chart.component';


@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MaterialModule,
    LoginModule,
    DynamicFormModule,
    RouterModule,
    FormsModule,
    MatSnackBarModule,
    NgChartsModule,
    // MatButtonToggleModule,
    MatBadgeModule,
    ReactiveFormsModule,
    ForgeViewerModule,

  ],
  declarations: [
    DataTableComponent,
    LanguageDropdownComponent,
    UserDropdownComponent,
    BreadcrumbsComponent,
    NotificationDropdownComponent,
    DataPropertyGetterPipe,
    DragndropDirective,
    PasswordRecoveryComponent,
    PasswordResetComponent,
    GwCardComponent,
    DeviceCardComponent,
    ProjectCardComponent,
    StageCardComponent,
    MeasurementCardComponent,
    ChartComponent,
    PoolChartComponent,
    PredictiveChartComponent,
    StylesDropdownComponent,
    NotificationSettingsComponent,
    ConfirmationDialogComponent,
    StrengthInsightsCardComponent,
    LineChartComponent,
    UserGenericComponent,
    SettingsGenericComponent,
    SupportSimulatorComponent,
    ProjectComponent,
    DialogComponent,
    StageComponent,
    MeasurementComponent,
    SimDetailsComponent,
    SimulationQuoteDialogComponent,
  ],
  exports: [
    // MODULES
    MaterialModule,
    LoginModule,
    ForgeViewerModule,

    // COMPONENTS
    DataTableComponent,
    LanguageDropdownComponent,
    UserDropdownComponent,
    BreadcrumbsComponent,
    NotificationDropdownComponent,
    StylesDropdownComponent,
    DynamicFormModule,
    NotificationSettingsComponent,
    ProjectCardComponent,
    MeasurementCardComponent,
    StageCardComponent,
    GwCardComponent,
    DeviceCardComponent,
    ChartComponent,
    PoolChartComponent,
    PredictiveChartComponent,
    ConfirmationDialogComponent,
    StrengthInsightsCardComponent,
    LineChartComponent,
    UserGenericComponent,
    SettingsGenericComponent,
    SupportSimulatorComponent,
    ProjectComponent,
    DialogComponent,
    StageComponent,
    MeasurementComponent,
    SimDetailsComponent,
    SimulationQuoteDialogComponent,
  ],
  providers: [
    TranslateService,
    [DatePipe],
    [DecimalPipe],
    [JsonPipe],
  ]
})
export class AngularUiModule {}


