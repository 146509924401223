import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorService } from '@cosmos/angular/utils';
import { Gateway } from '@cosmos/shared';
import { catchError, map, Observable } from 'rxjs';
import { environment } from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GatewaysService {

  constructor(
    private http: HttpClient,
    private errorService: ErrorService,
  ) { }

  find(query=''): Observable<HttpResponse<Gateway[]>> {
    return this.http
      .get<Gateway[]>(`${environment.API_URL}/gateways${query}`, {observe: 'response'})
        .pipe(
          map(response => {return response;}),
          catchError( (error) => this.errorService.handleError(error))
        );
  }

  findChirped(query=''): Observable<HttpResponse<Gateway[]>> {
    return this.http
      .get<Gateway[]>(`${environment.API_URL}/gateways/chirp${query}`, {observe: 'response'})
        .pipe(
          map(response => {return response;}),
          catchError( (error) => this.errorService.handleError(error))
        );
  }

  findByUser(query = ''): Observable<HttpResponse<Gateway[]>> {
    return this.http
      .get<Gateway[]>(`${environment.API_URL}/gateways/usergateways${query}`, {observe: 'response'})
        .pipe(
          map(response => {return response;}),
          catchError( (error) => this.errorService.handleError(error))
        );
  }


  findById(id: string, query = ''): Observable<HttpResponse<Gateway>> {
    return this.http
      .get<Gateway>(`${environment.API_URL}/gateways/${id}${query}`, {observe: 'response'})
      .pipe(
        map(response => {return response;}),
        catchError( (error) => this.errorService.handleError(error))
      );
  }

  create(body: Gateway) {
    return this.http
      .post(`${environment.API_URL}/gateways`, body, {observe: 'response'})
      .pipe(
        map(response => {return response;}),
        catchError( (error) => this.errorService.handleError(error))
      );
  }

  update(body: any) {
    return this.http
      .patch(`${environment.API_URL}/gateways/${body[0]}`, body[1], {observe: 'response'})
      .pipe(
        map(response => {return response;}),
        catchError( (error) => this.errorService.handleError(error))
      );
  }

  delete(id: string) {
    return this.http
      .delete(`${environment.API_URL}/gateways/${id}`, {observe: 'response'})
      .pipe(
        map(response => {return response;}),
        catchError( (error) => this.errorService.handleError(error))
      );
  }

}
