import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';


@Injectable({
  providedIn: 'root'
})
export class ConversionService {

  constructor(
    private translateService: TranslateService,
  ) {

  }

  getTransmissionInfo(transmission) {
    let color: string = 'green';
    let icon: string = 'signal_cellular_4_bar';
    let text: string = 'signal.good';

    if (transmission.rssi < -110) {
      text = 'signal.weak';
      color = 'yellow';
    }

    return [color, icon, this.translateService.instant(text)];
  }

  getBatteryInfo(battery: number) {
    let color: string = 'green';
    let icon: string = 'battery_6_bar';
    let text: string = 'battery.good';

    if (battery < 3) {
      text = 'battery.charge';
      color = 'red';
      icon = 'battery_1_bar';
    } else if (battery < 3.2) {
      text = 'battery.low';
      color = 'yellow';
      icon = 'battery_3_bar'
    }
    return [color, icon, this.translateService.instant(text)];
  }
}
