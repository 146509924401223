import { Component, OnInit } from '@angular/core';
import { StateService } from '@cosmos/angular/utils';
import { User } from '@cosmos/shared';

@Component({
  selector: 'cosmos-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  user: User;

  constructor( private stateService: StateService) {
    this.user = this.stateService.getCurrentUser();
   }

  ngOnInit(): void {
  }

}
