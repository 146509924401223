import { FormFieldBase, FormFieldDropdown, FormFieldTextbox, FormFieldCheckBox } from "@cosmos/angular/ui";
import { FakeMissingTranslationHandler } from "@ngx-translate/core";
import { of } from "rxjs";

export class FormFieldsClass {
  formFields: FormFieldBase<any>[];

  constructor(create: boolean, data:any, users:any[], companies: any[], superadmin: boolean) {
    // COMMON FIELDS
    this.formFields = [
        new FormFieldTextbox({
            key: 'name',
            label: 'fields.name',
            value: create?'':data.name,
            type:'text',
            required: true,
            order: 1
        }),
        new FormFieldTextbox({
            key: 'description',
            label: 'fields.description',
            value: create?'':data.description,
            type:'text',
            required: false,
            order: 2
        }),
    ]

    // EDIT ONLY FIELDS
    if(!create && superadmin) {
      this.formFields.push(
        new FormFieldDropdown({
          key: 'shared_w',
          label: 'fields.shared_W',
          value: create?'':data.shared_w,
          options: users,
          order: 5,
          required: false,
          multiple: true,

    }),
      new FormFieldDropdown({
          key: 'shared_r',
          label: 'fields.shared_R',
          value: create?'':data.shared_r,
          options: users,
          multiple: true,
          required: false,
          order: 6
        }),
      );
    }
    if(superadmin) {
      this.formFields.push(
        new FormFieldDropdown({
          key: 'owner',
          label: 'fields.owner',
          value: create?'':data.owner,
          options: users,
          required: true,
          order: 3
        }),
        new FormFieldDropdown({
          key: 'company',
          label: 'fields.company',
          value: create?'':data.company,
          options: companies,
          required: true,
          order: 4
        }),
        new FormFieldCheckBox({
          key: 'premium',
          label: 'fields.premium',
          value: create?false:data.premium?data.premium:false,
          order: 10
        }),
        new FormFieldCheckBox({
          key: 'bim',
          label: 'fields.bim',
          value: create?false:data.bim?true:false,
          order: 11
        })
      );
    }
  }

  getCreateFields()
  {
    return of(this.formFields.sort((a, b) => a.order - b.order));
  }
}
