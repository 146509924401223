<div *ngIf="simulation" class="row my-4">
    <div class="col-md-6 mb-3">
        <mat-card appearance="outlined">
            <mat-card-header>
                <div mat-card-avatar class="text-center card-avatar">{{simulation.name[0]}}</div>
                <mat-card-title class="mb-1">{{simulation.name}}</mat-card-title>
                <mat-card-subtitle>{{ 'simulations.subtitle' | translate }} - {{(simulation.completed ? 'simulations.completed' : 'simulations.ongoing') | translate}}</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
              <mat-list>
                <mat-list-item>
                  <mat-icon matListItemIcon>schedule</mat-icon>
                  <div matListItemTitle>{{ simulation.createdAt | date:'dd/MM/yyyy HH:mm:ss' }}</div>
                  <div matListItemLine>{{ "simulations.createdAt" | translate }}</div>
                </mat-list-item>

                <mat-divider></mat-divider>

                <mat-list-item>
                  <mat-icon matListItemIcon>description</mat-icon>
                  <div matListItemTitle>{{ citiesRef?citiesRef[simulation.region].region:''}}</div>
                  <div matListItemLine>{{ "simulations.card.region" | translate }}</div>
                </mat-list-item>
                <mat-list-item>
                    <mat-icon matListItemIcon>event</mat-icon>
                    <div matListItemTitle>{{ simulation.startDate | date:'dd/MM/yyyy' }}</div>
                    <div matListItemLine>{{ "simulations.card.startDate" | translate }}</div>
                </mat-list-item>
                <mat-list-item>
                  <mat-icon matListItemIcon>sync</mat-icon>
                  <div matListItemTitle>{{ simulation.concreteCycles }}</div>
                  <div matListItemLine>{{ "simulations.card.concreteCycles" | translate }}</div>
                </mat-list-item>
                <mat-list-item>
                  <mat-icon matListItemIcon>insights</mat-icon>
                  <div matListItemTitle>{{ simulation.concreteMixture.name }}</div>
                  <div matListItemLine>{{ "simulations.card.concrete" | translate }}</div>
                </mat-list-item>
                <mat-list-item>
                <mat-icon matListItemIcon>query_stats</mat-icon>
                  <div matListItemTitle>{{ simulation.strengthReq }} MPa</div>
                  <div matListItemLine>{{ "simulations.card.strengthReq" | translate }}</div>
                </mat-list-item>
                <mat-list-item *ngIf="!public && simulation.public">
                  <mat-icon matListItemIcon>share</mat-icon>
                      <div matListItemTitle>{{ publicUrl }}</div>
                      <div matListItemLine>{{ "simulations.card.public-url" | translate }}</div>
                    </mat-list-item>
              </mat-list>
            </mat-card-content>
            <mat-card-actions *ngIf="!public">
              <button mat-raised-button color="primary" [disabled]="simulation.quoteRequested" (click)="openQuoteDialog()">{{ simulation.quoteRequested?'simulations.button.already-requested':'simulations.button.request-proposal' | translate}}</button>
              <mat-slide-toggle *ngIf="!public" class="mt-3 mx-3" color="primary" [checked]="simulation.public" (change)="shareToggle($event)">{{ "simulations.share-public" | translate }}</mat-slide-toggle>
            </mat-card-actions>
        </mat-card>
    </div>
    <!-- ADD ANOTHER COL FOR THE CONCRETE CURVE CHART -->
    <div class="col-md-6">
      <p class="h4">{{ "simulations.new.selected-concrete-title" | translate}}</p>
        <mat-card  appearance="outlined"  class="">
            <mat-card-header>
                <div mat-card-avatar class="text-center card-avatar">
                    <mat-icon> insert_chart_outlined</mat-icon>
                </div>
                <mat-card-title class="mb-1">{{simulation.concreteMixture.name}}</mat-card-title>
                <mat-card-subtitle>{{ "measurement.chart.subtitle" | translate }}</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <cosmos-line-chart [id]="selectedConcreteChart" [dataX]="concreteChartData.x" [dataY]="concreteChartData.y" [threshold]="simulation.concreteMixture.fck"></cosmos-line-chart>
                <p class="h5 mt-3">{{ "simulations.chart.concrete-7days" | translate }} <span class="highlight">{{simulation.concreteMixture.lab_results[2].strength}} MPa</span></p>
              </mat-card-content>
        </mat-card>
    </div>
</div>
<div class="row mb-4" *ngIf="simulation">
  <div class="col">
    <!-- <h4>Ahorro potencial</h4> -->
    <mat-card  appearance="outlined"  class="my-2">
      <mat-card-header>
          <div mat-card-avatar class="text-center card-avatar">
              <mat-icon>savings</mat-icon>
          </div>
          <mat-card-title class="mb-1">{{ "simulations.savings.title" | translate}}</mat-card-title>
          <mat-card-subtitle>{{ "simulations.savings.subtitle" | translate}}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <p class="h5" *ngIf="results.percentMatFixed >= 0 && results.percentMatReg >= 0">
          {{ "simulations.savings.both-savings" | translate }} 
        </p>

        <p class="h5" *ngIf="(results.percentMatFixed < 0 || results.percentMatReg < 0) && !(results.percentMatFixed < 0 && results.percentMatReg < 0)">
          {{"simulations.savings.one-savings" | translate }} 
        </p>

        <p class="h5" *ngIf="results.percentMatFixed < 0 && results.percentMatReg < 0">
          {{"simulations.savings.no-savings" | translate }}
        </p>

        <mat-list>
          <!-- VS CICLOS FIJOS -->
            <!-- POSITIVO -->
            <mat-list-item *ngIf="results.percentMatFixed >= 0">
              <mat-icon matListItemIcon color="primary">check_circle</mat-icon>
              <div matListItemTitle>
                <span class="h4 highlight">{{ results.percentMatFixed | number: '1.0-0'}}% 
                {{"simulations.savings.faster" | translate }} </span>{{"simulations.savings.vsfixed" | translate }}
              </div>
              <div matListItemLine>
                {{"simulations.savings.in" | translate}} {{results.daysMat}} días en vez de en {{results.daysFixed}}.
              </div>
            </mat-list-item>
            <!-- NEGATIVO -->
            <mat-list-item *ngIf="results.percentMatFixed < 0">
              <mat-icon matListItemIcon color="primary">error</mat-icon>
              <div matListItemTitle>
                <span class="h4 highlight">{{ (results.percentMatFixed*-1) | number: '1.0-0'}}% 
                  {{"simulations.savings.slower" | translate }} </span>{{"simulations.savings.vsfixed" | translate }}
              </div>
              <div matListItemLine>
                {{"simulations.savings.in" | translate}} {{results.daysMat}} días en vez de en {{results.daysFixed}}.
              </div>
            </mat-list-item>
          
          <!-- VS EHE -->
            <!-- POSITIVO -->
            <mat-list-item *ngIf="results.percentMatReg >= 0">
            <mat-icon matListItemIcon color="primary">check_circle</mat-icon>
              <div matListItemTitle>
                <span class="h4 highlight">{{ results.percentMatReg | number: '1.0-0'}}% 
                {{"simulations.savings.faster" | translate }}</span> {{"simulations.savings.vsehe" | translate}}</div>
              <div matListItemLine>
                {{"simulations.savings.in" | translate}} {{results.daysMat}} días en vez de en {{results.daysReg}}.
              </div>
            </mat-list-item>
            <!-- NEGATIVO -->
            <mat-list-item *ngIf="results.percentMatReg < 0">
              <mat-icon matListItemIcon color="primary">error</mat-icon>
                <div matListItemTitle>
                  <span class="h4 highlight">{{ (-1* results.percentMatReg) | number: '1.0-0'}}% 
                    {{"simulations.savings.slower" | translate }}</span> {{"simulations.savings.vsehe" | translate}}</div>
                <div matListItemLine>
                  {{"simulations.savings.in" | translate}} {{results.daysMat}} días en vez de en {{results.daysReg}}.
                </div>
              </mat-list-item>
        </mat-list>

        <div class="mt-4" *ngIf="maturityLonger">
          <h5 class="highlight">Atención!</h5>
          <p>Existen fases de tu proyecto en las que el método de la madurez determina que, con los datos introducidos, tu hormigón necesita más fraguado para alcanzar tus requisitos de resistencia.</p>
          <p>No obstante, con un adecuado plan de control en que se implementen diversas metodologías de control, tendrás suficientes datos para tomar decisiones de forma segura, garantizando la integridad de la estructura</p>
        </div>

        <h6 class="mt-5" *ngIf="!simulation?.details">Si estos números te convencen, consulta la planificación detallada, donde podrás comparar los plazos de ejecucion para cada ciclo de hormigonado según los distintos métodos constructivos propuestos.</h6>
        
      </mat-card-content>

      <mat-card-actions class="text-center" *ngIf="!simulation?.details">
        <button mat-raised-button color="primary" (click)="showDetailedPlanning()">Ver todos los detalles de la simulación</button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>

<!-- ESG -->
<div class="row mb-4" *ngIf="simulation">
  <div class="col">
    <!-- <h4>Ahorro potencial</h4> -->
    <mat-card  appearance="outlined"  class="my-2">
      <mat-card-header>
          <div mat-card-avatar class="text-center card-avatar-green">
              <mat-icon>eco</mat-icon>
          </div>
          <mat-card-title class="mb-1">Sostenibilidad e impacto ambiental (ESG + LCA)</mat-card-title>
          <mat-card-subtitle>El hormigón digital, al ser más eficiente, te permite construir de forma más verde</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <p class="h5" *ngIf="results.percentMatFixed >= 0 && results.percentMatReg >= 0">
          Los resultados indican una construcción más eficiente, reduciendo la huella de carbono del proyecto. El análisis del ciclo de vida (LCA) afectará positivamente a las políticas ESG y certificaciones de Sostenibilidad. 
        </p>

        <div *ngIf="(results.percentMatFixed < 0 || results.percentMatReg < 0) && !(results.percentMatFixed < 0 && results.percentMatReg < 0)">
         <p class="h5">Depende de con respecto a que método constructivo evalúes el impacto ambiental del proyecto utilizando hormigón digital, podrás beneficiarte de una reducción en la huella de carbono del mismo.</p>
        </div>

        <div *ngIf="results.percentMatFixed < 0 && results.percentMatReg < 0">
         <p class="h5">La simulación presenta ciclos más largos utilizando el hormigón digital.</p>
         <p>Con el control de calidad adicional, puedes garantizar la seguridad y la integridad de la estructura, aunque con los parámetros de la simulación no se puede estimar ninguna mejora en los aspectos de sostenibilidad del proyecto (ESG o LCA).</p>
        </div>

        <mat-list>
          <!-- VS FIXED -->
          <mat-list-item *ngIf="esgResults.fixed > 0">
            <mat-icon matListItemIcon style="color: rgb(31, 192, 31);;">check_circle</mat-icon>
              <div matListItemTitle>
                <span class="h4 highlight-green">{{ esgResults.fixed | number: '1.0-2'}}
                kgCO2eq. </span> de reducción de impacto, respecto a los ciclos fijos de hormigonado.
              </div>
              <div matListItemLine>
                Basado en el ahorro potencial de {{results.daysFixed - results.daysMat}} días.
              </div>
          </mat-list-item>

          <mat-list-item *ngIf="esgResults.fixed <= 0">
            <mat-icon matListItemIcon color="primary">help</mat-icon>
              <div matListItemTitle>
                Con respecto a ciclos fijos de hormigonado y con los parámetros de la simulación dados, no se puede estimar la reducción de impacto.
              </div>
              <div matListItemLine>
                Contacta con nosotros para que te asesoremos de que manera puedes conseguir mejores ritmos constructivos.
              </div>
          </mat-list-item>

          <!-- VS EHE -->
          <mat-list-item *ngIf="esgResults.ehe > 0">
            <mat-icon matListItemIcon style="color: rgb(31, 192, 31);;">check_circle</mat-icon>
              <div matListItemTitle>
                <span class="h4 highlight-green">{{ esgResults.ehe | number: '1.0-2'}}
                kgCO2eq. </span> de reducción de impacto, respecto a los plazos establecidos por la EHE.
              </div>
              <div matListItemLine>
                Basado en el ahorro potencial de {{results.daysReg - results.daysMat}} días.
              </div>
          </mat-list-item>

          <mat-list-item *ngIf="esgResults.ehe <= 0">
            <mat-icon matListItemIcon color="primary">help</mat-icon>
              <div matListItemTitle>
                Con respecto a los plazos de la EHE y con los parámetros de la simulación dados, no se puede estimar la reducción de impacto.
              </div>
              <div matListItemLine>
                Contacta con nosotros para que te asesoremos de que manera puedes conseguir mejores ritmos constructivos.
              </div>
          </mat-list-item>
         </mat-list>

        <!-- <p class="bold small d-flex align-items-center pt-2">
          <img src="/assets/mace.png" style="padding-right: 10px;" height="20px"/>
          <span style="border-bottom: 1px dotted ;"><a class="text-decoration-none" style="color:inherit" target="_blank" href="https://www.macegroup.com/locations/uk-and-europe/spain">
            Los datos de la estimación se calculan a partir de estudios realizados por MACE Management Services SA, empresa líder en gestión medioambiental de proyectos de construcción.
          </a></span>
        </p> -->

         <!-- <p class="bold small"><span style="border-bottom: 1px dotted ;" matTooltipHideDelay="1000" matTooltip="Los datos de la estimación se calculan a partir de estudios realizados por MACE Management Services SA, empresa líder en gestión medioambiental de proyectos de construcción.">¿Cómo se hace el cálculo?</span></p> -->

        <!-- FORM concrete cubic meters -->
        <!-- <form [formGroup]="form">
          <div class="form-row d-flex align-items-center justify-content-center sustainability-form">
            <mat-form-field appearance="outline" style="max-width:50%">
              <input type="number" matInput formControlName="cubicMeters" placeholder="m3 hormigón" required>
            </mat-form-field>
            <button mat-raised-button color="primary" class="mx-3" [disabled]="!form.valid" (click)="calculateImpact()">Calcular ahorro de impacto ambiental</button>
          </div>
        </form> -->

          <!-- <cosmos-data-table
            class="centered"
            *ngIf="esgTableData && simulation.cubicMeters"
            [tableData]="esgTableData"
            [tableColumns]="columnsESG"
            [isFilterable]="false"
            [toggleColumnsVisibility]="false"
            [rowActions]="[]"
            [tableFooter]="true"
            
            (rowAction)="rowAction($event)">
          </cosmos-data-table> -->

          <!-- <p class="h5 text-center">Próximamente podrás descubrir y cuantificar los beneficios que CoSMoS te ofrece en relación a la sostenibilidad.</p> -->
        
      </mat-card-content>
    </mat-card>
  </div>
</div>
<!-- TABLE -->
<div class="row mb-4" *ngIf="simulation && simulation.details">
  <div class="col">
    <!-- <h4>Detalle de planificación</h4> -->
    
    <mat-card  appearance="outlined"  class="my-2">
      <mat-card-header>
        <div mat-card-avatar class="text-center card-avatar">
            <mat-icon>calendar_month</mat-icon>
        </div>
        <mat-card-title class="mb-1">Detalle de planificación</mat-card-title>
        <mat-card-subtitle>Tabla comparativa con los plazos previstos según el hormigón digital utilizando sensores de madurez, ciclos fijos de hormigonado de 10 días laborables y ciclos establecidos en la EHE.</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <cosmos-data-table
          class="centered"
          *ngIf="simTableData"
          [tableData]="simTableData"
          [tableColumns]="columnsCycles"
          [isFilterable]="false"
          [toggleColumnsVisibility]="false"
          [rowActions]="[]"
          [tableFooter]="true"
          [preHeader]="preHeaderCycles"
          (rowAction)="rowAction($event)">
        </cosmos-data-table>

      </mat-card-content>
    </mat-card>
  </div>
</div>

<mat-list>
  <mat-divider></mat-divider>
</mat-list>

<!-- SECTION 2 - SUMMARY OF RESULTS (total time, average days per cycle) VS EHE -->
<div class="row my-4" *ngIf="simulation && simulation.details">
  <h4>Resumen de la simulación</h4>

  <div class="col-xl-3 col-md-6">
    <!-- METEO DATA -->
    <mat-card  appearance="outlined"  class="my-2">
      <mat-card-header>
          <div mat-card-avatar class="text-center card-avatar">
              <mat-icon>thermostat</mat-icon>
          </div>
          <mat-card-title class="mb-1">{{ "simulations.insight.meteo-title" | translate }}</mat-card-title>
          <mat-card-subtitle>{{ "simulations.insight.subtitle" | translate }}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <!-- AVG TEMP -->
        <div class="result-showcase">
          <div class="item-label">{{ "simulations.summary.avg-temp" | translate }}</div>
          <div class="heading-line">{{ average(regulation.arrays.temp_array) | number : '1.0-2'}} ºC</div>
        </div>
        <!-- MAX TEMP -->
        <div class="result-showcase">
          <div class="item-label">{{ "simulations.summary.max-temp" | translate }}</div>
          <div class="heading-line">{{ max(regulation.arrays.temp_array) | number : '1.0-2'}} ºC</div>
        </div>
        <!-- MIN TEMP -->
        <div class="result-showcase">
          <div class="item-label">{{ "simulations.summary.min-temp" | translate }}</div>
          <div class="heading-line">{{ min(regulation.arrays.temp_array) | number : '1.0-2'}} ºC</div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <!-- MATURITY CARD -->
  <div class="col-xl-3 col-md-6">
    <mat-card  appearance="outlined"  class="my-2">
      <mat-card-header>
          <div mat-card-avatar class="text-center card-avatar">
              <mat-icon>sensors</mat-icon>
          </div>
          <mat-card-title class="mb-1">{{ "simulations.insight.maturity-title" | translate }}</mat-card-title>
          <mat-card-subtitle>{{ "simulations.insight.subtitle" | translate }}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <!-- TOTAL TIME -->
        <div class="result-showcase">
          <div class="item-label">{{ "simulations.summary.total-days" | translate }}</div>
          <div class="heading-line">{{ maturity.arrays.temp_array.length }} {{ "units.time.days" | translate }}</div>
        </div>
        <!-- AVT TIME -->
        <div class="result-showcase">
          <div class="item-label">{{ "simulations.summary.average-cycle-days" | translate }}</div>
          <div class="heading-line">{{ average(cycleTimes.maturity) |  number : '1.0-2'}} {{ "units.time.days" | translate }}</div>
        </div>
        <!-- MAX TIME -->
        <div class="result-showcase">
          <div class="item-label">{{ "simulations.summary.max-cycle-days" | translate }}</div>
          <div class="heading-line">{{ max(cycleTimes.maturity) |  number : '1.0-2'}} {{ "units.time.days" | translate }}</div>
        </div>
        <!-- MIN TIME -->
        <div class="result-showcase">
          <div class="item-label">{{ "simulations.summary.min-cycle-days" | translate }}</div>
          <div class="heading-line">{{ min(cycleTimes.maturity) |  number : '1.0-2'}} {{ "units.time.days" | translate }}</div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <!-- FIXED CARD -->
  <div class="col-xl-3 col-md-6">
    <mat-card  appearance="outlined"  class="my-2">
      <mat-card-header>
          <div mat-card-avatar class="text-center card-avatar">
              <mat-icon>forward_10</mat-icon>
          </div>
          <mat-card-title class="mb-1">{{ "simulations.insight.fixed-title" | translate }}</mat-card-title>
          <mat-card-subtitle>{{ "simulations.insight.subtitle" | translate }}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <!-- TOTAL TIME -->
        <div class="result-showcase">
          <div class="item-label">{{ "simulations.summary.total-days" | translate }}</div>
          <div class="heading-line">{{ fixed10.arrays.temp_array.length }} {{ "units.time.days" | translate }}</div>
        </div>
        <!-- AVT TIME -->
        <div class="result-showcase">
          <div class="item-label">{{ "simulations.summary.average-cycle-days" | translate }}</div>
          <div class="heading-line">{{ average(cycleTimes.fixed) |  number : '1.0-2'}} {{ "units.time.days" | translate }}</div>
        </div>
        <!-- MAX TIME -->
        <div class="result-showcase">
          <div class="item-label">{{ "simulations.summary.max-cycle-days" | translate }}</div>
          <div class="heading-line">{{ max(cycleTimes.fixed) |  number : '1.0-2'}} {{ "units.time.days" | translate }}</div>
        </div>
        <!-- MIN TIME -->
        <div class="result-showcase">
          <div class="item-label">{{ "simulations.summary.min-cycle-days" | translate }}</div>
          <div class="heading-line">{{ min(cycleTimes.fixed) |  number : '1.0-2'}} {{ "units.time.days" | translate }}</div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <!-- REGULATION CARD -->
  <div class="col-xl-3 col-md-6">
    <mat-card  appearance="outlined"  class="my-2">
      <mat-card-header>
          <div mat-card-avatar class="text-center card-avatar">
              <mat-icon>menu_book</mat-icon>
          </div>
          <mat-card-title class="mb-1">{{ "simulations.insight.regulation-title" | translate }}</mat-card-title>
          <mat-card-subtitle>{{ "simulations.insight.subtitle" | translate }}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <!-- TOTAL TIME -->
        <div class="result-showcase">
          <div class="item-label">{{ "simulations.summary.total-days" | translate }}</div>
          <div class="heading-line">{{ regulation.arrays.temp_array.length }} {{ "units.time.days" | translate }}</div>
        </div>
        <!-- AVT TIME -->
        <div class="result-showcase">
          <div class="item-label">{{ "simulations.summary.average-cycle-days" | translate }}</div>
          <div class="heading-line">{{ average(cycleTimes.regulation) |  number : '1.0-2'}} {{ "units.time.days" | translate }}</div>
        </div>
        <!-- MAX TIME -->
        <div class="result-showcase">
          <div class="item-label">{{ "simulations.summary.max-cycle-days" | translate }}</div>
          <div class="heading-line">{{ max(cycleTimes.regulation) |  number : '1.0-2'}} {{ "units.time.days" | translate }}</div>
        </div>
        <!-- MIN TIME -->
        <div class="result-showcase">
          <div class="item-label">{{ "simulations.summary.min-cycle-days" | translate }}</div>
          <div class="heading-line">{{ min(cycleTimes.regulation) |  number : '1.0-2'}} {{ "units.time.days" | translate }}</div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<div class="container my-4 text-center" *ngIf="simulation && simulation.details && !public">
  <div class="row">
    <div class="col">
      <h5 class="my-3">Si quieres conocer más detalles sobre el por qué de estos números, y cuáles serian los pasos a seguir para digitalizar el hormigón de tus obras mediante sensores de madurez, contacta con nosotros.</h5>
      <button mat-raised-button color="primary" [disabled]="simulation.quoteRequested" (click)="openQuoteDialog()">{{ simulation.quoteRequested?'simulations.button.already-requested':'simulations.button.request-proposal' | translate}}</button>
    </div>
  </div>
</div>

<div class="row my-3"></div>