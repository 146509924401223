import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormFieldBase } from '@cosmos/angular/ui';
import { StateService } from '@cosmos/angular/utils';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { FormFieldsClass } from '../../stages/stages.forms';

@Component({
  selector: 'cosmos-stage-dialog',
  templateUrl: './stage-dialog.component.html',
  styleUrls: ['./stage-dialog.component.scss']
})
export class StageDialogComponent implements OnInit {

  title = '';
  fields: Observable<FormFieldBase<string>[]>;

  constructor(
    public dialogRef: MatDialogRef<StageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private stateService: StateService,
    private translateService: TranslateService,
  ) { }

  ngOnInit(): void {

    const users: {key: string, value: string}[] = [];
    const companies: {key: string, value: string}[] = [];
    const projects : {key: string, value: string}[] = [];

    if(this.data.users) {
      for(const user of this.data.users) users.push({key: user['_id'], value: user['name'] });
    }

    if(this.data.companies) {
      for(const company of this.data.companies) companies.push({key: company['_id'], value: company['name'] });
    }

    if(this.data.projects) {
      for(const project of this.data.projects) projects.push({key: project['_id'], value: project['name']});
    }

    if (this.data.create) {
      this.title =this.translateService.instant('dialog.title.create.stage');
      this.fields = new FormFieldsClass(true, this.data.stage, users, companies, projects, this.stateService.getCurrentUser().roles.indexOf('superadmin') > -1).getCreateFields();
    }
    else {
      this.title = this.translateService.instant('dialog.title.edit.stage') + ' ' + this.data.stage._id;
      this.fields = new FormFieldsClass(false, this.data.stage, users, companies, projects, this.stateService.getCurrentUser().roles.indexOf('superadmin') > -1).getCreateFields();
    }
  }
  handleEvent($event){
    if(this.data.create) this.dialogRef.close($event);
    else this.dialogRef.close([this.data.stage._id, $event]);
  }

}
