import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorService } from '@cosmos/angular/utils';
import { catchError, map, Observable } from 'rxjs';
import { environment } from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ForgeService {

  constructor(
    private http: HttpClient,
    private errorService: ErrorService,
  ) { }

  getAccessToken() {
    return this.http
      .get(`${environment.API_URL}/forge/token`, {observe: 'response'})
      .pipe(
        map(response => {console.log(response); return response;}),
        catchError( (error) => this.errorService.handleError(error))
      );
  }
  
  // update(body: any) {
  //   return this.http
  //     .patch(`${environment.API_URL}/stages/${body[0]}`, body[1], {observe: 'response'})
  //     .pipe(
  //       map(response => {return response;}),
  //       catchError( (error) => this.errorService.handleError(error))
  //     );
  // }

  // delete(id: string) {
  //   return this.http
  //     .delete(`${environment.API_URL}/stages/${id}`, {observe: 'response'})
  //     .pipe(
  //       map(response => {return response;}),
  //       catchError( (error) => this.errorService.handleError(error))
  //     );
  // }

}
