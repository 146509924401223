import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CompaniesService, DevicesService, MeasurementsService, ProjectsService } from '@cosmos/angular/api';
import { ActionDefinition, ColumnDefinition } from '@cosmos/angular/ui';
import { ErrorService, StateService } from '@cosmos/angular/utils';
import { Company, Device, Project, Stage, User } from '@cosmos/shared';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationDialogComponent } from '@cosmos/angular/ui';
import { DeviceDialogComponent } from '../dialogs/device-dialog/device-dialog.component';

@Component({
  selector: 'cosmos-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss']
})
export class DevicesComponent implements OnInit {

  devices: Device[] = [];
  companies: Company[] = [];
  stages: Stage[] = [];
  projects: Project[] = [];
  user: User;
  confirmDialog: MatDialogRef<ConfirmationDialogComponent>;
  queryFields = [];
  currentQuery = {};

  actions: ActionDefinition[] = [
    {name: 'edit', label: 'table.actions.edit', icon: 'edit', sticky: true},
    {name: 'delete', label: 'table.actions.delete', icon: 'delete', sticky: true},
  ];

  columns: ColumnDefinition[] = [
    {name: 'name', label: 'table.labels.name', visible: true, sortable: true, draggable: true},
    {name: 'loramac', label: 'table.labels.lora', visible: true, sortable: true, draggable: true},
    {name: 'available', label: 'table.labels.available', visible: true, sortable: true, draggable: true, type: 'boolean'},
    {name: 'project.name', label: 'table.labels.project', visible: true, sortable: true, draggable: true},
    {name: 'company.name', label: 'table.labels.company', visible: true, sortable: true, draggable: true},
    {name: 'measurement.name', label: 'table.labels.measurement', visible: true, sortable: true, draggable: true},
    {name: 'measurement.transmission', label: 'table.labels.transmission', visible: true, sortable: true, draggable: true, type: 'transmission'},
    {name: 'measurement.battery', label: 'table.labels.battery', visible: true, sortable: true, draggable: true},
  ];


  constructor(
    private devicesService: DevicesService,
    public deviceDialog: MatDialog,
    private companiesService: CompaniesService,
    private projectsService: ProjectsService,
    private stateService: StateService,
    private translateService: TranslateService,
    private errorService: ErrorService,
  ) { }

  ngOnInit(): void {
    this.user = this.stateService.getCurrentUser();

    this.getData(this.currentQuery);

    var queryC = ''; //Query for companies service
    var queryP = ''; //Query for projects service

    if(this.user.roles.indexOf("superadmin") <= -1) { //If user is NOT a superadmin
    }

    this.companiesService.find(queryC).subscribe({
      next: (response) => {this.companies = response.body; this.queryFields.push({field: "company", label: "fields.company", options: response.body})},
      error: (e) => this.errorService.handleError(e),
    });
    this.projectsService.find(queryP).subscribe({
      next: (response) => {this.projects = response.body;},
      error: (e) => this.errorService.handleError(e),
    });
  }

  openDialog(id?: string, object?: any): any {
    const device = object;
    let create = true;
    const companies = this.companies;
    var projects = this.projects;


    if (device) create = false;

    const dialogRef = this.deviceDialog.open(DeviceDialogComponent, {
      data: { device, create, companies, projects }
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result !== undefined) {
        if (create) {
          this.devicesService.create(result).subscribe({
            error: (e) => this.errorService.handleError(e),
            complete: () => {this.getData(this.currentQuery);}
          });
        } else {
          this.devicesService.update(result).subscribe({
            error: (e) => this.errorService.handleError(e),
            complete: () => {this.getData(this.currentQuery);}
          });
        }
      }
    });
  }


  getData(currentQuery): any {
    let query='';
    Object.keys(currentQuery).forEach((key) => {
      if(currentQuery[key] != undefined) {
        query = `${query}${key}=${currentQuery[key]}&`
      }
    });

    // if(this.user.roles.indexOf("superadmin") > -1) { //If superadmin
    //   query = '/?populate_company=name&populate_measurement=name&populate_project=name';
    // }
    query = `?populate_company=name&populate_measurement=name&populate_project=name&${query}`;//If not,search by company of user

    this.devicesService.find(query).subscribe({
      next: (response) => {this.devices = response.body;},
      error: (e) => this.errorService.handleError(e),
    });
  }

  deleteElement(row: any): any {
    this.confirmDialog = this.deviceDialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });

    this.confirmDialog.componentInstance.confirmMessage = this.translateService.instant("dialog.subtitle.delete.device") + ' ' + row.name;

    this.confirmDialog.afterClosed().subscribe(result => {
      if(result) {
        this.devicesService.delete(row._id).subscribe({
          error: (e) => this.errorService.handleError(e),
          complete: () => this.getData(this.currentQuery)
        });
      }
    });
  }

  rowAction(event: any): any {
    if (event.action === 'edit') {
      this.openDialog(event.row._id, event.row);
    } else if (event.action === 'delete') {
      this.deleteElement(event.row);
    }
  }

  queryEvent(event: any) {
    this.currentQuery[event.field] = event.$event.value;
    this.getData(this.currentQuery);
  }
}
