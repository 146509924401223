<!--
<div>
    <input type="file" class="file-upload" (change)="onFileSelected($event)">

</div>-->

<div class="dropzone" cosmosDragndrop (fileDropped)="onFileDropped($event)">
    <input type="file"#fileDropRef id="fileDropRef" class="file-input" [accept]="filetype" [multiple]="isMultiple"
    (change)="onFileSelected($event)" #fileUpload>

    <div class="file-upload" >
        <button mat-mini-fab color="primary" class="upload-btn"
            (click)="fileUpload.click() ">
            <mat-icon>attach_file</mat-icon>
        </button>
        {{fileName || "file-upload.empty" | translate}}
    </div>

    <div *ngIf="showBar">
        <h3 class="progressbar">{{"file-upload.progress" | translate}}: {{progressNumber}} %&#160;
            <mat-progress-spinner [diameter]="25" mode="determinate" value={{progressNumber}}></mat-progress-spinner>
        </h3>
    </div>

    <button mat-raised-button color = "warn" [disabled] = "!canUpload" (click)="onClick()">{{"file-upload.upload" | translate}}</button>
</div>
