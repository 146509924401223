import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CompaniesService, GatewaysService, ProjectsService } from '@cosmos/angular/api';
import { ActionDefinition, ColumnDefinition } from '@cosmos/angular/ui';
import { ErrorService, StateService } from '@cosmos/angular/utils';
import { Company, Gateway, Project, User } from '@cosmos/shared';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationDialogComponent } from '@cosmos/angular/ui';
import { GatewayDialogComponent } from '../dialogs/gateway-dialog/gateway-dialog.component';

@Component({
  selector: 'cosmos-gateways',
  templateUrl: './gateways.component.html',
  styleUrls: ['./gateways.component.scss']
})
export class GatewaysComponent implements OnInit {

  gateways: Gateway[] = [];
  companies: Company[] = [];
  projects: Project[] = [];
  user: User;
  confirmDialog: MatDialogRef<ConfirmationDialogComponent>;

  actions: ActionDefinition[] = [
    {name: 'edit', label: 'table.actions.edit', icon: 'edit', sticky: true},
    {name: 'delete', label: 'table.actions.delete', icon: 'delete', sticky: true},
  ];

  columns: ColumnDefinition[] = [
    {name: 'name', label: 'table.labels.name', visible: true, sortable: true, draggable: true},
    {name: 'description', label: 'table.labels.description', visible: true, sortable: true, draggable: true},
    {name: 'mac', label: 'table.labels.mac', visible: true, sortable: true, draggable: true},
    {name: 'project.name', label: 'table.labels.project', visible: true, sortable: true, draggable: true},
    {name: 'company.name', label: 'table.labels.company', visible: true, sortable: true, draggable: true},
    {name: 'transmission', label: 'table.labels.transmission', visible: true, sortable: true, draggable: true, type: 'transmission'},
  ];

  constructor(
    private gatewaysService: GatewaysService,
    public gatewayDialog: MatDialog,
    private companiesService: CompaniesService,
    private projectsService: ProjectsService,
    private stateService: StateService,
    private translateService: TranslateService,
    private errorService: ErrorService,
  ) { }

  ngOnInit(): void {
    this.user = this.stateService.getCurrentUser();

    this.getData();

    var queryC = ''; //Query for companies service
    var queryP = ''; //Query for projects service

    if(this.user.roles.indexOf("superadmin") <= -1) { //If user is NOT a superadmin
      // queryC = '/?account_manager=' + this.user._id;
      // queryP = '/?owner=' + this.user._id; //+ "&shared_w=" + this.user._id
    } else {
      this.columns.push({name: 'vpn', label: 'table.labels.vpn', visible: true, sortable: true, draggable: true});
    }

    this.companiesService.find(queryC).subscribe({
      next: (response) => {this.companies = response.body;},
      error: (e) => this.errorService.handleError(e),
    });
    this.projectsService.find(queryP).subscribe({
      next: (response) => {this.projects = response.body;},
      error: (e) => this.errorService.handleError(e),
    });
  }

  openDialog(id?: string, object?: any): any {
    const gateway = object;
    let create = true;
    const companies = this.companies;
    const projects = this.projects;

    if (gateway)  {
      create = false;
    }

    const dialogRef = this.gatewayDialog.open(GatewayDialogComponent, {
      data: { gateway, create, companies, projects }
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result !== undefined) {
        if (create) {
          this.gatewaysService.create(result).subscribe({
            error: (e) => this.errorService.handleError(e),
            complete: () => { this.getData();}
          });
        } else {
          this.gatewaysService.update(result).subscribe({
            error: (e) => this.errorService.handleError(e),
            complete: () => { this.getData();}
          });
     }
    }
    });
  }

  getData(): any {
    var query;

    if(this.user.roles.indexOf("superadmin") > -1) { //If superadmin
      query = '/?populate_company=name&populate_project=name';
    }
    else query = '/?company=' + this.user.company + '&populate_company=name&populate_project=name';//If not,search by company of user

    this.gatewaysService.find(query).subscribe({
      next: (response) => {this.gateways = response.body;},
      error: (e) => this.errorService.handleError(e),
    });
  }

  deleteElement(row: any): any {
    this.confirmDialog = this.gatewayDialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });

    this.confirmDialog.componentInstance.confirmMessage = this.translateService.instant("dialog.subtitle.delete.gateway") + ' ' + row.name;

    this.confirmDialog.afterClosed().subscribe(result => {
      if(result) {
        this.gatewaysService.delete(row._id).subscribe({
          error: (e) => this.errorService.handleError(e),
          complete: () => this.getData()
        });
      }
    });
  }

  rowAction(event: any): any {
    if (event.action === 'edit') {
      this.openDialog(event.row._id, event.row);
    } else if (event.action === 'delete') {
      this.deleteElement(event.row);
    }
  }

}
