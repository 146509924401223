import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormFieldBase } from '@cosmos/angular/ui';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { FormFieldsClass } from './simulation-quote.form';

@Component({
  selector: 'cosmos-simulation-quote-dialog',
  templateUrl: './simulation-quote-dialog.component.html',
  styleUrls: ['./simulation-quote-dialog.component.scss']
})
export class SimulationQuoteDialogComponent implements OnInit {

  fields: Observable<FormFieldBase<string>[]>;
  buttonAction = '';

  constructor(
    public dialogRef: MatDialogRef<SimulationQuoteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.buttonAction = this.translate.instant('simulations.button.request-proposal')
    this.fields = new FormFieldsClass(this.data.phone).getCreateFields();
  }

  handleEvent($event){
    this.dialogRef.close($event);
  }

}
