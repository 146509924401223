import { StorageService } from '@cosmos/angular/utils';
import { TranslateService } from '@ngx-translate/core';

export function initConfig(storageService: StorageService, translate: TranslateService): any {
  return () => {
    const body = document.getElementsByTagName("body")[0];
    if(
      storageService.getItem('theme') === 'light' ||
      (storageService.getItem('theme') === null && window.matchMedia("(prefers-color-scheme: light)").matches)
    ) body.classList.add('light-theme');

    if(storageService.getItem('language')) translate.use(storageService.getItem('language'));
    if(!storageService.getItem('groupDisplay')) storageService.setItem('groupDisplay','table');

  }
}
