import { FormFieldBase, FormFieldDropdown, FormFieldTextbox, FormFieldCheckBox } from "@cosmos/angular/ui";
import { of } from "rxjs";

export class FormFieldsClass {
  formFields: FormFieldBase<any>[];

  constructor(phone: string) {
    // COMMON FIELDS
    this.formFields = [
        new FormFieldTextbox({
            key: 'phone',
            label: 'fields.phone',
            value: phone?phone:'',
            type:'text',
            required: true,
            order: 1
        }),
        new FormFieldTextbox({
            key: 'comments',
            label: 'fields.quote-comments',
            value: '',
            type:'text',
            long: true,
            required: false,
            order: 2
        }),
    ]
  }

  getCreateFields()
  {
    return of(this.formFields.sort((a, b) => a.order - b.order));
  }
}
