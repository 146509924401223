import { FormFieldBase, FormFieldTextbox , FormFieldCheckBox, FormFieldSharingDelete} from "@cosmos/angular/ui";
import { of } from "rxjs";

export class FormFieldsClassProjectsShared {
  formFields: FormFieldBase<any>[];

  constructor( data:any) {
    // COMMON FIELDS
    this.formFields = [
        new FormFieldTextbox({
            key: 'shared_Email',
            label: 'fields.email',
            value: '',
            type: "email",
            required: true,
            order: 1
        }),
        new FormFieldCheckBox({
          key: 'write',
          label: 'fields.allow-write',
          value: false,
          required: false,
          type: 'fields.allow-write',
          order: 2
        }),
        new FormFieldSharingDelete({
          key: 'shared_w',
          label: 'fields.write-permissions',
          value: data,
          required: false,
          order: 3
        }),
        new FormFieldSharingDelete({
          key: 'shared_r',
          label: 'fields.read-permissions',
          value: data,
          required: false,
          order: 4
        })
    ]

    // EDIT ONLY FIELDS
    if(!data.create) {
      this.formFields.push();
    }
  }

  getCreateFields()
  {
    return of(this.formFields.sort((a, b) => a.order - b.order));
  }
}
