<!-- CONTENT -->
{{'dashboard.admin' | translate}}

<mat-list>
  <mat-divider></mat-divider>
</mat-list>

<div class="row mb-4">
  <!-- GESTION USUARIOS -->
  <div class="col-md-4">
      <mat-card  appearance="outlined"  class="my-2">
          <mat-card-header>
              <div mat-card-avatar class="text-center card-avatar">U</div>
              <mat-card-title class="mb-1">{{"dashboard.user.title" | translate}}</mat-card-title>
              <mat-card-subtitle>{{"dashboard.user.subtitle" | translate}}</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
              <p>{{"dashboard.user.description" | translate}}e</p>
          </mat-card-content>
          <mat-card-actions>
              <button mat-raised-button color="primary" [routerLink]="'users'">{{"dashboard.access" | translate}}</button>
          </mat-card-actions>
      </mat-card>
  </div>


  <!-- GESTION EMPRESAS -->
  <div class="col-md-4">
    <mat-card  appearance="outlined"  class="my-2">
        <mat-card-header>
            <div mat-card-avatar class="text-center card-avatar">E</div>
            <mat-card-title class="mb-1">{{"dashboard.company.title" | translate}}</mat-card-title>
            <mat-card-subtitle>{{"dashboard.company.subtitle" | translate}}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <p>{{"dashboard.company.description" | translate}}</p>
        </mat-card-content>
        <mat-card-actions>
            <button mat-raised-button color="primary" [routerLink]="'companies'">{{"dashboard.access" | translate}}</button>
        </mat-card-actions>
    </mat-card>
  </div>

  <!-- GESTION CUENTAS FACT -->
  <div *ngIf="this.user.roles.indexOf('superadmin') > -1" class="col-md-4">
    <mat-card  appearance="outlined"  class="my-2">
        <mat-card-header>
            <div mat-card-avatar class="text-center card-avatar">F</div>
            <mat-card-title class="mb-1">{{"dashboard.billing.title" | translate}}</mat-card-title>
            <mat-card-subtitle>{{"dashboard.billing.subtitle" | translate}}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <p>{{"dashboard.billing.description" | translate}}</p>
        </mat-card-content>
        <mat-card-actions>
            <button mat-raised-button color="primary" [routerLink]="'billing-accounts'">{{"dashboard.access" | translate}}</button>
        </mat-card-actions>
    </mat-card>
  </div>

  <!-- SIMULACIONES  -->
  <div *ngIf="this.user.roles.indexOf('superadmin') > -1" class="col-md-4">
    <mat-card  appearance="outlined"  class="my-2">
        <mat-card-header>
            <div mat-card-avatar class="text-center card-avatar">S</div>
            <mat-card-title class="mb-1">{{"dashboard.simulations.title" | translate}}</mat-card-title>
            <mat-card-subtitle>{{"dashboard.simulations.subtitle" | translate}}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <p>{{"dashboard.simulations.description" | translate}}</p>
        </mat-card-content>
        <mat-card-actions>
            <button mat-raised-button color="primary" [routerLink]="'simulations'">{{"dashboard.access" | translate}}</button>
        </mat-card-actions>
    </mat-card>
  </div>
</div>

<!-- CONCRETES, DEVICES AND GATEWAYS -->
<mat-list>
  <mat-divider></mat-divider>
</mat-list>

<div class="row mb-4">
  <div class="col-md-4">
    <mat-card  appearance="outlined"  class="my-2">
      <mat-card-header>
          <div mat-card-avatar class="text-center card-avatar">H</div>
          <mat-card-title class="mb-1">{{"dashboard.concretes.title" | translate}}</mat-card-title>
          <mat-card-subtitle>{{"dashboard.concretes.subtitle" | translate}}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
          <p>{{"dashboard.concretes.description" | translate}}</p>
      </mat-card-content>
      <mat-card-actions>
          <button mat-raised-button color="primary" [routerLink]="'concretes'">{{"dashboard.access" | translate}}</button>
      </mat-card-actions>
    </mat-card>
  </div>

  <div class="col-md-4">
    <mat-card  appearance="outlined"  class="my-2">
      <mat-card-header>
          <div mat-card-avatar class="text-center card-avatar">D</div>
          <mat-card-title class="mb-1">{{"dashboard.devices.title" | translate}}</mat-card-title>
          <mat-card-subtitle>{{"dashboard.devices.subtitle" | translate}}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
          <p>{{"dashboard.devices.description" | translate}}</p>
      </mat-card-content>
      <mat-card-actions>
          <button mat-raised-button color="primary" [routerLink]="'devices'">{{"dashboard.access" | translate}}</button>
      </mat-card-actions>
    </mat-card>
  </div>

  <div class="col-md-4">
    <mat-card  appearance="outlined"  class="my-2">
      <mat-card-header>
          <div mat-card-avatar class="text-center card-avatar">G</div>
          <mat-card-title class="mb-1">{{"dashboard.gateways.title" | translate}}</mat-card-title>
          <mat-card-subtitle>{{"dashboard.gateways.subtitle" | translate}}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
          <p>{{"dashboard.gateways.description" | translate}}</p>
      </mat-card-content>
      <mat-card-actions>
          <button mat-raised-button color="primary" [routerLink]="'gateways'">{{"dashboard.access" | translate}}</button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>


<!-- PROJECTS, STAGES AND MEASUREMENTS -->
<mat-list>
  <mat-divider></mat-divider>
</mat-list>
<div class="row mb-4">
  <div class="col-md-4">
    <mat-card  appearance="outlined"  class="my-2">
      <mat-card-header>
          <div mat-card-avatar class="text-center card-avatar">P</div>
          <mat-card-title class="mb-1">{{"dashboard.projects.title" | translate}}</mat-card-title>
          <mat-card-subtitle>{{"dashboard.projects.subtitle" | translate}}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
          <p>{{"dashboard.projects.description" | translate}}</p>
      </mat-card-content>
      <mat-card-actions>
          <button mat-raised-button color="primary" [routerLink]="'projects'">{{"dashboard.access" | translate}}</button>
      </mat-card-actions>
    </mat-card>
  </div>

  <div class="col-md-4">
    <mat-card  appearance="outlined"  class="my-2">
      <mat-card-header>
          <div mat-card-avatar class="text-center card-avatar">F</div>
          <mat-card-title class="mb-1">{{"dashboard.stages.title" | translate}}</mat-card-title>
          <mat-card-subtitle>{{"dashboard.stages.subtitle" | translate}}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
          <p>{{"dashboard.stages.description" | translate}}</p>
      </mat-card-content>
      <mat-card-actions>
          <button mat-raised-button color="primary" [routerLink]="'stages'">{{"dashboard.access" | translate}}</button>
      </mat-card-actions>
    </mat-card>
  </div>

  <div class="col-md-4">
    <mat-card  appearance="outlined"  class="my-2">
      <mat-card-header>
          <div mat-card-avatar class="text-center card-avatar">M</div>
          <mat-card-title class="mb-1">{{"dashboard.measurements.title" | translate}}</mat-card-title>
          <mat-card-subtitle>{{"dashboard.measurements.subtitle" | translate}}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
          <p>{{"dashboard.measurements.description" | translate}}</p>
      </mat-card-content>
      <mat-card-actions>
          <button mat-raised-button color="primary" [routerLink]="'measurements'">{{"dashboard.access" | translate}}</button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
