import { Component, Input, OnInit } from '@angular/core';
import { StorageService } from '@cosmos/angular/utils';
import { Measurement } from '@cosmos/shared';
import { ColumnDefinition } from '../../data-table/column-definition.model';

@Component({
  selector: 'cosmos-measurement-card',
  templateUrl: './measurement-card.component.html',
  styleUrls: ['./measurement-card.component.scss']
})
export class MeasurementCardComponent implements OnInit {

  @Input()
  measurements: Measurement[];
  currentGroup: string;

  columns: ColumnDefinition[] = [
    {name: 'name', label: 'table.labels.name', visible: true, sortable: true, draggable: true},
    {name: 'description', label: 'table.labels.description', visible: true, sortable: true, draggable: true},
    {name: 'time', label: 'table.labels.lastSeen', visible: true, sortable: true, draggable: true, type: 'dateArray'},
  ];

  constructor(
    private storageService: StorageService
  ) { }

  ngOnInit(): void {
    this.currentGroup = this.storageService.getItem("groupDisplay");
  }

  updateStorage(selectedGroup: string){
    this.storageService.setItem('groupDisplay',selectedGroup);
    this.currentGroup = selectedGroup;
  }

}
