import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConcretesService, DevicesService, MeasurementsService, UsersService, StagesService, ProjectsService, CompaniesService } from '@cosmos/angular/api';
import { ActionDefinition, ColumnDefinition, FileUploadService } from '@cosmos/angular/ui';
import { ErrorService, StateService } from '@cosmos/angular/utils';
import { Company, Concrete, Device, Measurement, Project, Stage, User } from '@cosmos/shared';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationDialogComponent } from '@cosmos/angular/ui';
import { MeasurementDialogComponent } from '../dialogs/measurement-dialog/measurement-dialog.component';

@Component({
  selector: 'cosmos-measurements',
  templateUrl: './measurements.component.html',
  styleUrls: ['./measurements.component.scss']
})
export class MeasurementsComponent implements OnInit {

  measurements: Measurement[];
  users: User[] = [];
  concretes: Concrete[] = [];
  companies: Company[] = [];
  devices: Device[] = [];
  stages: Stage[] = [];
  projects: Project[] = [];
  user: User;
  count = 0;
  pageSize = 25;
  pageIndex = 0;
  pages = 1;
  confirmDialog: MatDialogRef<ConfirmationDialogComponent>;
  queryFields = [];
  currentQuery = {};

   actions: ActionDefinition[] = [
    {name: 'download', label: 'table.actions.download', icon: 'download', sticky: true},
    {name: 'clear', label: 'table.actions.clear', icon: 'delete_sweep', sticky: true},
    {name: 'edit', label: 'table.actions.edit', icon: 'edit', sticky: true},
    {name: 'delete', label: 'table.actions.delete', icon: 'delete', sticky: true},
   ];

   columns: ColumnDefinition[] = [
    {name: 'name', label: 'table.labels.name', visible: true, sortable: true, draggable: true},
    {name: 'description', label: 'table.labels.description', visible: true, sortable: true, draggable: true},
    {name: 'project.name', label: 'table.labels.project', visible: true, sortable: true, draggable: true},
    {name: 'stage.name', label: 'table.labels.stage', visible: true, sortable: true, draggable: true},
    {name: 'concrete.name', label: 'table.labels.concrete', visible: true, sortable: true, draggable: true},
    {name: 'device.name', label: 'table.labels.device', visible: true, sortable: true, draggable: true},
    {name: 'strength.length', label: 'table.labels.datapoints', visible: true, sortable: true, draggable: true},
    {name: 'time', label: 'table.labels.last-seen', type:'dateArray', visible: true, sortable: true, draggable: true}
   ];

  constructor(
    private measurementsService: MeasurementsService,
    public measurementDialog: MatDialog,
    private usersService: UsersService,
    private concretesService: ConcretesService,
    private devicesService: DevicesService,
    private stagesService: StagesService,
    private projectsService: ProjectsService,
    private stateService: StateService,
    private companiesService: CompaniesService,
    private filesService: FileUploadService,
    private translateService: TranslateService,
    private snackBar: MatSnackBar,
    private datePipe: DatePipe,
    private errorService: ErrorService
  ) { }

  ngOnInit(): void {
    this.user = this.stateService.getCurrentUser();

    this.getData(this.currentQuery);

    let queryConc = ''; //Query for concretes service
    let queryD = ''; //Query for devices service
    let queryP = ''; //Query for projects service
    let queryS = ''; //Query for stages service


    if(this.user.roles.indexOf('superadmin') > -1) {//If superadmin
      queryD = '?available=true';

      this.usersService.find().subscribe({
        next: (response) => {this.users = response.body;},
        error: (e) => this.errorService.handleError(e),
      });
    }
    else {
      queryConc = "/?company=" + this.user.company;
      queryP = "/?company=" + this.user.company;
      queryD = "/?company=" + this.user.company + "&available=true";
      queryS = "/?company=" + this.user.company;

      this.usersService.findById(this.user._id).subscribe({
        next: (response) => {this.users.push(response.body);},
        error: (e) => this.errorService.handleError(e),
      });

    }

    this.concretesService.find(queryConc).subscribe({
      next: (response) => {this.concretes = response.body;},
      error: (e) => this.errorService.handleError(e),
    });

    this.companiesService.find().subscribe({
      next: (response) => {this.companies = response.body;},
      error: (e) => this.errorService.handleError(e),
    });

    this.devicesService.find(queryD).subscribe({
      next: (response) => {this.devices = response.body;},
      error: (e) => this.errorService.handleError(e),
    });

    this.stagesService.find(queryS).subscribe({
      next: (response) => {this.stages = response.body; this.queryFields.push({field: "stage", label: "fields.stage", options: response.body})},
      error: (e) => this.errorService.handleError(e),
    });

    this.projectsService.find(queryP).subscribe({
      next: (response) => {this.projects = response.body; this.queryFields.push({field: "project", label: "fields.project", options: response.body})},
      error: (e) => this.errorService.handleError(e),
    });

  }

  openDialog(id?: string, object?: any): any {
    const measurement = object;
    let create = true;
    const users = this.users;
    const concretes = this.concretes;
    const devices = this.devices;
    const stages = this.stages;
    const projects = this.projects;
    const companies = this.companies;

    if (measurement)  {
      create = false;
    }

    const dialogRef = this.measurementDialog.open(MeasurementDialogComponent, {
      data: { measurement, create, concretes, devices, users, projects, stages, companies}
    });

     dialogRef.afterClosed().subscribe(async result => {
       if (result !== undefined) {
        if (create) {
          this.measurementsService.create(result).subscribe({
            error: (e) => this.errorService.handleError(e),
            complete: () => { this.getData(this.currentQuery);}
          });
        } else {
          this.measurementsService.update(result).subscribe({
            error: (e) => this.errorService.handleError(e),
            complete: () => { this.getData(this.currentQuery);}
          });
     }
     }
     });
  }

  getData(currentQuery): any {
    let query = ''
    Object.keys(currentQuery).forEach((key) => {
      if(currentQuery[key] != undefined) {
        query = `${query}${key}=${currentQuery[key]}&`
      }
    });

    let countQuery = `/?count=true&${query}`;
    query =`/?populate_project=name&populate_stage=name&populate_concrete=name&populate_device=name&populate_company=name&limit=${this.pageSize}&skip=${(this.pageIndex * this.pageSize)}&${query}`;

    this.measurementsService.find(countQuery).subscribe({
      next: (response) => this.count = response.body,
      error: (e) => this.errorService.handleError(e),
      complete: () => {
        this.measurementsService.find(query).subscribe({
          next: (response) => {this.measurements = response.body;},
          error: (e) => this.errorService.handleError(e)
        });
      }
    });

    
  }
  deleteElement(row: any): any {

    this.confirmDialog = this.measurementDialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });

    this.confirmDialog.componentInstance.isSecure = true; //ADD THIS PART FOR THE EXTRA "DELETE" SECURITY
    this.confirmDialog.componentInstance.confirmMessage = this.translateService.instant("dialog.subtitle.delete.measurement") + ' ' + row.name;

    this.confirmDialog.afterClosed().subscribe(result => {
      if(result) {
        this.measurementsService.delete(row._id).subscribe({
          error: (e) => this.errorService.handleError(e),
          complete: () => this.getData(this.currentQuery)
        });
      }
    });
  }

  clearData(row: any) {
    if(this.user.roles.indexOf('superadmin') <= -1) {//IF NOT SUPERADMIN
      if(row.time.length > 6) { //IF THE TIME PASSED MORE THAN 3H, stop
        this.snackBar.open(this.translateService.instant("measurement.clear.too-much"),"OK")
        return;
      }
    }
    //IF SUPERADMIN, OR NOT THE PREVIOUS CONDITIONS, PROCEED WITH DELETION W/ CHECK

    this.confirmDialog = this.measurementDialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });
    this.confirmDialog.componentInstance.isSecure = true; //ADD THIS PART FOR THE EXTRA "DELETE" SECURITY
    this.confirmDialog.componentInstance.confirmMessage = this.translateService.instant("measurement.clear.confirm") + row.name + "?";

    this.confirmDialog.afterClosed().subscribe(result => {
      if(result) {
        const body = {time: [], temperature: [], strength: [], maturity: []}
        this.measurementsService.update([row._id, body]).subscribe({
          error: (e) => this.errorService.handleError(e),
          complete: () => { this.getData(this.currentQuery)}
        })
      }
    });
  }

  downloadCSV(row: any) {
    this.measurementsService.downloadCSV('_id=' + row._id).subscribe({
      next: (response) => { this.filesService.downloadCSVFromBlob(response); },
      error: (e) => {
        this.snackBar.open(this.translateService.instant("measurement.download.error"),"OK")
      }
    })
  }

  rowAction(event: any): any {
    if (event.action === 'edit') {
      this.openDialog(event.row._id, event.row);
    }
    else if (event.action === 'delete') {
      this.deleteElement(event.row);
    }
    else if (event.action === "clear") {
      this.clearData(event.row);
    }
    else if (event.action === "download") {
      this.downloadCSV(event.row);
    }
  }

  paginatorEvent(event: any) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.getData(this.currentQuery);
  }

  queryEvent(event: any) {
    this.currentQuery[event.field] = event.$event.value;
    
    if(event.field == "project" && event.$event.value != undefined) {
      this.currentQuery['stage'] = undefined;
      this.stagesService.find('?project='+ event.$event.value).subscribe({
        next: (response) => {
          this.queryFields.forEach((field, index) => {
            if(field.field == 'stage') {
              this.queryFields[index].options = response.body;
            }
          });
        },
        error: (e) => this.errorService.handleError(e),
      });
    } else if (event.field=="project" && event.$event.value == undefined) {
      this.stagesService.find().subscribe({
        next: (response) => {
          this.queryFields.forEach((field, index) => {
            if(field.field == 'stage') {
              this.queryFields[index].options = response.body;
            }
          });
        },
        error: (e) => this.errorService.handleError(e),
      });
    }
    this.getData(this.currentQuery);
  }

}
