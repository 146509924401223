
<!-- MEASUREMENT INFORMATION CARD -->
    <!-- SPLIT CONTENT: RIGHT SIDE ADD SOME KIND OF SUMMARY -->
    <div *ngIf="this.measurement" class="row my-4">
        <div class="col-md-12">
            <mat-card appearance="outlined" >
                <mat-card-header>
                    <div mat-card-avatar class="text-center card-avatar">{{measurement.name[0]}}</div>
                    <mat-card-title class="mb-1">{{measurement.name}}</mat-card-title>
                    <mat-card-subtitle>{{ 'measurement.subtitle' | translate }} - {{(measurement.active ? 'measurement.ongoing' : 'measurement.finished') | translate}}</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                  <mat-list>
                    <mat-list-item>
                      <mat-icon matListItemIcon>schedule</mat-icon>
                      <div matListItemTitle>{{ measurement.createdAt | date:'dd/MM/yyyy HH:mm:ss' }}</div>
                      <div matListItemLine>{{ "measurement.created" | translate }}</div>
                    </mat-list-item>
                    <mat-list-item>
                      <mat-icon matListItemIcon>update</mat-icon>
                      <div matListItemTitle>{{ measurement.updatedAt | date:'dd/MM/yyyy HH:mm:ss' }}</div>
                      <div matListItemLine>{{ "measurement.updated" | translate }}</div>
                    </mat-list-item>

                    <mat-divider></mat-divider>

                    <mat-list-item>
                      <mat-icon matListItemIcon>description</mat-icon>
                      <div matListItemTitle>{{measurement.description}}</div>
                      <div matListItemLine>{{ "measurement.card.description" | translate }}</div>
                    </mat-list-item>
                    <mat-list-item>
                      <mat-icon matListItemIcon>memory</mat-icon>
                      <div matListItemTitle>{{ device?.name }}</div>
                      <div matListItemLine>{{ "measurement.card.device" | translate }}</div>
                    </mat-list-item>
                    <mat-list-item>
                      <mat-icon matListItemIcon>insights</mat-icon>
                      <div matListItemTitle>{{ concrete?.name }}</div>
                      <div matListItemLine>{{ "measurement.card.concrete" | translate }}</div>
                    </mat-list-item>
                  </mat-list>
                </mat-card-content>
                <mat-card-actions>
                    <button mat-button *ngIf="project.owner === user._id || (project.shared_w.indexOf(user._id) + 1)" (click)="editMeasurement()" color="primary"><mat-icon inline="true">edit</mat-icon> {{ "measurement.edit" | translate }}</button>
                    <button *ngIf="measurement?.active && (project?.owner === user?._id || (project?.shared_w.indexOf(user?._id) + 1))" (click)="finishMeasurement(measurement._id)" mat-button [matTooltip]="'measurement.tooltip.finish' | translate" matTooltipClass="tooltip-red" matTooltipPosition="above" color="warn"><mat-icon inline="true">close</mat-icon> {{ "measurement.finish" | translate }}</button>
                    <button *ngIf="measurement?.active && (project?.owner === user?._id || (project?.shared_w.indexOf(user?._id) + 1))" (click)="changeDevice(measurement)" mat-button [matTooltip]="'measurement.tooltip.change-device' | translate" matTooltipClass="tooltip-red" matTooltipPosition="above" color="accent"><mat-icon inline="true">find_replace</mat-icon> {{ "measurement.change_device" | translate }}</button>
                    <button mat-button routerLink="/devices"><mat-icon inline="true">search</mat-icon> {{ "measurement.show_device" | translate }}</button>
                </mat-card-actions>
            </mat-card>
        </div>
    </div>

    <!--CHARTS-->
    <div *ngIf="this.measurement" class="row mb-4">
      <div class="col-md-5">
        <cosmos-strength-insights-card
          [currentStrength]="measurement.strength.length?measurement.strength[measurement.strength.length-1]:0"
          [specifications]="measurement.specifications"
          [currentTime]="measurement.time.length?measurement.time[measurement.time.length-1]:null"
        >
        </cosmos-strength-insights-card>
      </div>
        <div class="col-md-7">
          <!-- NORMAL CHART -->
            <mat-card  appearance="outlined"  class="my-2">
                <mat-card-header>
                    <div mat-card-avatar class="text-center card-avatar">
                        <mat-icon> insert_chart_outlined</mat-icon>
                    </div>
                    <mat-card-title class="mb-1">{{ "measurement.chart.title" | translate }}</mat-card-title>
                    <mat-card-subtitle>{{ "measurement.chart.subtitle" | translate }}</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                    <cosmos-chart [measurement]="measurement"></cosmos-chart>
                </mat-card-content>
            </mat-card>
          <!-- PREDICTIVE CHART -->
            <mat-card  appearance="outlined"  class="my-2" *ngIf="project.premium && predictions && measurement">
              <mat-card-header>
                  <div mat-card-avatar class="text-center card-avatar">
                      <mat-icon> insert_chart_outlined</mat-icon>
                  </div>
                  <mat-card-title class="mb-1">{{ "measurement.predictive-chart.title" | translate }}</mat-card-title>
                  <mat-card-subtitle>{{ "measurement.predictive-chart.subtitle" | translate }}</mat-card-subtitle>
              </mat-card-header>
              <mat-card-content>
                  <cosmos-predictive-chart [realData]="measurement" [predictionData]="predictions"></cosmos-predictive-chart>
              </mat-card-content>
          </mat-card>
        </div>
    </div>




