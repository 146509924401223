import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { FormFieldBase } from './form-models/form-field-base.model';
import { FormFieldChips } from './form-models/form-field-chips.model'

@Injectable()
export class FieldControlService {
  constructor() { }

  toFormGroup(fields: FormFieldBase<string>[] ) {
    const group: any = {};

    fields.forEach(field => {
      group[field.key] = field.required ? new UntypedFormControl(field.value || '', Validators.required)
                                              : new UntypedFormControl(field.value || '');
    });
    return new UntypedFormGroup(group);
  }
}
