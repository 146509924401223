import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@cosmos/angular/utils';

@Component({
  selector: 'cosmos-user-dropdown',
  templateUrl: './user-dropdown.component.html',
  styleUrls: ['./user-dropdown.component.scss']
})
export class UserDropdownComponent implements OnInit {

  @Input("app") app: String;

  constructor(
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
  }

  logOut(): void {
    this.authService.signOut();
  }

}
