import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { AppComponent } from './app.component';

import { AngularUiModule } from '@cosmos/angular/ui';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AngularUtilsModule, AuthInterceptor, AuthService, JwtService, StateService, StorageService } from '@cosmos/angular/utils';
import { LoginModule } from '@cosmos/angular/ui';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { initAuth } from './initAuth';
import { initConfig } from './initConfig';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DashboardModule } from './dashboard/dashboard.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AngularTranslateModule } from '@cosmos/angular/translate'
import { environment } from '../../../../environments/environment';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
  ],

  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production,
      //registrationStrategy: 'registerWhenStable:30000'
    }),
    AngularTranslateModule,
    AppRoutingModule,
    DashboardModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    AngularUiModule,
    LoginModule,
    AngularUtilsModule,
  ],
  providers: [

    AuthService,
    {
      provide: APP_INITIALIZER,
      useFactory: initAuth,
      deps: [AuthService, JwtService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initConfig,
      deps: [StorageService, TranslateService],
      multi: true
    },
    JwtService,
    StateService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },

  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
