import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormFieldBase } from '@cosmos/angular/ui';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { FormFieldsClass } from '../../measurements/measurements.forms';

@Component({
  selector: 'cosmos-measurement-dialog',
  templateUrl: './measurement-dialog.component.html',
  styleUrls: ['./measurement-dialog.component.scss']
})
export class MeasurementDialogComponent implements OnInit {

  title = '';
  fields: Observable<FormFieldBase<string>[]>;

  constructor(
    public dialogRef: MatDialogRef<MeasurementDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translateService: TranslateService,
  ) { }

  ngOnInit(): void {

    const users: {key: string, value: string}[] = [];
    const projects: {key: string, value: string}[] = [];
    const devices : {key: string, value: string, lora?: string}[] = [];
    const concretes : {key: string, value: string}[] = [];
    const stages : {key: string, value: string}[] = [];
    const companies: {key: string, value: string}[] = [];

    if(this.data.users) {
      for(const user of this.data.users) users.push({key: user['_id'], value: user['name'] });
    }

    if(this.data.projects) {
      for(const project of this.data.projects) projects.push({key: project['_id'], value: project['name'] });
    }

    if(this.data.devices) {
      for(const device of this.data.devices) devices.push({key: device['_id'], value: device['name'], lora: device['loramac']});
    }

    if(this.data.concretes) {
      for(const concrete of this.data.concretes) concretes.push({key: concrete['_id'], value: concrete['name']});
    }

    if(this.data.stages) {
      for(const stage of this.data.stages) stages.push({key: stage['_id'], value: stage['name']});
    }

    if(this.data.companies) {
      for(const company of this.data.companies) companies.push({key: company['_id'], value: company['name']});
    }

    if (this.data.create) {
      this.title =  this.translateService.instant('dialog.title.create.measurement');
      this.fields = new FormFieldsClass(true, this.data.measurement, users, projects, devices, concretes, stages, companies).getCreateFields();
    }
    else {
      this.title = this.translateService.instant('dialog.title.edit.measurement') + ' ' + this.data.measurement._id;
      this.fields = new FormFieldsClass(false, this.data.measurement, users, projects, devices, concretes, stages, companies).getCreateFields();
    }
  }

  handleEvent($event){

    //---------- Transforms data from string to number
    const temp = $event;
    temp.specifications = parseInt(temp.specifications);
    if(this.data.create) this.dialogRef.close(temp);
    else this.dialogRef.close([this.data.measurement._id, temp]);
  }


}
