<div [formGroup]="form">
    <!-- <mat-label [attr.for]="field.key">{{field.label | translate}}</mat-label> -->

  <div [ngSwitch]="field.controlType" class="my-1">

    <!-- <div class="errorMessage" *ngIf="!isValid">{{field.label | translate}} {{"formfield.required" | translate}}</div> -->
    <!-- INPUT - TEXTBOX -->
    <mat-form-field *ngSwitchCase="'textbox'" appearance="outline" class="w-100">
      <mat-label>{{field.label | translate}}</mat-label>
      <input *ngIf="!field.long" matInput [type]="field.type" placeholder="" [formControlName]="field.key" [id]="field.key" [email]="field.type === 'email'">
      <!-- <span matTextSuffix *ngIf="field.suffix">{{field.suffix}}</span> -->
      <textarea *ngIf="field.long" matInput [type]="field.type" placeholder="" [formControlName]="field.key" [id]="field.key" [email]="field.type === 'email'"></textarea>
      <mat-error *ngIf="isError">{{"formfield.required" | translate}}</mat-error>
    </mat-form-field>

    <!-- INPUT - NUMBER CHIPS -->
    <mat-form-field *ngSwitchCase="'number-chips'" appearance="outline" class="w-100">
      <mat-label>{{field.label | translate}}</mat-label>
      <mat-chip-grid #chipList [formControlName]="field.key">
        <mat-chip-row *ngFor="let chip of chipValues" [value]="chip" (removed)="chipInputRemove(chip)">
          {{chip + field.suffix}}
          <button matChipRemove><mat-icon>cancel</mat-icon></button>
        </mat-chip-row>
        <input placeholder="" [type]="field.type" #chipInput
          [formControl]="inputControl"
          [matChipInputFor]="chipList"
          (matChipInputTokenEnd)="numberChipInputSelected($event)"
          >
      </mat-chip-grid>
      <mat-error *ngIf="isError">{{"formfield.required" | translate}}</mat-error>
    </mat-form-field>

    <!-- INPUT - CHIPS -->
    <mat-form-field *ngSwitchCase="'chips'" appearance="outline" class="w-100">
      <mat-label>{{field.label | translate}}</mat-label>
      <mat-chip-grid #chipList [formControlName]="field.key">
        <mat-chip-row *ngFor="let chip of chipValues" [value]="chip.key" (removed)="chipInputRemove(chip)">
          {{chip.value}}
          <button matChipRemove><mat-icon>cancel</mat-icon></button>
        </mat-chip-row>
        <input placeholder="" #chipInput
          [formControl]="inputControl"
          [matAutocomplete]="auto"
          [matChipInputFor]="chipList">
      </mat-chip-grid>
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="chipInputSelected($event)">
        <mat-option *ngFor="let opt of filteredOptions | async" [value]="opt.key">{{opt.value}}</mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="isError">{{"formfield.required" | translate}}</mat-error>
    </mat-form-field>

    <!-- SELECT - DROPDOWN -->
    <div *ngSwitchCase="'dropdown'" class="w-100">
      <mat-form-field  appearance="outline" class="w-100">
        <mat-label>{{field.label | translate}}</mat-label>
        <mat-select #select [formControlName]="field.key" placeholder="" [multiple] = "field.multiple" (selectionChange)="selectChanges($event)">
          <!--<mat-option *ngFor="let opt of field.options" [value]="opt.key">{{ opt.value }}</mat-option>-->
          <!-- <div *ngIf="field.multiple"> -->
            <mat-option *ngIf="!field.multiple" [value]='null'>-</mat-option>
            <mat-option *ngFor="let opt of field.options" [value]="opt.key">{{ opt.value }}</mat-option>
          <!-- </div> -->

          <!-- <div *ngIf="!field.multiple"> -->
            <!-- <mat-option *ngFor="let opt of field.options" [value]="opt.key">{{ opt.value }}</mat-option> -->
          <!-- </div> -->

        </mat-select>
        <mat-error *ngIf="isError">{{"formfield.required" | translate}}</mat-error>
      </mat-form-field>

      <zxing-scanner *ngIf="scanner" [enable]="scanner" (scanComplete)="scanCompleteHandler($event)"></zxing-scanner>
      <button mat-icon-button (click)="openCamera()" *ngIf="field.key=== 'device'">
        <mat-icon>qr_code_scanner</mat-icon>
      </button>
    </div>


    <!-- SELECT - DELETE - SHARED -->

    <div *ngSwitchCase="'share-delete'" appearance="outline" class="w-100">
      <mat-divider ></mat-divider>
      <mat-label style="font-weight:bold">{{field.label | translate}}</mat-label>

      <div *ngIf="field.key === 'shared_w'">
        <div *ngFor="let user of field.value.shared_w">
          <mat-divider ></mat-divider>
          {{user.email}}
          <button mat-icon-button color="primary" (click)="removeShared(user,field)"><mat-icon>delete</mat-icon></button>

        </div>
      </div>

      <div *ngIf="field.key === 'shared_r'">
        <div *ngFor="let user of field.value.shared_r">
          <mat-divider ></mat-divider>
          {{user.email}}
          <button mat-icon-button color="primary" (click)="removeShared(user,field)"><mat-icon>delete</mat-icon></button>

        </div>
      </div>

    </div>

    <!-- INPUT - CHECKBOX -->
    <div *ngSwitchCase="'checkbox'">
      <mat-checkbox class="mat-form-field-wrapper" [checked]="field.value" color="primary" [formControlName]="field.key">{{field.label | translate}}</mat-checkbox>
      <mat-error *ngIf="isError">{{"formfield.required" | translate}}</mat-error>
    </div>

    <!-- FILE - MANAGER -->

    <div *ngSwitchCase="'file-manager'">
      <cosmos-file-upload [services]="field.value.services" [context]="field.context" [company]="field.value.project.company" [id]="field.value.project._id" [key]="field.value.key" [filetype]="field.value.filetype" [uploadDestination]="field.type" (fileuploaded)="changeImageEventHandle()" ></cosmos-file-upload>
      <div *ngIf="field.value.files">
        <div *ngFor="let file of field.value.files">
          <mat-divider ></mat-divider>
          {{file.filename || "formfield.no_filename" | translate}}
          <button mat-icon-button color="primary" (click)="deleteFile(file)"><mat-icon>delete</mat-icon></button>

        </div>
      </div>

    </div>

    <div *ngSwitchCase="'file-selector'">
      <button mat-button [matMenuTriggerFor]="menuReportImage" color="primary" class="right_header">
        {{field.label | translate}}: <span *ngIf="field.value">{{ field.value }}</span><span *ngIf="!field.value">{{ 'fields.no-image' | translate }}</span> <mat-icon>edit</mat-icon>
      </button> 
      <mat-menu #menuReportImage>
        <div *ngIf="field.options.length > 0">
          <div *ngFor="let file of field.options">
              <button mat-menu-item (click)="changeReportImage(file)">
                  {{file.filename || "formfield.no_filename" | translate}}
              </button>
          </div>
        </div>
        <div *ngIf="field.options.length <= 0">
            <div mat-menu-item>{{"projects.blueprint.empty" | translate}}</div>
        </div>
      </mat-menu>
    </div>
    

</div>
