<div class="row">
    <div class="col-md-12">
        <div style="display: block;">
            <canvas [id]="measurement._id" baseChart
                [data]="chartData"
                [options]="chartOptions"
                [legend]="true"
                [plugins]="chartPlugins"
                [type]="chartType">
            </canvas>
        </div>
    </div>
</div>
