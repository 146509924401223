import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() {

  }

  getItem(item: string) {
    return window.localStorage.getItem(item);
  }

  setItem(item: string, content: any) {
    window.localStorage.setItem(item, content);
  }

  removeItem(item: string) {
    window.localStorage.removeItem(item);
  }


}
