import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BillingaccountDialogComponent } from '../dialogs/billingaccount-dialog/billingaccount-dialog.component';
import { UsersService } from '@cosmos/angular/api';
import { CompaniesService } from '@cosmos/angular/api';
import { BillingAccountsService } from '@cosmos/angular/api';
import { ActionDefinition, ColumnDefinition } from '@cosmos/angular/ui';
import { ConfirmationDialogComponent } from '@cosmos/angular/ui';
import { TranslateService } from '@ngx-translate/core';
import { ErrorService } from '@cosmos/angular/utils';



@Component({
  selector: 'cosmos-billing-accounts',
  templateUrl: './billing-accounts.component.html',
  styleUrls: ['./billing-accounts.component.css']
})
export class BillingAccountsComponent implements OnInit {

  users: any = [];
  companies: any = [];
  billingAccounts: any = [];
  confirmDialog: MatDialogRef<ConfirmationDialogComponent>;

  actions: ActionDefinition[] = [
    {name: 'edit', label: 'table.actions.edit', icon: 'edit', sticky: true},
    {name: 'delete', label: 'table.actions.delete', icon: 'delete', sticky: true},
  ];

  columns: ColumnDefinition[] = [
    {name: '_id', label: 'table.labels.id', visible: true, sortable: true, draggable: true},
    {name: 'name', label: 'table.labels.name', visible: true, sortable: true, draggable: true},
    {name: 'owner.name', label: 'table.labels.owner', visible: true, sortable: true, draggable: true},
    {name: 'company.name', label: 'table.labels.company', visible: true, sortable: true, draggable: true},
  ];

  constructor(
    private baService: BillingAccountsService,
    public baDialog: MatDialog,
    private usersService: UsersService,
    private companiesService: CompaniesService,
    private translateService: TranslateService,
    private errorService: ErrorService,
  ) { }

  ngOnInit(): void {
    this.getData();
    this.usersService.find().subscribe({
      next: (response) => {this.users = response.body;},
      error: (e) => this.errorService.handleError(e),
    });

    this.companiesService.find().subscribe({
      next: (response) => {this.companies = response.body;},
      error: (e) => this.errorService.handleError(e),
    });
  }

  openDialog(baId?: string, object?: any): any {
    //let billing_account: any;
    let create = true;
    const companies = this.companies;
    const users = this.users;
    const billing_account= object;

    if (baId)  {
      create = false;
    }

     const dialogRef = this.baDialog.open(BillingaccountDialogComponent, {
       data: { billing_account, create, users, companies }
     });

     dialogRef.afterClosed().subscribe(async result => {
       if (result !== undefined) {
         if (create) {
          this.baService.create(result).subscribe({
            error: (e) => this.errorService.handleError(e),
            complete: () => { this.getData();}
          });
        } else {
          this.baService.update(result).subscribe({
            error: (e) => this.errorService.handleError(e),
            complete: () => {this.getData();}
          });
     }
     }
  });
}

  getData(): any {
    this.baService.find('/?populate_owner=name&populate_company=name').subscribe({
      next: (response) => {this.billingAccounts = response.body;},
      error: (e) => this.errorService.handleError(e),
    });
  }
  deleteElement(row: any): any {
    this.confirmDialog = this.baDialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });

    this.confirmDialog.componentInstance.confirmMessage = this.translateService.instant("dialog.subtitle.delete.billing-account") +' ' + row.name ;

    this.confirmDialog.afterClosed().subscribe(result => {
      if(result) {
        this.baService.delete(row._id).subscribe({
          error: (e) => this.errorService.handleError(e),
          complete: () => this.getData()
        })
      }
    })
  }

  rowAction(event: any): any {
    if (event.action === 'edit') {
      this.openDialog(event.row._id, event.row);
    } else if (event.action === 'delete') {
      this.deleteElement(event.row);
    }
  }
}
