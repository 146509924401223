<!-- <mat-sidenav-container autosize>
  <mat-sidenav #sidenav fixedInViewport="false" mode="side" [opened]="openNav">
    <div style="height: 100%; display:  flex; flex-direction: column;">

       <mat-nav-list>
       <mat-list-item routerLink="/users">
          <mat-icon matListItemIcon>person_filled</mat-icon>
          <h4 matListItemTitle *ngIf="isExpanded">{{"dashboard.sidenav.users" | translate}}</h4>
        </mat-list-item> -->
        <!--
        <mat-list-item routerLink="/companies">
          <mat-icon matListItemIcon>domain</mat-icon>
          <h4 matListItemTitle *ngIf="isExpanded">{{"dashboard.sidenav.companies" | translate}}</h4>
        </mat-list-item>
        <mat-list-item routerLink="/billing-accounts" *ngIf="this.user.roles.indexOf('superadmin') > -1">
          <mat-icon matListItemIcon>savings</mat-icon>
          <h4 matListItemTitle *ngIf="isExpanded">{{"dashboard.sidenav.billing-accounts" | translate}}</h4>
        </mat-list-item> -->
      <!-- </mat-nav-list> -->

      <!-- <mat-divider></mat-divider> -->
<!--

      <mat-nav-list>
        <mat-list-item routerLink="/concretes">
          <mat-icon matListItemIcon>insights</mat-icon>
          <h4 matListItemTitle *ngIf="isExpanded">{{"dashboard.sidenav.concretes" | translate}}</h4>
        </mat-list-item>
        <mat-list-item routerLink="/devices">
          <mat-icon matListItemIcon>memory</mat-icon>
          <h4 matListItemTitle *ngIf="isExpanded">{{"dashboard.sidenav.devices" | translate}}</h4>
        </mat-list-item>
        <mat-list-item routerLink="/gateways">
          <mat-icon matListItemIcon>router</mat-icon>
          <h4 matListItemTitle *ngIf="isExpanded">{{"dashboard.sidenav.gateways" | translate}}</h4>
        </mat-list-item>
      </mat-nav-list>

      <mat-divider></mat-divider>

      <mat-nav-list>
        <mat-list-item routerLink="/projects">
          <mat-icon matListItemIcon>folder</mat-icon>
          <h4 matListItemTitle *ngIf="isExpanded">{{"dashboard.sidenav.projects" | translate}}</h4>
        </mat-list-item>
        <mat-list-item routerLink="/stages">
          <mat-icon matListItemIcon>topic</mat-icon>
          <h4 matListItemTitle *ngIf="isExpanded">{{"dashboard.sidenav.stages" | translate}}</h4>
        </mat-list-item>
        <mat-list-item routerLink="/measurements">
          <mat-icon matListItemIcon>description</mat-icon>
          <h4 matListItemTitle *ngIf="isExpanded">{{"dashboard.sidenav.measurements" | translate}}</h4>
        </mat-list-item>
      </mat-nav-list> -->
      
      
      <!-- <mat-divider style="flex-grow:1"></mat-divider> -->

      <!-- CONFIGURACION Y SOPORTE -->
      <!-- <mat-nav-list>
        <mat-list-item routerLink="/support">
          <mat-icon matListItemIcon>help</mat-icon>
          <h4 matListItemTitle *ngIf="isExpanded">{{"dashboard.sidenav.support" | translate}}</h4>
        </mat-list-item>
        <mat-list-item routerLink="/settings">
          <mat-icon matListItemIcon>settings</mat-icon>
          <h4 matListItemTitle *ngIf="isExpanded">{{"dashboard.sidenav.settings" | translate}}</h4>
        </mat-list-item>
      </mat-nav-list> -->

      <!-- EXPANDIR Y COLAPSAR -->
      <!-- <mat-divider></mat-divider>

      <mat-nav-list>
        <mat-list-item *ngIf="!isExpanded" (click)="expandSidebar(true)">
          <mat-icon matListItemIcon>navigate_next</mat-icon>
        </mat-list-item>
        <mat-list-item *ngIf="isExpanded" (click)="expandSidebar(false)">
          <mat-icon matListItemIcon >navigate_before</mat-icon>
        </mat-list-item>
      </mat-nav-list>

    </div>
  </mat-sidenav>

  <mat-sidenav-content>
    <main class="container-fluid">
      <cosmos-breadcrumbs></cosmos-breadcrumbs>
      <router-outlet></router-outlet>
    </main>
  </mat-sidenav-content>
</mat-sidenav-container> -->

<mat-sidenav-container autosize>
  <mat-sidenav #sidenav fixedInViewport="false" mode="side" [opened]="openNav">
    <div style="height: 100%; display:  flex; flex-direction: column;">

      <mat-nav-list>
        <mat-list-item routerLink="/users">
          <mat-icon matListItemIcon>person</mat-icon>
          <h4 matListItemTitle *ngIf="isExpanded">{{ "dashboard.sidenav.users" | translate}}</h4>
        </mat-list-item>
        <mat-list-item routerLink="/companies">
          <mat-icon matListItemIcon>domain</mat-icon>
          <h4 matListItemTitle *ngIf="isExpanded">{{ "dashboard.sidenav.companies" | translate}}</h4>
        </mat-list-item>
        <mat-list-item routerLink="/billing-accounts" *ngIf="this.user.roles.indexOf('superadmin') > -1">
          <mat-icon matListItemIcon>savings</mat-icon>
          <h4 matListItemTitle *ngIf="isExpanded">{{"dashboard.sidenav.billing-accounts" | translate}}</h4>
        </mat-list-item>
      </mat-nav-list>

      <mat-divider></mat-divider>

      <mat-nav-list>
        <mat-list-item routerLink="/concretes">
          <mat-icon matListItemIcon>insights</mat-icon>
          <h4 matListItemTitle *ngIf="isExpanded">{{"dashboard.sidenav.concretes" | translate}}</h4>
        </mat-list-item>
        <mat-list-item routerLink="/devices">
          <mat-icon matListItemIcon>memory</mat-icon>
          <h4 matListItemTitle *ngIf="isExpanded">{{"dashboard.sidenav.devices" | translate}}</h4>
        </mat-list-item>
        <mat-list-item routerLink="/gateways">
          <mat-icon matListItemIcon>router</mat-icon>
          <h4 matListItemTitle *ngIf="isExpanded">{{"dashboard.sidenav.gateways" | translate}}</h4>
        </mat-list-item>
      </mat-nav-list>

      <mat-divider></mat-divider>

      <mat-nav-list>
        <mat-list-item routerLink="/projects">
          <mat-icon matListItemIcon>folder</mat-icon>
          <h4 matListItemTitle *ngIf="isExpanded">{{"dashboard.sidenav.projects" | translate}}</h4>
        </mat-list-item>
        <mat-list-item routerLink="/stages">
          <mat-icon matListItemIcon>topic</mat-icon>
          <h4 matListItemTitle *ngIf="isExpanded">{{"dashboard.sidenav.stages" | translate}}</h4>
        </mat-list-item>
        <mat-list-item routerLink="/measurements">
          <mat-icon matListItemIcon>description</mat-icon>
          <h4 matListItemTitle *ngIf="isExpanded">{{"dashboard.sidenav.measurements" | translate}}</h4>
        </mat-list-item>
      </mat-nav-list>

      <mat-divider style="flex-grow:1"></mat-divider>

      <!-- CONFIGURACION Y SOPORTE -->
      <mat-nav-list>
        <mat-list-item routerLink="/support">
          <mat-icon matListItemIcon>help</mat-icon>
          <h4 matListItemTitle *ngIf="isExpanded">{{"dashboard.sidenav.support" | translate}}</h4>
        </mat-list-item>
        <mat-list-item routerLink="/settings">
          <mat-icon matListItemIcon>settings</mat-icon>
          <h4 matListItemTitle *ngIf="isExpanded">{{"dashboard.sidenav.settings" | translate}}</h4>
        </mat-list-item>
      </mat-nav-list>

      <!-- EXPANDIR Y COLAPSAR -->
      <mat-divider></mat-divider>

      <mat-nav-list>
        <mat-list-item *ngIf="!isExpanded" (click)="expandSidebar(true)">
          <mat-icon matListItemIcon>navigate_next</mat-icon>
        </mat-list-item>
        <mat-list-item *ngIf="isExpanded" (click)="expandSidebar(false)">
          <mat-icon matListItemIcon >navigate_before</mat-icon>
        </mat-list-item>
      </mat-nav-list>

    </div>
  </mat-sidenav>

  <mat-sidenav-content>
    <main class="container-fluid">
      <cosmos-breadcrumbs></cosmos-breadcrumbs>
      <router-outlet></router-outlet>
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>
