import { Component, Injectable, Input, EventEmitter, Output } from '@angular/core';
import { environment } from '../../../../../../environments/environment';
import { HttpClient, HttpResponse, HttpEventType} from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { catchError, map } from 'rxjs';
import { ErrorService } from '@cosmos/angular/utils';
import { FileUploadService } from './file-upload.service';
import { Project } from '@cosmos/shared';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'cosmos-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
@Injectable({
  providedIn: 'root'
})


export class FileUploadComponent {

  @Output()
  fileuploaded: EventEmitter<Project> = new EventEmitter<Project>();

  showBar = false;
  progressNumber: number;
  fileName= '';
  file: File[] = null;
  canUpload = false;
  formData:FormData;

  @Input() id: string;
  @Input() services: string;
  @Input() company: string;
  @Input() owner: string;

  @Input() filetype: string;
  @Input() context: string;
  @Input() uploadDestination: string;
  @Input() isMultiple = false;

  constructor(
    private _snackBar: MatSnackBar,
    private fileUploadService: FileUploadService,
    private translateService: TranslateService,
  ) { }

  onFileDropped($event){
    //checks if dropped file is of correct filetype, before sending
    this.showBar = false;
    this.progressNumber = 0;
    const test: File[] = Array.from($event)
    let validType = true;
    test.forEach(element => {
      if(element.type.match(this.filetype) == null) {validType = false;}
    });
    if(this.isMultiple == false && test.length != 1) {this.openSnackBar(this.translateService.instant('file-upload.too-many'))}
    else{
      if(validType) this.onFileSelected($event); else this.openSnackBar(this.translateService.instant('file-upload.wrong-filetype'))  ;
    }
  }

  openSnackBar(message: string) {
    this._snackBar.open(message,undefined,{duration : 3000});
  }

  onFileSelected(event){
    if(event.target == undefined){this.file = event} else {this.file = event.target.files;}
    if(this.isMultiple) {
      this.fileName = '';
      const filesArray: File[] = Array.from(this.file);
      filesArray.forEach(element => {
        this.fileName += "''" + element.name + "'' ";
      });
      this.canUpload = true;
    }
    else{
      this.fileName = this.file[0].name;
      this.canUpload = true;
    }
  }

  onClick(){
    this.showBar = true;
    this.progressNumber = 0;
    if(this.file !== null){
      let response;

      this.fileUploadService.uploadFile(this.uploadDestination, this.file, this.id, this.services, this.filetype, this.company, this.context).subscribe({
        next: (event: any) =>{

          if(event.type == HttpEventType.UploadProgress) {
            this.progressNumber = Math.round(100 * event.loaded / event.total);
          } else if (event.type == HttpEventType.Response)  response = event;
          },
          error: (e) => this.openSnackBar(JSON.stringify(e.error)),
          complete: () => {
            this.openSnackBar(this.translateService.instant('file-upload.upload-correct'));
            this.fileuploaded.emit(response.body);
            //Hides Uploaders
            this.canUpload = false;
          }
        })
      }
    }
}
