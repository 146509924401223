import { Component, Input, OnInit, OnChanges, ViewChild, AfterViewInit } from '@angular/core';

import 'chartjs-adapter-moment';
import { Interaction, InteractionModeFunction } from 'chart.js';
import { CrosshairPlugin } from '../plugins/chartjs-plugin-crosshair.esm';
import { interpolate } from '../plugins/interpolate';
import { TranslateService } from '@ngx-translate/core';
import { BaseChartDirective } from 'ng2-charts';

declare module 'chart.js' {
  interface InteractionModeMap {
    interpolate: InteractionModeFunction;
  }
}

Interaction.modes.interpolate = interpolate;

@Component({
  selector: 'cosmos-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent implements AfterViewInit, OnChanges {

  @ViewChild(BaseChartDirective) chart: BaseChartDirective;


  @Input()
  id: string;

  @Input()
  dataX: Number[];

  @Input()
  dataY: Number[];

  @Input()
  threshold: Number = null;

  //CHART
  // chartType = 'scatter';
  chartType = 'line';
  chartPlugins = [CrosshairPlugin];
  chartData = {
    datasets: [],
    labels: []
  };

  chartOptions = {
    scales: {
      // Define the X axis and its components.
      x: {
        type: 'linear',
        // time: {
        //   unit: 'day',
        //   displayFormats: {
        //     day: 'dd'
        //   }
        // },
        ticks: {
          source: 'data',
          callback: function(val, index) {return 'Día ' + val},
          //Enables Days in legend
          major: {
             enabled: true,
          },
        },
      },
      y: {
        max: 50,
        min: 0,
        type: 'linear',
        ticks: {
        }
      }
    },
    plugins: {
      tooltip: {
        enabled: true,
        // mode: 'interpolate',
        mode: 'index',
        intersect: false
      },
      crosshair: {
        zoom: {
          enabled: true,
        }
      },
      legend: { display: true },
    }
  };

  constructor(
    private translateService: TranslateService,
  ) {}

  ngAfterViewInit(): void {
    const time = this.dataX;

    this.chartData.datasets = [this.generateDataset(time, this.dataY, this.translateService.instant('chart.legend.strength'))];
    this.dataX.forEach(label => this.chartData.labels.push('Día ' + label));
    if (this.threshold) {
      const threshold_array = [];
      this.dataX.forEach(label => threshold_array.push(this.threshold));

      this.chartData.datasets.push(this.generateDataset(time, threshold_array, 'fck', [6,6]))
    }

    if(this.chart) {
      this.chart.chart.update();
    }

  }

  ngOnChanges() {
    const time = this.dataX;
    this.chartData.labels = [];
    this.dataX.forEach(label => this.chartData.labels.push('Día ' + label));
    
    this.chartData.datasets = [this.generateDataset(time, this.dataY, this.translateService.instant('chart.legend.strength'))];

    if (this.threshold) {
      const threshold_array = [];
      this.dataX.forEach(label => threshold_array.push(this.threshold));

      this.chartData.datasets.push(this.generateDataset(time, threshold_array, 'fck', [6,6]))
    }

    if(this.chart) {
      this.chart.chart.update();
    }
  }

  // TO GENERATE SCATTER DATA (DIFFERENT TIME AXIS)

  generateDataset(dataX, dataY, label, dashed?) {
    const data = [];

    dataX.forEach((element,index) => {
      data.push({ x: element, y: dataY[index] });
    });

    const dataset = {
      // backgroundColor: color,
      // borderColor: color,
      showLine: true,
      fill: false,
      pointRadius: 2,
      label: label,
      data: data,
      lineTension: 0,
      interpolate: true,
      cubicInterpolationMode: 'monotone'
    };

    if(dashed) {
      dataset['borderDash'] = dashed;
    }
    return dataset;
  }

}
