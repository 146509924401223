import { FormFieldBase, FormFieldFileManager } from "@cosmos/angular/ui";
import { of } from "rxjs";

export class FormFieldsClassFileManager {
  formFields: FormFieldBase<string>[];

  constructor(data:any) {
    // COMMON FIELDS
    this.formFields = [
        new FormFieldFileManager({
          key:'file',
          label: "fields.files",
          value: data,
          required: false,
          type: "Projects/" + data.project._id + "/Planos",
          order: 1
        })
    ]
  }

  getCreateFields()
  {
    return of(this.formFields.sort((a, b) => a.order - b.order));
  }
}
