<button mat-icon-button [matMenuTriggerFor]="menuLanguage">
  <mat-icon>translate</mat-icon>
</button>
<mat-menu #menuLanguage="matMenu">


    <!-- <button mat-menu-item routerLink="/account">
      <mat-icon>account_circle</mat-icon>
      <span>Account</span>
    </button> -->
    <button mat-menu-item (click)="changeLanguage('es_ES')" [id]="'dashboard.id' | translate">
      ES
    </button>
    <button mat-menu-item (click)="changeLanguage('en_US')">
      EN
    </button>

</mat-menu>
