<!-- CONTENT -->
<h1>{{"company.title" | translate}}</h1>
<p>{{"company.subtitle" | translate}}</p>
<div *ngIf="this.user.roles.indexOf('superadmin')>-1" class="container-fluid">
  <div class="row">
    <button mat-raised-button color="primary" (click)="openDialog()">{{"company.button" | translate}}</button>
  </div>
</div>

<mat-list>
  <mat-divider></mat-divider>
</mat-list>

<cosmos-data-table
  [tableData]="companies"
  [tableColumns]="columns"
  [isFilterable]="true"
  [isPageable]="true"
  [toggleColumnsVisibility]="true"
  [rowActions]="actions"
  (rowAction)="rowAction($event)">
</cosmos-data-table>

