<div>
  <form [formGroup]="form" class="w-100">

    <!-- <mat-form-field appearance="standard" class="input-full-width">
      <input matInput formControlName="key" id="key" type="text">
    </mat-form-field> -->


    <div *ngFor="let field of fields" class="form-row">
      <cosmos-dynamic-form-field class="w-100" [field]="field" [form]="form"></cosmos-dynamic-form-field>
    </div>

    <div class="form-row justify-content-center">
      <!-- <button type="submit" [disabled]="!form.valid">Save</button> -->
      <button *ngIf="save" mat-raised-button color="primary" [disabled]="!form.valid" (click)="saveForm()">{{ (actionText?actionText:'button.save') | translate}}</button>
      <button *ngIf="!save" mat-icon-button color="primary" [disabled]="!form.valid" (click)="saveForm()" class="right_header"><mat-icon>close</mat-icon></button>
    </div>
  </form>

  <!-- <div *ngIf="payLoad" class="form-row">
    <strong>Saved the following values</strong><br>{{payLoad}}
  </div> -->
</div>
