import { FormFieldBase, FormFieldDropdown, FormFieldTextbox } from "@cosmos/angular/ui";
import { of } from "rxjs";

export class FormFieldsClass {
  formFields: FormFieldBase<string>[];

  constructor(create: boolean, data:any, users:any[], companies: any[], projects: any[], superadmin: boolean) {
    // COMMON FIELDS
    this.formFields = [
        new FormFieldTextbox({
            key: 'name',
            label: 'fields.name',
            value: create?'':data.name,
            type:'text',
            required: true,
            order: 1
        }),
        new FormFieldTextbox({
            key: 'description',
            label: 'fields.description',
            value: create?'':data.description,
            type:'text',
            required: false,
            order: 2
        }),
          new FormFieldDropdown({
            key: 'project',
            label: 'fields.project',
            value: create?'':data.project,
            options: projects,
            required: true,
            order: 5
          }),
    ]

    // EDIT ONLY FIELDS
    if(superadmin) {
      this.formFields.push(
        new FormFieldDropdown({
          key: 'owner',
          label: 'fields.owner',
          value: create?'':data.owner,
          options: users,
          required: true,
          order: 3
    }),
      new FormFieldDropdown({
          key: 'company',
          label: 'fields.company',
          value: create?'':data.company,
          options: companies,
          required: true,
          order: 4
        }),
      );
    }
  }

  getCreateFields()
  {
    return of(this.formFields.sort((a, b) => a.order - b.order));
  }
}
