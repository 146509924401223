<!-- CONTENT -->
<h1>{{"devices.title" | translate}}</h1>
<p>{{"devices.subtitle" | translate}}</p>

<div *ngIf="this.user.roles.indexOf('superadmin') > -1" class="container-fluid">
  <div class="row">
    <button mat-raised-button color="primary" (click)="openDialog()">{{"devices.button" | translate}}</button>
  </div>
</div>

<mat-list>
  <mat-divider></mat-divider>
</mat-list>

<cosmos-data-table
  *ngIf="devices"
  [tableData]="devices"
  [tableColumns]="columns"
  [isFilterable]="true"
  [isPageable]="true"
  [toggleColumnsVisibility]="true"
  [rowActions]="actions"
  [queryFields]="queryFields"
  (rowAction)="rowAction($event)"
  (queryChange)="queryEvent($event)">
</cosmos-data-table>
