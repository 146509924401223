import { AuthService, JwtService } from '@cosmos/angular/utils';

export function initAuth(authService: AuthService, jwtService: JwtService): any {
  return () => {
    if (jwtService.getToken()) {
      // There is a user logged in, try to get details
      return authService.updateCurrentUserUsingToken();
    } else {
      // Remove any potential remnants of previous auth states
      authService.purgeAuth();
    }
  }
}
