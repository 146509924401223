<div class="row my-4">
  <!-- <div class=""> -->
    <ul class="h5 breadcrumbs">
    <ng-container *ngFor = "let breadcrumb of (breadcrumbs$ | async); last as last; first as first" >
      <a *ngIf = "first && !last" [routerLink]="breadcrumb.url"><mat-icon>home</mat-icon></a>
      <div *ngIf = "first && last" ><mat-icon>home</mat-icon></div>
    </ng-container>

    <ul id="breadcrumbstext">
      <ng-container *ngFor = "let breadcrumb of (breadcrumbs$ | async); last as last; first as first" >
        <li *ngIf = "!first && last" >{{breadcrumb.label | translate}}</li>

        <li class="primary" *ngIf = "!first && !last" [routerLink]="breadcrumb.url"style="cursor: pointer;">{{breadcrumb.label | translate}}</li>
      </ng-container>
    </ul>
  </ul>
  <!-- </div> -->
</div>
