import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorService } from '@cosmos/angular/utils';
import { Measurement } from '@cosmos/shared';
import { catchError, map, Observable } from 'rxjs';
import { environment } from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MeasurementsService {

  constructor(
    private http: HttpClient,
    private errorService: ErrorService,
  ) { }

  find(query='') {
    return this.http
      .get<Measurement[]>(`${environment.API_URL}/measurements${query}`, {observe: 'response'})
        .pipe(
          map(response => {return response;}),
          catchError( (error) => this.errorService.handleError(error))
        );
  }

  findById(id: string, query = ''): Observable<HttpResponse<Measurement>> {
    return this.http
      .get<Measurement>(`${environment.API_URL}/measurements/${id}${query}`, {observe: 'response'})
      .pipe(
        map(response => {return response;}),
        catchError( (error) => this.errorService.handleError(error))
      );
  }

  create(body: Measurement) {
    return this.http
      .post(`${environment.API_URL}/measurements`, body, {observe: 'response'})
      .pipe(
        map(response => {return response;}),
        catchError( (error) => this.errorService.handleError(error))
      );
  }

  update(body: any) {
    return this.http
      .patch(`${environment.API_URL}/measurements/${body[0]}`, body[1], {observe: 'response'})
      .pipe(
        map(response => {return response;}),
        catchError( (error) => this.errorService.handleError(error))
      );
  }

  delete(id: string) {
    return this.http
      .delete(`${environment.API_URL}/measurements/${id}`, {observe: 'response'})
      .pipe(
        map(response => {return response;}),
        catchError( (error) => this.errorService.handleError(error))
      );
  }

  finish(id: string){
    return this.http
    .post(`${environment.API_URL}/measurements/finish/${id}`, {observe: 'response'})
      .pipe(
        map(response => {return response;}),
        catchError( (error) => this.errorService.handleError(error))
      );
  }

  changeDevice(id:string ,body: any){
    return this.http
      .post(`${environment.API_URL}/measurements/changedevice/${id}`,body, {observe:'response'})
        .pipe(
          map(response => {return response;}),
          catchError( (error) => this.errorService.handleError(error))
        );
  }

  downloadCSV(query: string) {
    return this.http
      .get(`${environment.API_URL}/measurements/download?${query}`, {responseType :'blob', observe: "response"})
        .pipe(
          map(response => {return response;}),
          catchError( (error) => this.errorService.handleError(error))
        )
  }

  getPredictions(id: string): Observable<HttpResponse<Measurement>> {
    return this.http
      .get<Measurement>(`${environment.API_URL}/measurements/predictions/${id}`, {observe: 'response'})
      .pipe(
        map(response => {return response;}),
        catchError( (error) => this.errorService.handleError(error))
      );
  }

  downloadReport(id: string) {
    return this.http
      .get(`${environment.API_URL}/measurements/report/${id}`, {responseType :'blob', observe: "response"})
        .pipe(
          map(response => {return response;}),
          catchError( (error) => this.errorService.handleError(error))
        )
  }

  generateReport(id: string) {
    return this.http
      .post(`${environment.API_URL}/measurements/report/${id}`, {}, {responseType :'blob', observe: "response"})
        .pipe(
          map(response => {return response;}),
          catchError( (error) => this.errorService.handleError(error))
        )
  }

  reschedule(id: string, timestampStart: number, timestampEnd: number) {
    return this.http
      .post(`${environment.API_URL}/measurements/reschedule/${id}/${timestampStart}/${timestampEnd}`, {}, {observe: "response"})
        .pipe(
          map(response => {return response;}),
          catchError( (error) => this.errorService.handleError(error))
        )
  }
}
