import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormFieldBase } from '@cosmos/angular/ui';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { FormFieldsClass } from '../../concretes/concretes.forms';

@Component({
  selector: 'cosmos-concrete-dialog',
  templateUrl: './concrete-dialog.component.html',
  styleUrls: ['./concrete-dialog.component.scss']
})
export class ConcreteDialogComponent implements OnInit {

  title = '';
  //concreteForm: FormGroup;
  fields: Observable<FormFieldBase<string>[]>;

  constructor(
    public dialogRef: MatDialogRef<ConcreteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translateService: TranslateService,
  ) { }

  ngOnInit(): void {

    const users: {key: string, value: string}[] = [];
    const companies: {key: string, value: string}[] = [];
    const projects: {key: string, value: string}[] = [];

    if(this.data.users) {
      for(const user of this.data.users) users.push({key: user['_id'], value: user['name'] });
    }

    if(this.data.companies) {
      for(const company of this.data.companies) companies.push({key: company['_id'], value: company['name'] });
    }

    if(this.data.projects) {
      for(const project of this.data.projects) projects.push({key: project['_id'], value: project['name'] });
    }

    if (this.data.create) {

      this.title = this.translateService.instant('dialog.title.create.concrete');

      this.fields = new FormFieldsClass(true, this.data.concrete, companies, projects).getCreateFields();

    } else {
      this.title = this.translateService.instant('dialog.title.edit.concrete') + ' ' + this.data.concrete._id;
      this.fields = new FormFieldsClass(false, this.data.concrete, companies, projects).getCreateFields();
    }

  }

  handleEvent($event) {

    //---------- Transforms data from string to number
    const temp = $event;
    temp.A_VAL = parseFloat(temp.A_VAL);
    temp.B_VAL = parseFloat(temp.B_VAL);
    temp.ABS_TEMP = parseFloat(temp.ABS_TEMP);
    //----------
    if(this.data.create) this.dialogRef.close(temp);
    else this.dialogRef.close([this.data.concrete._id, temp]);
  }

}
