import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, RoleGuard } from '@cosmos/angular/utils';
import { BreadcrumbsResolverService, MeasurementComponent, SettingsGenericComponent, UserGenericComponent } from '@cosmos/angular/ui';

import { BillingAccountsComponent } from './billing-accounts/billing-accounts.component';
import { CompaniesComponent } from './companies/companies.component';
import { ConcretesComponent } from './concretes/concretes.component';
import { DashboardComponent } from './dashboard.component';
import { DevicesComponent } from './devices/devices.component';
import { GatewaysComponent } from './gateways/gateways.component';
import { LandingComponent } from './landing/landing.component';
import { MeasurementsComponent } from './measurements/measurements.component';
import { ProjectsComponent } from './projects/projects.component';
import { SimulationsComponent } from './simulations/simulations.component';
import { StagesComponent } from './stages/stages.component';
import { UsersComponent } from './users/users.component';
import { ProjectComponent } from '@cosmos/angular/ui';
import { StageComponent } from '@cosmos/angular/ui';
import { SimDetailsComponent } from '@cosmos/angular/ui';



const routes: Routes = [
  { path: '',data:{breadcrumb: "Home", role: "admin"}, component: DashboardComponent, canActivate: [AuthGuard, RoleGuard], children: [
    {
      path: '',data:{breadcrumb: ""}, component: LandingComponent, },
        {
          path: 'user', data:{breadcrumb: 'breadcrumbs.user'}, component: UserGenericComponent
        },
        {
          path: 'settings', data:{breadcrumb: 'breadcrumbs.settings'}, component: SettingsGenericComponent
        },
        {
          path: 'users', data:{breadcrumb: 'breadcrumbs.users'}, children: [
            {path: '', data: {breadcrumb: ''}, component: UsersComponent},
            {path: ':user', data: {breadcrumb: ''}, component: UsersComponent},
          ]
        },
        {
          path: 'companies', data:{breadcrumb: 'breadcrumbs.companies'}, component: CompaniesComponent
        },
        {
          path: 'billing-accounts', data:{breadcrumb: 'breadcrumbs.billing-accounts', role: "superadmin"}, component: BillingAccountsComponent, canActivate:[RoleGuard]
        },
        {
          path: 'simulations', data:{breadcrumb: 'breadcrumbs.simulations', role: "superadmin"}, canActivate:[RoleGuard], children: [
            {path: '', data: {breadcrumb: ''}, component: SimulationsComponent},
            {path: ':simulation', data: {breadcrumb: (data: any) => `${data.data[1].body.name}`, resolve: "simulation", public: true}, resolve: { data: BreadcrumbsResolverService}, component: SimDetailsComponent},
          ]
        },
        {
          path: 'concretes', data:{breadcrumb: 'breadcrumbs.concretes'}, component: ConcretesComponent
        },
        {
          path: 'devices', data:{breadcrumb: 'breadcrumbs.devices'}, children: [
            {path: '', data: {breadcrumb: ''}, component: DevicesComponent},
            {path: ':device', data: {breadcrumb: ''}, component: DevicesComponent},
          ]
        },
        {
          path: 'gateways', data:{breadcrumb: 'breadcrumbs.gateways'}, children: [
            {path: '', data: {breadcrumb: ''}, component: GatewaysComponent},
            {path: ':gateway', data: {breadcrumb: ''}, component: GatewaysComponent},
          ]
        },
        {
          path: 'measurements', data:{breadcrumb: 'breadcrumbs.measurements'}, children: [
            {path: '', data: {breadcrumb: ''}, component: MeasurementsComponent},
            {path: ':measurement', data: { breadcrumb: (data: any) => `${data.data[0]} ${data.data[1].body.name}`, resolve: "measurement"},resolve: { data: BreadcrumbsResolverService}, component: MeasurementComponent},
          ]
        },
        {
          path: 'projects', data:{breadcrumb: 'breadcrumbs.projects'}, children: [
            {path: '', data: {breadcrumb: ''}, component: ProjectsComponent},
            {path: ':project', data: {breadcrumb: (data: any) => `${data.data[0]} ${data.data[1].body.name}`, resolve: "project"},resolve: { data: BreadcrumbsResolverService }, children: [
              {path: '', data: {breadcrumb: ''}, component: ProjectComponent},
              {path: ':stage', data: { breadcrumb: (data: any) => `${data.data[0]} ${data.data[1].body.name}`, resolve: "stage"},resolve: { data: BreadcrumbsResolverService }, children: [
                {path: '', data: {breadcrumb: ''}, component: StageComponent},
                {path: ':measurement', data: { breadcrumb: (data: any) => `${data.data[0]} ${data.data[1].body.name}`, resolve: "measurement"},resolve: { data: BreadcrumbsResolverService}, component: MeasurementComponent},
              ]},
            ]},
          ]
        },
        {
          path: 'stages', data:{breadcrumb: 'breadcrumbs.stages'}, children: [
            {path: '', data: {breadcrumb: ''}, component: StagesComponent},
            {path: ':stage', data: { breadcrumb: (data: any) => `${data.data[0]} ${data.data[1].body.name}`, resolve: "stage"},resolve: { data: BreadcrumbsResolverService }, children: [
              {path: '', data: {breadcrumb: ''}, component: StageComponent},
              {path: ':measurement', data: { breadcrumb: (data: any) => `${data.data[0]} ${data.data[1].body.name}`, resolve: "measurement"},resolve: { data: BreadcrumbsResolverService}, component: MeasurementComponent},
            ]},
          ]
        },
  ]
}

];

@NgModule({
  imports: [

    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule,
  ]
})
export class DashboardRoutingModule { }
