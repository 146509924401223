import { FormFieldBase, FormFieldDropdown, FormFieldTextbox } from "@cosmos/angular/ui";
import { of } from "rxjs";


export class FormFieldsClass {
    formFields: FormFieldBase<string>[];

    constructor(create: boolean, data:any, users: any[], companies: any[]){

        this.formFields = [

            new FormFieldTextbox({
                key: 'name',
                label: 'fields.name',
                value: create?'':data.name,
                required: true,
                order: 1
            }),
            new FormFieldDropdown({
                key: 'owner',
                label: 'fields.owner',
                value: create?'':data.owner._id,
                options: users,
                order: 2,
                required: true
            }),
            new FormFieldDropdown({
                key: 'company',
                label: 'fields.company',
                value: create?'':data.company._id,
                options: companies,
                order: 3,
                required: true,
              }),

        ]

        //EDIT ONLY FIELDS

        if(!create) {
            this.formFields.push();
        }
    }

    getCreateFields()
    {
        return of(this.formFields.sort((a, b) => a.order - b.order));
    }
}
