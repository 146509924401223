import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { forkJoin, Observable } from "rxjs";

import { CuringPoolssService, DevicesService, GatewaysService, MeasurementsService, ProjectsService, SimulationsService, StagesService } from '@cosmos/angular/api';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})

//FROM : https://marco.dev/angular-breadcrumb

export class BreadcrumbsResolverService {
    temp = null;
    constructor(
        private projectsService: ProjectsService,
        private stagesService: StagesService,
        private measurementsService: MeasurementsService,
        private devicesService: DevicesService,
        private gatewaysService: GatewaysService,
        private translateService: TranslateService,
        private simulationService: SimulationsService,
        private curingPoolService: CuringPoolssService,
        ){}

    data = {
        project : this.projectsService,
        stage : this.stagesService,
        measurement: this.measurementsService,
        device: this.devicesService,
        gateway: this.gatewaysService,
        simulation: this.simulationService,
        curingpool: this.curingPoolService,
    };

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        const id = route.params[route.data.resolve];
        let type: string;
        switch( route.data.resolve ) {
            case "project" : type ='breadcrumbs.project'; break;
            case "gateway" : type = 'breadcrumbs.gateway'; break;
            case "measurement" : type = 'breadcrumbs.measurement'; break;
            case "stage" : type = 'breadcrumbs.stage'; break;
            case "device" : type = 'breadcrumbs.device'; break;
            case "simulation": type = 'breadcrumbs.simulation'; break;
            case "curingpool": type = 'breadcrumbs.curing-pool'
        }
        const prefix = this.translateService.get(type);
        const suffix = this.data[route.data.resolve].findById(id,'/?select=name');
        return forkJoin([prefix, suffix])
        // return suffix;
      }
}
