import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FieldControlService } from './field-control.service';
import { FormFieldBase } from './form-models/form-field-base.model';

import { Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'cosmos-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
  providers: [FieldControlService],
})
export class DynamicFormComponent implements OnInit {

  @Output() formValue = new EventEmitter<any>();
  @Output() changeEmitter = new EventEmitter<any>();
  @Input() emitValues: string[] | [];
  @Input() actionText: string | '';
  @Input() fields: FormFieldBase<string>[] | null = [];
  @Input() save = true;
  form: UntypedFormGroup;
  payLoad = '';

  constructor(private fcs: FieldControlService) { }

  ngOnInit(): void {
    this.form = this.fcs.toFormGroup(this.fields as FormFieldBase<string>[]);
    if(this.emitValues?.length) {
      this.form.valueChanges.subscribe(data => {
        this.propagateChanges(data);
      });
    }
  }

  saveForm() {
    this.formValue.emit(this.form.value);
    this.payLoad = JSON.stringify(this.form.getRawValue());
  }

  propagateChanges(data) {
    const filteredData = this.emitValues.filter( key => key in data)
                            .reduce((filtered, key) => (filtered[key] = data[key], filtered), {});
    this.changeEmitter.emit(filteredData);
  }

}
