import { Component, OnInit } from '@angular/core';
import { DatePipe, DecimalPipe } from '@angular/common';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ActivatedRoute } from '@angular/router';
import { SimulationsService } from '@cosmos/angular/api';
import { ColumnDefinition } from '@cosmos/angular/ui';
import { ErrorService, StateService } from '@cosmos/angular/utils';
import { Simulation, User } from '@cosmos/shared';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { environment } from '../../../../../../../environments/environment';
import { SimulationQuoteDialogComponent } from './simulation-quote-dialog/simulation-quote-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';




@Component({
  selector: 'cosmos-simulation-details',
  templateUrl: './sim-details.component.html',
  styleUrls: ['./sim-details.component.scss']
})
export class SimDetailsComponent implements OnInit {

  quoteRequestDialog: MatDialogRef<SimulationQuoteDialogComponent>;
  user: User;

  simulation: Simulation;
  cycleTimes = {maturity: [], regulation: [], fixed: []};
  citiesRef: any;
  concreteChartData = {x: [0], y: [0]};
  publicUrl = environment.SIMULATOR_URL + '/viewer/';
  public = true;
  maturityLonger = false;
  cubicMeters = 0;
  form: UntypedFormGroup;

  results: any = {};

  maturity: any = {};
  fixed10: any = {};
  regulation: any = {};

  columnsCycles: ColumnDefinition[] = [
    {name: 'stage', label: 'table.labels.stage', visible: true, sortable: false, draggable: false, footer: 'table.labels.preheader-savings', rightBorder: true},
    {name: 'daysMaturity', label: 'table.labels.daysMaturity', visible: true, sortable: false, draggable: false, type: 'tooltip'},
    {name: 'daysFixed', label: 'table.labels.daysFixed', visible: true, sortable: false, draggable: false, type: 'tooltip'},
    {name: 'daysEHE', label: 'table.labels.daysEHE', visible: true, sortable: false, draggable: false, rightBorder: true, type: 'tooltip'},
    {name: 'daysDiffFixed', label: 'table.labels.daysDiffFixed', visible: true, sortable: false, draggable: false, footer:true, type: "highlight-negative"},
    {name: 'daysDiffEHE', label: 'table.labels.daysDiffEHE', visible: true, sortable: false, draggable: false, footer:true, type: "highlight-negative"},
  ];

  columnsESG: ColumnDefinition[] = [
    {name: 'stage', label: 'table.labels.stage', visible: true, sortable: false, draggable: false, footer: 'table.labels.footer-env-impact', rightBorder: true},
    {name: 'daysDiffFixed', label: 'table.labels.daysDiffFixed', visible: true, sortable: false, draggable: false, type: "highlight-negative"},
    {name: 'daysDiffEHE', label: 'table.labels.daysDiffEHE', visible: true, sortable: false, draggable: false, type: "highlight-negative", rightBorder: true},
    {name: 'savingsVSfixed', label: 'table.labels.daysMaturity', visible: true, sortable: false, draggable: false, footer: '', type: "highlight-negative"},
    {name: 'savingsVSEHE', label: 'table.labels.daysFixed', visible: true, sortable: false, draggable: false, footer: '', type: "highlight-negative"},
  ];

  preHeaderCycles = [
    {name: 'col1', label: '', colspan: 1, rightBorder: true},
    // {name: 'col2', label: ''},
    {name: 'col3', label: 'table.labels.preheader-cycle-time', colspan: 3, rightBorder: true},
    // {name: 'col4', label: ''},
    {name: 'col5', label: 'table.labels.preheader-savings', colspan: 2},
    // {name: 'col6', label: ''},
  ]

  preHeaderESG= [
    {name: 'col1', label: '', colspan: 1, rightBorder: true},
    // {name: 'col2', label: ''},
    {name: 'col3', label: 'table.labels.preheader-cycle-time', colspan: 3, rightBorder: true},
    // {name: 'col4', label: ''},
    {name: 'col5', label: 'table.labels.preheader-savings', colspan: 2},
    // {name: 'col6', label: ''},
  ]

  simTableData = [];

  esgTableData = [];
  esgResults = {};

  average = arr => arr.reduce( (p,c) => p+c, 0) / arr.length;  
  min = arr => Math.min(...arr);
  max = arr => Math.max(...arr);

  constructor(
    private route: ActivatedRoute,
    private simulationService: SimulationsService,
    private errorService: ErrorService,
    public quoteDialog: MatDialog,
    private stateService: StateService,
    private translateService: TranslateService,
    private dp: DatePipe,
    private decimalPipe: DecimalPipe,
  ) { }

  ngOnInit(): void {
    this.user = this.stateService.getCurrentUser();

    const simId = this.route.snapshot.params['simulation'];
    this.public = this.route.snapshot.data.public;
    this.simulationService.getCities().subscribe({
      next: (response) => this.citiesRef = response.body,
      error: (e) => this.errorService.handleError(e),
      complete: () => {},
    });
    this.simulationService.findById(simId).subscribe({
      next: (response) => {
        this.simulation = response.body;
        this.maturity = this.simulation.results.maturity;
        this.fixed10 = this.simulation.results.fixed10;
        this.regulation = this.simulation.results.regulation;
        this.calculateResults();
        // if (this.simulation.cubicMeters && this.simulation.cubicMeters) {
          this.calculateESG();
        // }
        this.form = new UntypedFormGroup({
          cubicMeters: new UntypedFormControl(this.simulation.cubicMeters?this.simulation.cubicMeters:'',Validators.required)
        });
      },
      error: (e) => this.errorService.handleError(e),
      complete: () => {
        this.publicUrl += this.simulation._id;
        this.simulation.results.maturity.stages.forEach(cycle => {
          this.cycleTimes.maturity.push(cycle.end.index - cycle.start.index + 1)
        });
        this.simulation.results.regulation.stages.forEach(cycle => {
          this.cycleTimes.regulation.push(cycle.end.index - cycle.start.index + 1)
        });
        this.simulation.results.fixed10.stages.forEach(cycle => {
          this.cycleTimes.fixed.push(cycle.end.index - cycle.start.index + 1)
        });
        this.simulation.concreteMixture.lab_results.forEach( sample => {
          this.concreteChartData.x.push(sample.days);
          this.concreteChartData.y.push(sample.strength);
        });
      },
    });    
  }

  calculateResults() {
    this.results = {
      percentMatFixed: (100 * (this.fixed10.arrays.temp_array.length - this.maturity.arrays.temp_array.length)/this.fixed10.arrays.temp_array.length),
      percentMatReg: (100 * (this.regulation.arrays.temp_array.length - this.maturity.arrays.temp_array.length)/this.regulation.arrays.temp_array.length),
      daysMat: this.maturity.arrays.temp_array.length,
      daysFixed: this.fixed10.arrays.temp_array.length,
      daysReg: this.regulation.arrays.temp_array.length
    }

    // simTableData = [
    //   {stage: 'Fase 1', startDateMaturity: 182378237, daysMaturity: 5, startDateFixed: 182378237, daysFixed: 10, daysDiff: 5}
    // ]
    let totalSavingsFixed = 0;
    let totalSavingsEHE = 0;

    this.maturity.stages.forEach((stage, index) => {
      const daysStageFixed10 = this.fixed10.stages[index].end.index - this.fixed10.stages[index].start.index + 1;
      const daysStageEHE = this.regulation.stages[index].end.index - this.regulation.stages[index].start.index + 1;

      const daysStageMaturity = stage.end.index-stage.start.index + 1;
      const tableRow = {
        stage: (index + 1),
        // startDateMaturity: stage.start.date,
        daysMaturity: {value: daysStageMaturity, tooltip: `${this.dp.transform(stage.start.date, 'dd-MM-yyyy')} - ${this.dp.transform(stage.end.date, 'dd-MM-yyyy')}`},
        // startDateFixed: this.fixed10.stages[index].start.date,
        daysFixed: {value: daysStageFixed10, tooltip: `${this.dp.transform(this.fixed10.stages[index].start.date, 'dd-MM-yyyy')} - ${this.dp.transform(this.fixed10.stages[index].end.date, 'dd-MM-yyyy')}`},
        daysDiffFixed: daysStageFixed10 - daysStageMaturity,
        daysEHE: {value: daysStageEHE, tooltip: `${this.dp.transform(this.regulation.stages[index].start.date, 'dd-MM-yyyy')} - ${this.dp.transform(this.regulation.stages[index].end.date, 'dd-MM-yyyy')}`},
        daysDiffEHE: daysStageEHE - daysStageMaturity
      };
      totalSavingsFixed += tableRow.daysDiffFixed;
      totalSavingsEHE += tableRow.daysDiffEHE;
      this.simTableData.push(tableRow);

      if(daysStageFixed10 - daysStageMaturity < 0) this.maturityLonger = true;
    })

    this.columnsCycles[4].footer = totalSavingsFixed;
    this.columnsCycles[5].footer = totalSavingsEHE;

  }

  calculateESG() {
    this.esgTableData = [];

    // CALCULO COSMOS - variable a metros cubicos y a días
    // const impactReduction = 14.7; // kgCO2eq/m3/dia
    // const cubicMeters = this.simulation.cubicMeters;
    // const cubicMetersPerStage = cubicMeters/this.simTableData.length;
    // 
    // CALCULO MACE - en funcion de días y parametro estándar
    const impactReduction = 362.07; // kgCO2eq/dia
    // 
    let totalImpactFixed = 0;
    let totalImpactEHE = 0;

    this.simTableData.forEach((row, index) => {
      // COSMOS
      // const savingsVSfixed = cubicMetersPerStage*impactReduction*row.daysDiffFixed;
      // const savingsVSEHE = cubicMetersPerStage*impactReduction*row.daysDiffEHE;

      // MACE
      const savingsVSfixed = impactReduction*row.daysDiffFixed;
      const savingsVSEHE = impactReduction*row.daysDiffEHE;
      const esgRow = {
        stage: row.stage,
        daysDiffFixed: row.daysDiffFixed,
        daysDiffEHE: row.daysDiffEHE,
        savingsVSfixed: savingsVSfixed,
        savingsVSEHE: savingsVSEHE
      }

      totalImpactFixed += savingsVSfixed;
      totalImpactEHE += savingsVSEHE;

      this.esgTableData.push(esgRow);
    });

    this.columnsESG[3].footer = this.decimalPipe.transform(totalImpactFixed, '1.0-2');
    this.columnsESG[4].footer = this.decimalPipe.transform(totalImpactEHE, '1.0-2');
    this.esgResults = {'fixed': totalImpactFixed, 'ehe': totalImpactEHE};

    console.log(this.esgTableData);

  }

  showDetailedPlanning() {
    this.simulationService.update([this.route.snapshot.params['simulation'], {details: true}]).subscribe({
      next: (response) => {this.simulation = response.body;},
      error: (e) => this.errorService.handleError(e),
      complete: () => {},
    })
  }

  shareToggle(event: MatSlideToggleChange) {
    this.simulationService.update([this.route.snapshot.params['simulation'], {public: event.checked}]).subscribe({
      next: (response) => {this.simulation = response.body;},
      error: (e) => this.errorService.handleError(e),
      complete: () => {},
    })
  }

  openQuoteDialog() {
    const dialogRef = this.quoteDialog.open(SimulationQuoteDialogComponent, {data: {phone: this.user.phone}, width: '40%'});
    dialogRef.afterClosed().subscribe( async result => {
      if(result != undefined) {
        
        this.requestQuote(result.phone, result.comments);
      }
    });
  }

  requestQuote(phoneNumber, comments) {
    this.simulationService.getQuote([this.route.snapshot.params['simulation'], {quoteRequested: true, quotePhone: phoneNumber, comments: comments}]).subscribe({
      next: (response) => {this.simulation = response.body;},
      error: (e) => this.errorService.handleError(e),
      complete: () => {},
    })
  }

  calculateImpact() {
    // console.log(this.form.controls['cubicMeters'].value);
    this.simulationService.calculateImpact([this.route.snapshot.params['simulation'], {cubicMeters: this.form.controls['cubicMeters'].value}]).subscribe({
      next: (response) => {
        this.simulation = response.body;
        this.calculateESG();
        // console.log(response);
      },
      error: (e) => this.errorService.handleError(e),
      complete: () => {},
    })
  }

}
