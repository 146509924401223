import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FormFieldBase } from '../form-models/form-field-base.model';


// CHIPS
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
// import { MatChipInputEvent } from '@angular/material/chips';
import { map, Observable, startWith } from 'rxjs';
import { ProjectsService } from '@cosmos/angular/api';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FileUploadService } from '../../file-upload/file-upload.service';
import { ErrorService } from '@cosmos/angular/utils';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'cosmos-dynamic-form-field',
  templateUrl: './dynamic-form-field.component.html',
  styleUrls: ['./dynamic-form-field.component.scss']
})
export class DynamicFormFieldComponent implements OnInit {

  //CHIPS
  inputControl: UntypedFormControl = new UntypedFormControl;
  chipValues: any[] = [];
  filteredOptions: Observable<any[]>;
  test:any = [];
  @ViewChild('chipInput') chipInput: ElementRef<HTMLInputElement>;
  @ViewChild('select') mySelect;

  scanner = false;

  @Input() field!: FormFieldBase<any>;
  @Input() form!: UntypedFormGroup;
  get isError() { return this.form.controls[this.field.key].hasError('required'); }

  constructor(
    private projectsService: ProjectsService,
    private translateService: TranslateService,
    private snackBar: MatSnackBar,
    private fileUploadService: FileUploadService,
    private errorService: ErrorService,
    ) {
  }

  // CHIPS
  ngOnInit() {
    if ((this.field.controlType === 'chips' || this.field.controlType === 'number-chips' ) && this.field.value) this.chipValues = this.field.value;
    // if (this.field.controlType === 'dropdown' && this.field.multiple) {};
    this.filteredOptions = this.inputControl.valueChanges.pipe(
      startWith(null),
      map((userinput) => (userinput ? this._filter(userinput) : this.field.options.slice())),
    );

    if (this.field.reflect) {

      this.form.get(this.field.reflect.formField).valueChanges.subscribe( newValue => {
        if (newValue) {
          const checkpoints = this.field.reflect.dataset.find(concrete => concrete.key === newValue);
          if (checkpoints) {
            this.chipValues = []
            this.chipValues.push(...checkpoints[this.field.reflect.foreignField]);
            this.form.controls[this.field.key].setValue(this.chipValues);
          }
        }
      });
    }
  }
  chipInputRemove(value): void {
    const index = this.chipValues.indexOf(value);

    if (index >= 0) {
      this.chipValues.splice(index, 1);
      this.form.controls[this.field.key].setValue(this.chipValues);
    }
  }

  // selectChanges(){
  // }

  numberChipInputSelected($event) {
    this.chipValues.push(Number($event.value));
    this.chipValues.sort(function(a, b){return a-b});
    this.chipInput.nativeElement.value = '';
    this.form.controls[this.field.key].setValue(this.chipValues);
  }

  chipInputSelected($event: MatAutocompleteSelectedEvent): void {
    const key = $event.option.value;
    const value = $event.option.viewValue;
    this.chipValues.push({key, value});
    this.chipInput.nativeElement.value = '';
    this.inputControl.setValue('');
    this.form.controls[this.field.key].setValue(this.chipValues);
  }

  private _filter(value): any[] {
    const filterValue = value.toLowerCase();
    return this.field.options.filter(opt => opt['value'].toLowerCase().includes(filterValue));
  }

  removeShared(user: any, field: any) {

    let body;
    if(field.key === "shared_w") { body = {"$pull": {"shared_w": user._id}};}
    else { body = {"$pull": {"shared_r": user._id}};}

    this.projectsService.update([field.value._id, body]).subscribe({
      next: () => this.field.value = field.value,
      error: (e) => this.errorService.handleError(e),
      complete: () => {
        this.snackBar.open(this.translateService.instant("projects.shared.deleted"), "OK");
        const shared_r = field.value.shared_r;
        shared_r.forEach((element, index) => {
            if(element == user)  shared_r.splice(index,1)
          });
        field.value.shared_r = shared_r;

        const shared_w = field.value.shared_w;
        shared_w.forEach((element, index) => {
            if(element == user)  shared_w.splice(index,1)
          });
        field.value.shared_w = shared_w;
      }
    });


  }

  changeImageEventHandle() {
    this.fileUploadService.find('?serviceId=' + this.field.value.project._id).subscribe({
      next: (response) => this.field.value.files = response.body,
      error: (e) => this.errorService.handleError(e.error),
    });
  }

  changeReportImage(image) {
    // console.log(image.path.split('/')[1]);
    this.projectsService.update([image.path.split('/')[1], {reportImage: image.filename}]).subscribe({
      next: (response) => this.field.value = response.body.reportImage,
      error: (e) => this.errorService.handleError(e.error)
    });
  }

  deleteFile( file: any) {
    this.fileUploadService.delete(file.path, file._id, file.filename).subscribe({
      error: (e) => this.errorService.handleError(e),
      complete: () => this.changeImageEventHandle()
    })

  }

  openCamera() {
    this.scanner = true;
  }

  scanCompleteHandler(event) {
    if((event !== undefined) && (event.text)) {
      this.field.options.some( option => {
        if(option.lora === event.text) {
          this.form.controls[this.field.key].setValue(option.key);
          // this.form.controls[this.field.key].markAsUntouched();
          this.snackBar.open('Device found', "OK");
          this.scanner = false;
          this.mySelect.close();
          return 1
        }
      });

      if(this.scanner === true) {
        this.snackBar.open('Detected device not in project or not available', "OK");
        this.scanner = false;
        return 0
      }
    }
  }

  selectChanges(event) {
    return 0;
  }
}
