<h4 mat-dialog-title>{{"dialog.confirm.title" | translate}}</h4>
<mat-dialog-content>
  <div class="margin">{{confirmMessage}}</div>
  <div *ngIf="!isSecure">
    <div class="d-flex w-100 justify-content-center align-items-center">
      <button mat-raised-button color="primary" class="mx-3" (click)="dialogRef.close(true)">{{"button.delete" | translate}}</button>
      <button mat-raised-button color="accent" class="mx-3 right_header" (click)="dialogRef.close(false)">{{"button.cancel" | translate}}</button>
    </div>
  </div>

  <div *ngIf="isSecure">
    <form>
      <mat-form-field appearance="fill">
        <mat-label>{{"dialog.confirm.secure" | translate}}</mat-label>
        <input matInput #message placeholder="ELIMINAR">
      </mat-form-field>
    </form>
    <div class="row text-center">
      <button [disabled]="message.value !== 'ELIMINAR'" mat-raised-button color="primary" (click)="dialogRef.close(true)">{{"button.delete" | translate}}</button>
      <button mat-raised-button color="accent" class="right_header" (click)="dialogRef.close(false)">{{"button.cancel" | translate}}</button>
    </div>
  </div>
</mat-dialog-content>