import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { NavigationComponent } from './navigation/navigation.component';
import { SidenavComponent } from './navigation/sidenav/sidenav.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'libs/angular/ui/src/lib/material/material.module';
import { ProjectsComponent } from './projects/projects.component';
import { StagesComponent } from './stages/stages.component';
import { UsersComponent } from './users/users.component';
import { BillingAccountsComponent } from './billing-accounts/billing-accounts.component';
import { ConcretesComponent } from './concretes/concretes.component';
import { MeasurementsComponent } from './measurements/measurements.component';
import { CompaniesComponent } from './companies/companies.component';
import { UserDialogComponent } from './dialogs/user-dialog/user-dialog.component';
import { DeviceDialogComponent } from './dialogs/device-dialog/device-dialog.component';
import { StageDialogComponent } from './dialogs/stage-dialog/stage-dialog.component';
import { ProjectDialogComponent } from './dialogs/project-dialog/project-dialog.component';
import { GatewayDialogComponent } from './dialogs/gateway-dialog/gateway-dialog.component';
import { BillingaccountDialogComponent } from './dialogs/billingaccount-dialog/billingaccount-dialog.component';
import { CompanyDialogComponent } from './dialogs/company-dialog/company-dialog.component';
import { ConcreteDialogComponent } from './dialogs/concrete-dialog/concrete-dialog.component';
import { MeasurementDialogComponent } from './dialogs/measurement-dialog/measurement-dialog.component';
import { GatewaysComponent } from './gateways/gateways.component';
import { LandingComponent } from './landing/landing.component';
import { DevicesComponent } from './devices/devices.component';
import { CompaniesService, UsersService } from '@cosmos/angular/api';
import { TranslateModule } from '@ngx-translate/core';
import { AngularUiModule } from '@cosmos/angular/ui';
import { FileUploadComponent } from 'libs/angular/ui/src/lib/file-upload/file-upload.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { SimulationsComponent } from './simulations/simulations.component';



@NgModule({
  declarations: [
    DashboardComponent,
    NavigationComponent,
    SidenavComponent,
    ProjectsComponent,
    StagesComponent,
    UsersComponent,
    BillingAccountsComponent,
    ConcretesComponent,
    MeasurementsComponent,
    CompaniesComponent,
    GatewaysComponent,
    LandingComponent,
    DevicesComponent,
    UserDialogComponent,
    DeviceDialogComponent,
    StageDialogComponent,
    ProjectDialogComponent,
    GatewayDialogComponent,
    BillingaccountDialogComponent,
    CompanyDialogComponent,
    ConcreteDialogComponent,
    MeasurementDialogComponent,
    SimulationsComponent,
  ],
  imports: [
    TranslateModule,
    DashboardRoutingModule,
    CommonModule,
    MaterialModule,
    MatPaginatorModule,
    AngularUiModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [
    UsersService,
    CompaniesService,
    FileUploadComponent,
  ]
})
export class DashboardModule { }
