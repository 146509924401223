import { FormFieldBase, FormFieldDropdown, FormFieldTextbox } from "@cosmos/angular/ui";
import { of } from "rxjs";

export class FormFieldsClassProject {
  formFields: FormFieldBase<string>[];

  constructor( data:any) {
    // COMMON FIELDS
    this.formFields = [
        new FormFieldTextbox({
            key: 'name',
            label: 'fields.name',
            value: data.create?'':data.project.name,
            type:'text',
            required: true,
            order: 1
        }),
        new FormFieldTextbox({
            key: 'description',
            label: 'fields.description',
            value: data.create?'':data.project.description,
            type:'text',
            required: true,
            order: 2
        })
    ]

    // EDIT ONLY FIELDS
    if(!data.create) {
      this.formFields.push();
    }
  }

  getCreateFields()
  {
    return of(this.formFields.sort((a, b) => a.order - b.order));
  }
}
