import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { User, Notification } from '@cosmos/shared';

@Injectable({
  providedIn: 'root'
})
export class StateService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser$: Observable<User>;

  private currentNotificationsSubject : BehaviorSubject<Notification[]>;
  public currentNotifications$: Observable<Notification[]>;

  constructor() {
    this.currentUserSubject = new BehaviorSubject<User>(null);
    this.currentUser$ = this.currentUserSubject.asObservable();

    this.currentNotificationsSubject = new BehaviorSubject<Notification[]>([]);
    this.currentNotifications$ = this.currentNotificationsSubject.asObservable();
  }

  setCurrentUser(user: User): void {
    this.currentUserSubject.next(user);
  }

  getCurrentUser(): User {
    return this.currentUserSubject.getValue();
  }

  setCurrentNotifications(notifications : Notification[]): void {
    this.currentNotificationsSubject.next(notifications);
  }

  getCurrentNotifications(): Notification[] {
    return this.currentNotificationsSubject.getValue();
  }
}
