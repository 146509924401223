import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Data, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Breadcrumb } from './breadcrumb.model';

@Injectable({
    providedIn: 'root'
})

//FROM : https://marco.dev/angular-breadcrumb

export class BreadcumbsService {

    //Emits breadcrumb hierarchy
    private readonly _breadcrumbs$ = new BehaviorSubject<Breadcrumb[]>([]);

    //Observable exposing it
    readonly breadcrumbs$ = this._breadcrumbs$.asObservable();

    constructor(private router: Router){
        this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd)
        ).subscribe(event => {
            //constructs the breadcrumb hierarchy
            const root = this.router.routerState.snapshot.root;
            const breadcrumbs: Breadcrumb[] = [];
            this.addBreadcrumb(root, [], breadcrumbs);

            //emit this hierarchy
            this._breadcrumbs$.next(breadcrumbs);
        })
    }

    private addBreadcrumb(route: ActivatedRouteSnapshot, parentUrl: string[], breadcrumbs: Breadcrumb[]) {
        if(route) {

            //construct route URL
            const routeUrl = parentUrl.concat(route.url.map(url => url.path));
            if(route.data.breadcrumb) {
                const breadcrumb = {
                    label: this.getLabel(route.data),
                    url: '/' + routeUrl.join('/')
                };
                breadcrumbs.push(breadcrumb);
            }

            //another element for next route
            this.addBreadcrumb(route.firstChild, routeUrl, breadcrumbs);
        }
    }

    private getLabel(data: Data) {
        return typeof data.breadcrumb === 'function' ? data.breadcrumb(data) : data.breadcrumb;
    }

}