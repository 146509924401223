import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorService } from '@cosmos/angular/utils';
import { Stage } from '@cosmos/shared';
import { catchError, map, Observable } from 'rxjs';
import { environment } from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StagesService {

  constructor(
    private http: HttpClient,
    private errorService: ErrorService,
  ) { }

  find(query=''): Observable<HttpResponse<Stage[]>> {
    return this.http
      .get<Stage[]>(`${environment.API_URL}/stages${query}`, {observe: 'response'})
        .pipe(
          map(response => {return response;}),
          catchError( (error) => this.errorService.handleError(error))
        );
  }

  findById(id: string, query = ''): Observable<HttpResponse<Stage>> {
    return this.http
      .get<Stage>(`${environment.API_URL}/stages/${id}${query}`, {observe: 'response'})
      .pipe(
        map(response => {return response;}),
        catchError( (error) => this.errorService.handleError(error))
      );
  }

  create(body: Stage) {
    return this.http
      .post(`${environment.API_URL}/stages`, body, {observe: 'response'})
      .pipe(
        map(response => {return response;}),
        catchError( (error) => this.errorService.handleError(error))
      );
  }

  update(body: any) {
    return this.http
      .patch(`${environment.API_URL}/stages/${body[0]}`, body[1], {observe: 'response'})
      .pipe(
        map(response => {return response;}),
        catchError( (error) => this.errorService.handleError(error))
      );
  }

  delete(id: string) {
    return this.http
      .delete(`${environment.API_URL}/stages/${id}`, {observe: 'response'})
      .pipe(
        map(response => {return response;}),
        catchError( (error) => this.errorService.handleError(error))
      );
  }

}
