import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GatewaysService, ProjectsService, StagesService, UsersService, WeatherService } from '@cosmos/angular/api';
import { ErrorService, JwtService, StateService } from '@cosmos/angular/utils';
import { Gateway, Project, Stage, User } from '@cosmos/shared';
import { DialogComponent } from '../../dialog/dialog.component';
import * as L from 'leaflet';
import { FileUploadService, GeometryLoadedEventArgs } from '@cosmos/angular/ui';
import { DomSanitizer } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FileE } from '@cosmos/shared';
import { TranslateService } from '@ngx-translate/core';

// FORGE
import { ViewerOptions, ViewerInitializedEvent, SelectionChangedEventArgs} from '@cosmos/angular/ui';
import { ForgeService } from '@cosmos/angular/api';
import datay29 from './test-martiricos/y-p29.json';
import datay28 from './test-martiricos/y-p28.json';
import datay27 from './test-martiricos/y-p27.json';

import datat30 from './test-martiricos/t-p30.json';
import datat29 from './test-martiricos/t-p29.json';
import datat28 from './test-martiricos/t-p28.json';



@Component({
  selector: 'cosmos-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit {

  user: User;
  markers = [];
  gateways: Gateway[] = [];
  project: Project = null;
  currentWeather: any = null;
  forecastWeather: any = null;
  image: any = null;
  blueprintType = '';
  zoom = 0.5;
  stages: Stage[] = [];
  map;
  popup = L.popup();
  files: FileE[];
  empty = false;
  token: string;

  viewer: Autodesk.Viewing.Viewer3D;

  public viewerOptions: ViewerOptions;

  constructor(
    private readonly gatewaysService: GatewaysService,
    private readonly stagesService: StagesService,
    private readonly projectsService: ProjectsService,
    private translateService: TranslateService,
    private stateService: StateService,
    private dialog: MatDialog,
    private fileUploadService: FileUploadService,
    private sanitizer : DomSanitizer,
    private userService: UsersService,
    private errorService: ErrorService,
    private snackBar: MatSnackBar,
    private router : Router,
    private weatherService: WeatherService,
    private jwtService: JwtService,
    private forgeService: ForgeService,
  ) { }


  ngOnInit(): void {
    this.token = this.jwtService.getToken();
    this.stateService.currentUser$.subscribe(x => {this.user = x});
    const url = (this.router.url).split("/");//Gets the current url, using the last part for the object
    this.projectsService.findById( url[url.length - 1]).subscribe({
      next: (response) => this.project = response.body,
      error: (e) => this.errorService.handleError(e),
      complete: () => {
        this.getData();
        this.loadMap()
      },
    });

    // FORGE
    // const ACCESS_TOKEN = 'eyJhbGciOiJSUzI1NiIsImtpZCI6IlU3c0dGRldUTzlBekNhSzBqZURRM2dQZXBURVdWN2VhIn0.eyJzY29wZSI6WyJkYXRhOnJlYWQiLCJkYXRhOndyaXRlIiwiZGF0YTpjcmVhdGUiLCJidWNrZXQ6cmVhZCIsImJ1Y2tldDpkZWxldGUiLCJidWNrZXQ6dXBkYXRlIiwiYnVja2V0OmNyZWF0ZSJdLCJjbGllbnRfaWQiOiJTVEVIMlF4R1M0RDA0SjROaFZ4SllreHJJSlVlS1JqNiIsImF1ZCI6Imh0dHBzOi8vYXV0b2Rlc2suY29tL2F1ZC9hand0ZXhwNjAiLCJqdGkiOiJhYnJ5WUF4Q3poeW82NXdFQUdsamJVeG50MGJveW5IUGZSTE9wSThocWtZZHRyR2U3UlpRTzRsUXFKS1BjU0JKIiwiZXhwIjoxNjY3NDgxOTc3fQ.HummFqyrWatZcrT4R8o326DPPCNmAa9ktmgnDyJ0p0_R705q4aSuH5o7eIa1JUIPIuKXzXEGUiovRCJk6ldss5krpOynxZl8ZqlpujkIb5RpAo_bCbRr9FC9W-aUp5tpP7xA6WtrwOKyPs0JScaSslsv2Bv16yp2DG5htlOD2UM6hBdBkMWNQNg5NNx3NI4YB9VPIBRcmLAhGgL4fDlQc4e7bab7-xQ7KdVkty6qlgqGrv2Cp95SsjNBMFs13pl2aM_rigUmtiWOVtZgq5Ja8MkRwLlWpKLLhkA4Nbcaf9lyqxQXxi83mrYlKGCaBbBI4EArxMY-2cA9HkyBflG8gQ';
    // TORRE Y
    // id: 63db7c46f4052e1240998a04
    // const DOCUMENT_URN =  'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6Y29zbW9zLWJ1Y2tldC1kZXYuY29zbW9zZW5naW5lZXJpbmcvUDA4MTkwLVBFLUUtTWFydGlyaWNvc19UMV9WMy5pZmM';

    // TORRE T
    // id: 63db7c46f4052e12409989f0
    // const DOCUMENT_URN =  'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6Y29zbW9zLWJ1Y2tldC1kZXYuY29zbW9zZW5naW5lZXJpbmcvUDA4MTkwLVBFLUUtTWFydGlyaWNvc19UMl9BVERPLmlmYw';


    this.viewerOptions = {
      initializerOptions: {
        env: "AutodeskProduction",
        getAccessToken: (
          onGetAccessToken: (token: string, expire: number) => void
        ) => {
          
          // const expireTimeSeconds = 60 * 30;
          // onGetAccessToken(ACCESS_TOKEN, expireTimeSeconds);

          // THIS GETS THE TOKEN FROM THE BACKEND
          this.forgeService.getAccessToken().subscribe({
            next: (response) => {
              onGetAccessToken(response.body.access_token, response.body.expires_in);
            },
            error: (e) => this.errorService.handleError(e),
        })
        },
        api: "derivativeV2",
        enableMemoryManagement: true
      },
      viewerConfig: {
        extensions: ["Autodesk.DataVisualization"],
        // extensions: ["Autodesk.DocumentBrowser", MyExtension.extensionName],
        theme: "dark-theme"
      },
      onViewerScriptsLoaded: () => {
        // Register a custom extension
        // Extension.registerExtension(MyExtension.extensionName, MyExtension);
      },
      onViewerInitialized: (args: ViewerInitializedEvent) => {
        args.viewerComponent.DocumentId = this.project.bimUrn;
        this.viewer = args.viewer;
        // this.initDataVisualization();
      },
      // showFirstViewable: false,
      // headlessViewer: true,
      // Set specific version number
      //version: "7.41"
    };
  }

  public selectionChanged(event: SelectionChangedEventArgs) {
    console.log(event.dbIdArray);
    console.log(this.viewer.getViewArrayFromCamera());
  }

  // public async initDataVisualization(event) {

  //   const dataVizExtn = await this.viewer.loadExtension("Autodesk.DataVisualization");

  //   // const DataVizExtn = new Autodesk.Extensions.DataVisualization(this.viewer);
  //   const DataVizCore = Autodesk.DataVisualization.Core;
  //   const viewableType = DataVizCore.ViewableType.SPRITE;
  //   const spriteColor = new THREE.Color(0xffffff);
  //   const baseURL = "https://shrikedoc.github.io/data-visualization-doc/_static/";
  //   const spriteIconUrl = `${baseURL}fan-00.svg`;


  //   const style = new DataVizCore.ViewableStyle(
  //     viewableType,
  //     spriteColor,
  //     spriteIconUrl
  //   );

  //   const viewableData = new DataVizCore.ViewableData();
  //   viewableData.spriteSize = 24;
  //   const myDataList = [
  //     { position: { x: -108.99654746273377, y: 10.59196338899477, z: -9.5 } },
  //     { position: { x: -3.035631512410646, y: 18.19961659918627, z: -9.5 } },
  //   ];

  //   myDataList.forEach((myData, index) => {
  //     const dbId = 3287 + index;
  //     const position = myData.position;
  //     const viewable = new DataVizCore.SpriteViewable(new THREE.Vector3(position.x, position.y, position.z), style, dbId);
  //     console.log(viewable);
  //     viewableData.addViewable(viewable);
  //   });

  //   await viewableData.finish();
  //   (dataVizExtn as Autodesk.Extensions.DataVisualization).addViewables(viewableData);
  //   // console.log(viewableData.viewables);
  // }

  public async initDataVisualization(event : GeometryLoadedEventArgs) {
    // this.viewer.setViewFromArray()
    const sensorStyleDefinitions = {
      temperature: {
          url: "assets/icons/cone.svg",
          color: 0xffffff,
      }
    };

    let devices = [];
    let sensorValues = [];
    let nodes = []
    let viewArray = [];

    if (this.project._id == '63db7c46f4052e1240998a04') { // TORRE Y
      devices = [...datay29, ...datay28, ...datay27];
      sensorValues = [20, 20, 20, 19, 19, 19, 19, 19, 15, 15, 15, 12, 12, 12, 8, 8, 8, 8, 8, 8];
      nodes = [6624, 6645, 6659, 6736, 6738, 6616, 6234, 6213, 6248, 6197, 6510, 6419, 6447];
      viewArray = [372676.667248822, 4065917.489550019, 144.83786169129777, 372635.5003087296, 4065951.128430875, 75.4549999558338, 0, 0, 1, 1.50125, 0.785398006439209, 1, 0];
    } else if (this.project._id == '63db7c46f4052e12409989f0') { // TORRE T
      devices = [...datat30, ...datat29, ...datat28];
      sensorValues = [20, 20, 20, 19, 19, 19, 19, 19, 15, 15, 15, 13, 13, 13, 13, 13, 13, 12, 12, 12, 8, 8, 8, 8, 8, 8];
      nodes = [8463, 8435, 8477, 8524, 8247, 8219, 8261, 8308, 7933, 8024, 8045, 8092];
      viewArray = [372583.46045501606, 4065957.375915278, 123.44279423306554, 372638.23909269524, 4065993.8663342656, 98.18473517822744, 0, 0, 1, 1.50125, 0.785398006439209, 1, 0];
    }

    this.viewer.setViewFromArray(viewArray);
    // TORRE Y
    // const devices = [...datay29, ...datay28, ...datay27];
    // TORRE T
    // const devices = [...datat30, ...datat29, ...datat28];
    // [
    //   { id: 'Medida 1', type: 'temperature', sensorTypes: ['temperature'], position: { x: -113.63384432873872, y: 29.42533140005858, z: -9.55 } },
    //   { id: 'Medida 2', type: 'temperature', sensorTypes: ['temperature'], position: { x: -107.67208101747886, y: -12.671885734990326, z: -9.5 } },
    //   { id: 'Medida 3', type: 'temperature', sensorTypes: ['temperature'], position: { x: -31.236251084968842, y: 42.52858040490938, z: -9.5 } },
    //   { id: 'Medida 4', type: 'temperature', sensorTypes: ['temperature'], position: { x: -0.011524552972673519, y: -2.2469413110210326, z: -9.5 } },
    //   { id: 'Medida 5', type: 'temperature', sensorTypes: ['temperature'], position: { x: 101.2086866637274, y: 35.26717911722551, z: -9.500000000000014 } },
    //   { id: 'Medida 6', type: 'temperature', sensorTypes: ['temperature'], position: { x: 85.53674796707804, y: -16.16958214460685, z: -9.5 } },
    // ];

    // const sensorValues = [22, 20, 16, 15.5, 10, 9];
    // TORRE Y
    // const sensorValues = [20, 20, 20, 19, 19, 19, 19, 19, 15, 15, 15, 12, 12, 12, 8, 8, 8, 8, 8, 8];
    // const nodes = [6624, 6645, 6659, 6736, 6738, 6616, 6234, 6213, 6248, 6197, 6510, 6419, 6447];
    // TORRE T
    // const sensorValues = [20, 20, 20, 19, 19, 19, 19, 19, 15, 15, 15, 13, 13, 13, 13, 13, 13, 12, 12, 12, 8, 8, 8, 8, 8, 8];
    // const nodes = [8463, 8435, 8477, 8524, 8247, 8219, 8261, 8308, 7933, 8024, 8045, 8092];


    const dataVizExtn = await this.viewer.loadExtension("Autodesk.DataVisualization");
    var styleMap = {};
    Object.entries(sensorStyleDefinitions).forEach(([type, styleDef]) => {
      styleMap[type] = new Autodesk.DataVisualization.Core.ViewableStyle(
          Autodesk.DataVisualization.Core.ViewableType.SPRITE,
          new THREE.Color(styleDef.color),
          styleDef.url
      );
    });

    const viewableData = new Autodesk.DataVisualization.Core.ViewableData();
    viewableData.spriteSize = 30;
    let startId = 1;

    devices.forEach((device) => {
      let style = styleMap[device.type] || styleMap["default"];
      const viewable = new Autodesk.DataVisualization.Core.SpriteViewable(
        new THREE.Vector3(device.position.x, device.position.y, device.position.z),
        style,
        startId
      );
      viewableData.addViewable(viewable);
      startId++;
    });

    await viewableData.finish();
    (dataVizExtn as Autodesk.Extensions.DataVisualization).showHideViewables(true, true);
    (dataVizExtn as Autodesk.Extensions.DataVisualization).addViewables(viewableData);
    // (dataVizExtn as Autodesk.Extensions.DataVisualization).changeOcclusion(true);

    // const dbIds = [3287];
    const {
      SurfaceShadingData,
      SurfaceShadingPoint,
      SurfaceShadingNode,
    } = Autodesk.DataVisualization.Core;

    // const shadingNode = new SurfaceShadingNode("Planta 1", 3287);
    // TORRE Y
    // const shadingNode = new SurfaceShadingNode("torre", [6624, 6645, 6659, 6736, 6738, 6616, 6234, 6213, 6248, 6197, 6510, 6419, 6447]);
    // TORRE T
    // const shadingNode = new SurfaceShadingNode("torre", [8463, 8435, 8477, 8524, 8247, 8219, 8261, 8308, 7933, 8024, 8045, 8092]);
    
    const shadingNode = new SurfaceShadingNode("torre", nodes);

    devices.forEach((device) => {
        const shadingPoint = new SurfaceShadingPoint(
            device.id,
            device.position,
            device.sensorTypes,
            device.number.toString()
        );
        shadingNode.addPoint(shadingPoint);
    });

    const heatmapData = new SurfaceShadingData();
    heatmapData.addChild(shadingNode);
    heatmapData.initialize(event.model);

    console.log(heatmapData);

    // await (dataVizExtn as Autodesk.Extensions.DataVisualization).setupSurfaceShading(event.model, heatmapData, {type: "PlanarHeatmap", slicingEnabled: true, placePosition: 'max'});
    await (dataVizExtn as Autodesk.Extensions.DataVisualization).setupSurfaceShading(event.model, heatmapData);

    (dataVizExtn as Autodesk.Extensions.DataVisualization).registerSurfaceShadingColors("Temperature", [0xffff00, 0x00ff00, 0xff0000]);
    function getSensorValue(device, sensorType) {
      console.log(sensorValues[parseInt(device.name)-1]);
      return ((sensorValues[parseInt(device.name)-1]/20));
    }

    (dataVizExtn as Autodesk.Extensions.DataVisualization).renderSurfaceShading("torre", "Temperature", getSensorValue, {heatmapConfig: {confidence: 1000} });
    console.log('data getter');
    (dataVizExtn as Autodesk.Extensions.DataVisualization).updateSurfaceShading(getSensorValue);
    // setInterval(() => {
    //   // Modify sensor values.
    //   for (let i = 0; i < devices.length; i++) {
    //       sensorValues[i] += Math.random();
    //   }
    //     (dataVizExtn as Autodesk.Extensions.DataVisualization).updateSurfaceShading(getSensorValue);
    // }, 300);
  }
  // END FORGE

  loadMap(): void {
    this.gatewaysService.findChirped('/?project=' + this.project._id).subscribe({
      next: (response) => {this.gateways = response.body;},
      error: (e) => this.errorService.handleError(e),
      complete: () => {this.initMap(); this.getWeather();},
    })
  }

  onMapReady() {

    if(!this.empty){
      setTimeout(() => {//for some reason, unless i give it a timeout, even if its 0, it does not work
        this.map.invalidateSize();
        this.map.fitBounds(this.markers);

        // this.map.flyToBounds(this.markers,{padding: L.point(0.5,0.5)});
      }, 0);
    }
 }


  openDialog(create: boolean): any{
    let dialogRef;
    if(create){
    const type = "stage"
    dialogRef = this.dialog.open(DialogComponent, {
      data: {create, type }
    });
    }
    else{
      const type = "project"
      const project = this.project;
      dialogRef = this.dialog.open(DialogComponent, {
      data: {create, type, project }
    });
    }

    dialogRef.afterClosed().subscribe(async result => {
      if (result !== undefined){
        if(create){
          result = {name: result.name, description: result.description, owner: this.user._id, company: this.project.company, project: this.project._id}
          this.stagesService.create(result).subscribe({
            error: (e) => this.errorService.handleError(e),
            complete: () => this.getData(),
          });
        }
        else{
          result = {name: result.name, description: result.description }
          this.projectsService.update([this.project._id, result]).subscribe({
            next: (response) => this.project = response.body,
            error: (e) => this.errorService.handleError(e),
            complete: () => this.getData(),
        })
        }
      }
    })
  }

  private initMap(): void {
    if(this.gateways.length > 0) {
      this.map = L.map('map');
      this.gateways.forEach(gateway => {
        let coordinates = [];
        if(gateway.chirpstackMetadata.gateway) {
          coordinates = [
            gateway.chirpstackMetadata?.gateway.location.latitude,
            gateway.chirpstackMetadata?.gateway.location.longitude
          ];
        } else {
          coordinates = [0,0];
        }
        const marker = L.marker(coordinates, {
          icon: L.icon({
            iconSize: [ 25, 41 ],
            iconAnchor: [ 13, 41 ],
            iconUrl: 'assets/leaflet/marker-icon.png',
            shadowUrl: 'assets/leaflet/marker-shadow.png'
          })
        })
        marker.addTo(this.map)
        this.markers.push(coordinates);
      });

      this.map.fitBounds(this.markers);

      // normal map
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 19,
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
      }).addTo(this.map);

      // open weather rain => move to backend to protect api key???
      L.tileLayer(`http://localhost:3001/api/weather/rainradar/{x}/{y}/{z}.png?token=${this.token}`, {
        maxZoom: 19,
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenWeatherMap</a>'
      }).addTo(this.map);
    }
    else{
      this.empty = true;
    }
  }

  private getWeather() {
    if(this.gateways.length > 0 && this.gateways[0].chirpstackMetadata.location) {
      const coordinates = this.gateways[0].chirpstackMetadata.gateway.location;
      this.weatherService.getCurrentWeather(coordinates.latitude, coordinates.longitude).subscribe({
        next: (response) => {this.currentWeather = response.body;},
        error: (e) => this.errorService.handleError(e),
        complete: () => {},
      });
      this.weatherService.getForecastWeather(coordinates.latitude, coordinates.longitude).subscribe({
        next: (response) => {this.forecastWeather = response.body;},
        error: (e) => this.errorService.handleError(e),
        complete: () => {},
      });
    }
  }


  getData(): void{
    this.fileUploadService.find('?serviceId=' + this.project._id +'&select=path,filename,filetype').subscribe({
      next: (response) => this.files = response.body,
      error: (e) => this.errorService.handleError(e.error),
    })

    this.projectsService.findById(this.project._id).subscribe({
      next: (response) => this.project= response.body,
      error:(e) => this.errorService.handleError(e),
    })


    this.stagesService.find('/?project=' + this.project._id)
    .subscribe({
      next: (event:any) => {
        this.stages = event.body;
      },
      error:(e) => this.errorService.handleError(e),
    });

    if(this.project.hasOwnProperty('plano')){
      var type = this.project.plano.split('.')

      if( type[type.length - 1] === "pdf") { //As the pdf is displayed differently from the images, they must be processed differently
        this.fileUploadService.getFile('Projects/' + this.project._id + '/Planos', this.project.plano, "pdf").subscribe({
          next:(event: any) => this.image =  event.body,
          error: (e) => this.errorService.handleError(e.error),
          complete: () => this.blueprintType = "pdf",
        });
      }
      else{
        this.fileUploadService.getFile('Projects/' + this.project._id + '/Planos', this.project.plano, "img").subscribe({
          next:(event: string) => this.image =  this.sanitizer.bypassSecurityTrustResourceUrl(event),
          error: (e) => this.errorService.handleError(e.error),
          complete: () => this.blueprintType = "image",
        });
      }

    }
  }

  shareProject() {
    let dialogRef;
    const type = "shareProject";
    this.projectsService.findById(this.project._id, "/?populate_shared_w=email&populate_shared_r=email").subscribe(
      {
        next: (res) => {
          const project = res.body;
          dialogRef = this.dialog.open(DialogComponent, {data: {project, type}})
        },
        complete: () => {
          dialogRef.afterClosed().subscribe({
            next: (dialogResult) => {
              if (dialogResult != undefined) {
                this.userService.find('/?email=' + dialogResult.shared_Email).subscribe({
                  next: (findResult) => {
                    if(findResult.body[0] != undefined) {
                      const sharedUser = findResult.body[0];


                      if(sharedUser._id === this.user._id) {
                        this.snackBar.open(this.translateService.instant("projects.shared.self"), "OK");
                        return;
                      }
                      if(dialogResult.write){
                        var exists = false;
                        //The two shared_ws are one form the project name within the object and the other is for the actual shared_w
                        dialogResult.shared_w.shared_w.forEach(element => {//Checks if user already registered IN WRITE
                          if (element._id == sharedUser._id) {
                            exists = true;
                            this.snackBar.open(this.translateService.instant("projects.shared.error")+ sharedUser.name + ".", "OK");
                            return;
                          }
                        });
                        dialogResult.shared_r.shared_r.forEach(element => {//Checks if user already registered IN READ
                          if (element._id == sharedUser._id) {
                            exists = true;
                            this.snackBar.open(this.translateService.instant("projects.shared.error")+ sharedUser.name + ".", "OK");
                            return;
                          }
                        });
                        if(exists) return;

                      }
                      if(!dialogResult.write){
                        var exists = false;
                       //The two shared_rs are one form the project name within the object and the other is for the actual shared_r
                        dialogResult.shared_r.shared_r.forEach(element => {

                          if (element._id == sharedUser._id) {//Checks if user already registered IN Read
                            exists = true;
                            this.snackBar.open(this.translateService.instant("projects.shared.error") + sharedUser.name + ".", "OK");
                            return;
                          }

                        });

                        dialogResult.shared_w.shared_w.forEach(element => {//Checks if user already registered IN WRITE
                          if (element._id == sharedUser._id) {
                            exists = true;
                            this.snackBar.open(this.translateService.instant("projects.shared.error")+ sharedUser.name + ".", "OK");
                            return;
                          }
                        });
                        if(exists) return;
                      }

                      let body;
                      if (dialogResult.write) {
                        body = {
                          "$addToSet": {
                            "shared_w": sharedUser._id
                          }
                        }
                      } else {
                        body = {
                          "$addToSet": {
                            "shared_r": sharedUser._id
                          }
                        }
                      }

                      this.projectsService.update([this.project._id, body]).subscribe({
                        next: (response) => this.project = response.body,
                        error: (e) => this.errorService.handleError(e),
                      });
                      this.snackBar.open( this.translateService.instant("projects.shared.success") + sharedUser.name, "OK");
                    }
                    else this.snackBar.open(this.translateService.instant("projects.shared.not_registered"), "OK");
                  },
                  error: (e) =>  {
                    if(e.status == 400) this.snackBar.open(this.translateService.instant("projects.shared.invalid_email"), "OK");
                    else this.errorService.handleError(e);
                  }
                });
              }
              this.getData();
            },
            error: (e) => {this.errorService.handleError(e)}
          });
        }
      }
    );
  }
  changeImage(imageId: string): void {
    this.projectsService.update([this.project._id,{plano: imageId}]).subscribe({
      complete: () => {this.project.plano = imageId; this.getData();}
    });
  }

  imageUploaded($event: any){
    this.getData()
  }

  editFiles() {
    const type = "fileManager";
    const project = this.project;
    const files = this.files;
    const services = "Project";
    const key = "plano";
    const filetype = "image/*,.pdf"
    const dialogRef = this.dialog.open(DialogComponent, {data: {project, type, files, services, key, filetype}});
    dialogRef.afterClosed().subscribe({
      complete: () => this.getData()
    });
  }
}
