import { FormFieldBase, FormFieldDropdown, FormFieldNumberChips, FormFieldTextbox } from "@cosmos/angular/ui";
import { of } from "rxjs";

export class FormFieldsClass {
  formFields: FormFieldBase<string>[];

  constructor(create: boolean, data:any, companies: any[], projects: any[]) {
    // COMMON FIELDS
    this.formFields = [
        new FormFieldTextbox({
            key: 'name',
            label: 'fields.name',
            value: create?'':data.name,
            type: 'text',
            required: true,
            order: 1
          }),
          new FormFieldDropdown({
            key: 'type',
            label: 'fields.type',
            value: create?"normal":data.type?data.type:"normal",
            options: [{key: "normal", value:"Normal"}],
            order: 2,
            required: false,
            multiple: false,
          }),

          new FormFieldDropdown({
            key: 'log',
            label: 'fields.log',
            value: create?"log_10":data.log,
            options: [{key: "log_10", value:"log 10"}, {key: "log_e", value:"log e"}],
            order: 2,
            required: false,
            multiple: false,
          }),

        new FormFieldTextbox({
            key: 'A_VAL',
            label: 'fields.A_VAL',
            value: create?'':data.A_VAL,
            type: 'number',
            required: true,
            order: 5
        }),
        new FormFieldTextbox({
            key: 'B_VAL',
            label:  'fields.B_VAL',
            value: create?'':data.B_VAL,
            type: 'number',
            required: true,
            order: 6
        }),
        new FormFieldTextbox({
            key: 'ABS_TEMP',
            label: 'fields.ABS_TEMP',
            value: create?-5:data.ABS_TEMP,
            type: "number",
            required: true,
            order: 7
        }),
          // DROPDOWN - USER - ACCOUNT MANAGER
          new FormFieldDropdown({
            key: 'company',
            label: 'fields.company',
            value: create?[]:data.company,
            options: companies,
            order: 8,
            required: false,
            multiple: true,
          }),

          new FormFieldDropdown({
            key: 'project',
            label: 'fields.project',
            value: create?[]:data.project,
            options: projects,
            order: 10,
            required: false,
            multiple: true,
          }),
          new FormFieldNumberChips({
            key: 'specifications',
            label: 'fields.specifications',
            value: create?[]:data.specifications,
            order: 11,
            required: false,
          }),
          new FormFieldNumberChips({
            key: 'specifications_maturity',
            label: 'fields.specifications_maturity',
            value: create?[]:data.specifications_maturity,
            order: 12,
            required: false,
          })
    ]

    // EDIT ONLY FIELDS
    if(!create) {
      this.formFields.push();
    }
  }

  getCreateFields()
  {
    return of(this.formFields.sort((a, b) => a.order - b.order));
  }
}
