import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardModule } from './dashboard/dashboard.module';
import { LoginComponent } from '../../../../libs/angular/ui/src/lib/login/login.component';


const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: '', loadChildren: () => DashboardModule },
  { path: '**', redirectTo: '/dashboard' }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes)],
  exports: [ RouterModule ]
})

export class AppRoutingModule { }


