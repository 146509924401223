import { FormFieldBase, FormFieldDropdown, FormFieldTextbox, FormFieldCheckBox } from "@cosmos/angular/ui";
import { of } from "rxjs";

export class FormFieldsClass {
  formFields: FormFieldBase<any>[];

  constructor() {
    // COMMON FIELDS
    this.formFields = [
        new FormFieldTextbox({
            key: 'concreteCycles',
            label: 'Fases',
            type:'number',
            value: '6',
            required: true,
            order: 3
        }),
        new FormFieldTextbox({
            key: 'startDate',
            label: 'fecha inicio',
            value: '2022-10-19',
            type:'date',
            required: true,
            order: 2
        }),
        new FormFieldDropdown({
          key: 'concreteMixture',
          label: 'Mezcla hormigon',
          value: '1',
          options: [{key: '1', value: 'HA30 33MPa'}, {key: '2', value: 'HA30 28MPa'}],
          order: 4,
          required: true,
          multiple: false,
        }),
        new FormFieldDropdown({
          key: 'region',
          label: 'region',
          value: '1',
          options: [{key: '1', value: 'Valencia'}],
          order: 1,
          required: true,
          multiple: false,
        }),
        new FormFieldTextbox({
          key: 'strengthReq',
          label: 'resistencia',
          type:'number',
          value: '24',
          required: true,
          order: 5
      }),
    ]
  }

  getCreateFields()
  {
    return of(this.formFields.sort((a, b) => a.order - b.order));
  }
}
