<mat-expansion-panel class="mt-4" *ngIf="isFilterable || toggleColumnsVisibility">
  <mat-expansion-panel-header>
    <mat-panel-title>
      <h6>{{"table.filter.title" | translate}}</h6>
    </mat-panel-title>
    <mat-panel-description>
      {{"table.filter.description" | translate}}
    </mat-panel-description>
  </mat-expansion-panel-header>

  <mat-divider></mat-divider>

  <div class="row mt-4">
    <div class="col-md-4" *ngIf="isFilterable">
      <h6>{{"table.filter.subtitle" | translate}}</h6>
      <mat-form-field>
        <input matInput #filter (keyup)="applyFilter($event.target.value)" placeholder="{{'table.filter.write' | translate}}">
      </mat-form-field>
    </div>
    <div class="col-md-8" *ngIf="toggleColumnsVisibility">
      <h6>{{"table.filter.parameters" | translate}}</h6>
      <mat-checkbox *ngFor="let column of tableColumns, let i = index" [name]="'checkbox-' + column.name" [checked]="tableColumns[i].visible" (change)="updateColumns(i, $event)">{{ tableColumns[i].label | translate}}</mat-checkbox>
    </div>
  </div>
  <div class="row mt-4" *ngIf="queryFields.length">
    <h6>{{"table.filter.fields" | translate}}</h6>
    <div class="col-md-4" *ngFor="let field of queryFields">
      <mat-form-field appearance="fill">
        <mat-label>{{field.label | translate}}</mat-label>
        <mat-select (selectionChange)="queryEvent($event, field.field)">
          <mat-option [value]=' '>-</mat-option>
          <mat-option *ngFor="let option of field.options" [value]="option._id">{{option.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</mat-expansion-panel>

<div class="table-container mt-4">
  <table mat-table [dataSource]="tableDataSource" matSort [matSortActive]="defaultSort" matSortDirection="asc" class="mat-elevation-z8" cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)">
    <!-- ROW ACTIONS -->
    <ng-container *ngFor="let action of rowActions" [matColumnDef]="action.name" [sticky]="action.sticky">
      <th class="row-actions" mat-header-cell *matHeaderCellDef>{{action.label | translate}}</th>
      <td class="row-actions" mat-cell *matCellDef="let element" [id]="'action-'+action.name" (click)="$event.stopPropagation();">
        <button mat-button [disabled]="canClear(element, action.name)" *ngIf="!isRead(element) || action.name !== 'markAsRead'" (click)="emitRowAction(action.name, element)">
          <mat-icon>{{action.icon}}</mat-icon>
        </button>
        <button mat-button disabled *ngIf="isRead(element) && action.name === 'markAsRead'">
          <mat-icon>{{action.iconAction}}</mat-icon>
        </button>
      </td>
    </ng-container>
    <!-- ROW DATA -->
    <ng-container *ngFor="let column of tableColumns, let i=index" [matColumnDef]="column.name">
      <th mat-header-cell 
            *matHeaderCellDef
            mat-sort-header
            cdkDrag
            [cdkDragDisabled]="!column.draggable"
            [disabled]="!column.sortable"
            [ngClass]="{'right-border': column.rightBorder}">{{column.label | translate}}</th>
      <td mat-cell
            *matCellDef="let element"
            [ngClass]="{'highlight': (column.type == 'highlight-negative' && isNegative(element, column)), 'right-border': column.rightBorder}"
            [matTooltip]="column.type == 'tooltip'?getTooltipValue(element, column.name):null"
            >
        @if(column.type=='icon') {
          <mat-icon [style.color]="getIconColor(element, column)">{{findColumnValue(element, column)}}</mat-icon>
        }
        @else {
          {{findColumnValue(element, column)}}

        }
        <!--{{element | dataPropertyGetter: column.name }} -->
      </td>
      <td mat-footer-cell
          *matFooterCellDef
          [ngClass]="{'highlight': (column.type == 'highlight-negative' && isNegative(element, column, true)), 'right-border': column.rightBorder}">
            {{column.footer  | translate}}
        </td>
    </ng-container>

    <!-- preHeaders -->
    
    <ng-container *ngFor="let preTH of preHeader, let i=index" [matColumnDef]="preTH.name">
      <div *ngIf="preHeader">
        <th mat-header-cell *matHeaderCellDef [attr.colspan]="preTH.colspan" [ngClass]="{'right-border': preTH.rightBorder}">{{preTH.label  | translate}}</th>
      </div>
    </ng-container>
    

    <div *ngIf="preHeader.length > 0">
      <tr mat-header-row *matHeaderRowDef="displayedPreHeaders" class="pre-header"></tr>
    </div>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

    <div>
      <!-- read class not properly implemented as it affects all , TODO come up with better method -->
      <tr mat-row [ngClass] = "{'linked' : isLinked, 'read' : row.read}"
      (click)="route(row) && isLinked"
      *matRowDef="let row; columns: displayedColumns;"
      ></tr>
    </div>

    <div *ngIf="tableFooter">
      <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
    </div>

  </table>

</div>
<mat-paginator *ngIf="isPageable"
[length]="paginatorCount!=undefined?paginatorCount:tableDataSource.data.length"
[showFirstLastButtons]="true"
[pageSize]="defaultPageSize"
[pageSizeOptions]="paginationSizes"
(page)="paginatorEvent($event)"
aria-label="Select page">
</mat-paginator>


