import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SimulationsService } from '@cosmos/angular/api';
import { ActionDefinition, ColumnDefinition, ConfirmationDialogComponent, FormFieldBase } from '@cosmos/angular/ui';
import { ErrorService, StateService } from '@cosmos/angular/utils';
import { Simulation, User } from '@cosmos/shared';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { FormFieldsClass } from './simulations.forms';

@Component({
  selector: 'cosmos-simulations',
  templateUrl: './simulations.component.html',
  styleUrls: ['./simulations.component.scss']
})
export class SimulationsComponent implements OnInit {

  fields: Observable<FormFieldBase<string>[]>;
  simulation_results: any;

  user: User;
  simulations: Simulation[];
  confirmDialog: MatDialogRef<ConfirmationDialogComponent>;


  actions: ActionDefinition[] = [
    // {name: 'edit', label: 'table.actions.edit', icon: 'edit', sticky: true, role: "superadmin"},
    {name: 'delete', label: 'table.actions.delete', icon: 'delete', sticky: true, role: "superadmin"},
  ];

  columns: ColumnDefinition[] = [
    {name: '_id', label: 'table.labels.id', visible: true, sortable: true, draggable: false},
    {name: 'name', label: 'table.labels.name', visible: true, sortable: true, draggable: false},
    {name: 'owner.name', label: 'table.labels.owner', visible: true, sortable: true, draggable: false},
    {name: 'owner.company_text', label: 'table.labels.company', visible: true, sortable: true, draggable: false},
    {name: 'createdAt', label: 'table.labels.created-at', visible: true, sortable: true, draggable: false, type: 'date-simple'},
  ];

  constructor(
    private simulationsService: SimulationsService,
    private errorService: ErrorService,
    private translateService: TranslateService,
    private stateService: StateService,
    public simulationsDialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.user = this.stateService.getCurrentUser();
    this.fields = new FormFieldsClass().getCreateFields();

    this.getData();
  }

  handleEvent($event){
    this.simulationsService.create($event).subscribe({
      next: (response) => {this.simulation_results = response.body;},
      error: (e) => this.errorService.handleError(e),
    });
  }

  rowAction(event: any): void {
    if (event.action === 'edit') {
      // this.openDialog(event.row._id, event.row);
    } else if (event.action === 'delete') {
      this.deleteElement(event.row);
    }
  }

  deleteElement(row: any): void {
    this.confirmDialog = this.simulationsDialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });

    this.confirmDialog.componentInstance.confirmMessage = this.translateService.instant("dialog.subtitle.delete.simulation") + ' ' + row.name;

    this.confirmDialog.afterClosed().subscribe(result => {
      if(result) {
        this.simulationsService.delete(row._id).subscribe({
          error: (e) => this.errorService.handleError(e),
          complete: () => this.getData()
        })
      }
    })
  }

  getData(): void {
    const query = "?populate_owner=name,company_text&select=name,owner,createdAt";
    this.simulationsService.find(query).subscribe({
      next: (response) => {this.simulations= response.body; console.log(this.simulations)},
      error: (e) => this.errorService.handleError(e),
    });


  }

}
