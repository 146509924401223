import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorService } from '@cosmos/angular/utils';
import { Simulation } from '@cosmos/shared';
import { catchError, map, Observable } from 'rxjs';
import { environment } from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SimulationsService {

  constructor(
    private http: HttpClient,
    private errorService: ErrorService,
  ) { }

  find(query=''): Observable<HttpResponse<Simulation[]>> {
    return this.http
      .get<Simulation[]>(`${environment.API_URL}/simulation${query}`, {observe: 'response'})
        .pipe(
          map(response => {return response;}),
          catchError( (error) => this.errorService.handleError(error))
        );
  }

  getCities(): Observable<HttpResponse<any>> {
    return this.http
      .get(`${environment.API_URL}/simulation/cities`, {observe: 'response'})
        .pipe(
          map(response => {return response;}),
          catchError( (error) => this.errorService.handleError(error))
        );
  }

  getConcretes(): Observable<HttpResponse<any>> {
    return this.http
      .get(`${environment.API_URL}/simulation/concretes`, {observe: 'response'})
        .pipe(
          map(response => {return response;}),
          catchError( (error) => this.errorService.handleError(error))
        );
  }

  findById(id: string, query = ''): Observable<HttpResponse<Simulation>> {
    return this.http
      .get<Simulation>(`${environment.API_URL}/simulation/${id}${query}`, {observe: 'response'})
      .pipe(
        map(response => {return response;}),
        catchError( (error) => this.errorService.handleError(error))
      );
  }

  create(body: Simulation) {
    return this.http
      .post(`${environment.API_URL}/simulation`, body, {observe: 'response'})
      .pipe(
        map(response => {return response;}),
        catchError( (error) => this.errorService.handleError(error))
      );
  }

  update(body: any) {
    return this.http
      .patch(`${environment.API_URL}/simulation/${body[0]}`, body[1], {observe: 'response'})
      .pipe(
        map(response => {return response;}),
        catchError( (error) => this.errorService.handleError(error))
      );
  }

  getQuote(body: any) {
    return this.http
      .patch(`${environment.API_URL}/simulation/quote/${body[0]}`, body[1], {observe: 'response'})
      .pipe(
        map(response => {return response;}),
        catchError( (error) => this.errorService.handleError(error))
      );
  }
  
  calculateImpact(body: any) {
    return this.http
      .patch(`${environment.API_URL}/simulation/impact/${body[0]}`, body[1], {observe: 'response'})
      .pipe(
        map(response => {return response;}),
        catchError( (error) => this.errorService.handleError(error))
      );

    }
  delete(id: string) {
    return this.http
      .delete(`${environment.API_URL}/simulation/${id}`, {observe: 'response'})
      .pipe(
        map(response => {return response;}),
        catchError( (error) => this.errorService.handleError(error))
      );
  }

}
