import { FormFieldBase, FormFieldDropdown, FormFieldNumberChips, FormFieldTextbox } from "@cosmos/angular/ui";
import { of } from "rxjs";

export class FormFieldsClassMeasurement {
  formFields: FormFieldBase<string>[];

  constructor(data:any, concretes: any, devices: any) {
    // COMMON FIELDS
    this.formFields = [
        new FormFieldTextbox({
            key: 'name',
            label: 'fields.name',
            value: data.create?'':data.measurement.name,
            type:'text',
            required: true,
            order: 1
        }),
        new FormFieldTextbox({
            key: 'description',
            label: 'fields.description',
            value: data.create?'':data.measurement.description,
            type:'text',
            required: false,
            order: 2
        }),

    ]

    if(!data.create) {
      this.formFields.push(
        new FormFieldNumberChips({
          key: 'specifications',
          label: 'fields.specifications',
          value: data.create?[0]:data.measurement.specifications,
          type: "number",
          required: false,
          order: 5,
          suffix: ' MPa',
        }),
      )
    }
    // CREATE ONLY FIELDS
    if(data.create) {
      this.formFields.push(
        new FormFieldDropdown({
            key: 'concrete',
            label: 'fields.concrete',
            value: data.create?'':data.measurement.concrete,
            options: concretes,
            required: true,
            order: 3
        }),
        new FormFieldDropdown({
          key: 'device',
          label: 'fields.device',
          value: data.create?'':data.measurement.device,
          options: devices,
          required: true,
          order: 2
        }),
        new FormFieldNumberChips({
          key: 'specifications',
          label: 'fields.specifications',
          value: data.create?[0]:data.measurement.specifications,
          type: "number",
          required: false,
          order: 5,
          suffix: ' MPa',
          reflect: {formField: 'concrete', foreignField: 'specifications', dataset: concretes}
        }),
      );
    }
  }

  getCreateFields()
  {
    return of(this.formFields.sort((a, b) => a.order - b.order));
  }
}
