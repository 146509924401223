import { Component, OnInit } from '@angular/core';
import { StorageService } from '@cosmos/angular/utils';

@Component({
  selector: 'cosmos-styles-dropdown',
  templateUrl: './styles-dropdown.component.html',
  styleUrls: ['./styles-dropdown.component.scss']
})
export class StylesDropdownComponent implements OnInit {

  body = document.getElementsByTagName("body")[0];

  constructor(private storageService: StorageService) {

   }

  ngOnInit(): void {

  }

  changeToDark() {
    this.storageService.setItem('theme', 'dark')
    this.body.classList.remove('light-theme');
  };
  changeToLight() {
    this.storageService.setItem('theme', 'light')
    this.body.classList.add('light-theme');
  };

}
