import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { of, throwError } from 'rxjs';
import { JwtService } from './jwt.service';
import { StateService } from './state.service';


@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(
    private jwtService: JwtService,
    private stateService: StateService,
    private router: Router,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
  ) {

  }

  handleError(error: HttpErrorResponse) {
    console.log(error);
    if(error.error instanceof Blob) {
      throw (error);
    }
    // if(error.error.message === "No notifications found") return;

    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
      return [];
    }
    else if(error.status === 406){
      this.snackBar.open(this.translateService.instant(error.error.message),"OK")
    
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(`Backend returned code ${error.status}, body was: `, error.error);
      return [];
      // return of(`Backend returned code ${error.status}, body was: `, error.error);
    }
    else if(error.status === 401) {
      this.jwtService.destroyToken();
      this.stateService.setCurrentUser(null);
      this.router.navigate(['/login']);
      console.log('Unauthenticated');
      return []
    }
    else {
      return [];
    }
  }

}
