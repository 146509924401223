<!-- CONTENT -->
<h1>{{"gateways.title" | translate}}</h1>
<p>{{"gateways.subtitle" | translate}}</p>

<div *ngIf="this.user.roles.indexOf('superadmin') > -1" class="container-fluid">
  <div class="row">
    <button mat-raised-button color="primary" (click)="openDialog()">{{"gateways.button" | translate}}</button>
  </div>
</div>

<mat-list>
  <mat-divider></mat-divider>
</mat-list>

<cosmos-data-table
  *ngIf="gateways"
  [tableData]="gateways"
  [tableColumns]="columns"
  [isFilterable]="true"
  [isPageable]="true"
  [toggleColumnsVisibility]="true"
  [rowActions]="actions"
  (rowAction)="rowAction($event)"></cosmos-data-table>
