import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ErrorService, StateService } from '@cosmos/angular/utils';
import { environment } from '../../../../../../environments/environment';
import { User } from '@cosmos/shared';
import { catchError, map, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(
    private http: HttpClient,
    private stateService: StateService,
    private errorService: ErrorService,
  ) { }

  find(query=''): Observable<HttpResponse<User[]>> {
    return this.http
      .get<User[]>(`${environment.API_URL}/users${query}`, {observe: 'response'})
        .pipe(
          map(response => {return response;}),
          catchError( (error) => this.errorService.handleError(error))
        );
  }

  findById(id: string): Observable<HttpResponse<User>> {
    return this.http
      .get<User>(`${environment.API_URL}/users/${id}`, {observe: 'response'})
      .pipe(
        map(response => {return response;}),
        catchError( (error) => this.errorService.handleError(error))
      );
  }

  create(body: User) {
    return this.http
      .post(`${environment.API_URL}/users`, body, {observe: 'response'})
      .pipe(
        map(response => {return response;}),
        catchError( (error) => this.errorService.handleError(error))
      );
  }

  update(body: any) {
    return this.http
      .patch(`${environment.API_URL}/users/${body[0]}`, body[1], {observe: 'response'})
      .pipe(
        map(response => {return response;}),
        catchError( (error) => this.errorService.handleError(error))
      );
  }

  delete(id: string) {
    return this.http
      .delete(`${environment.API_URL}/users/${id}`, {observe: 'response'})
      .pipe(
        map(response => {return response;}),
        catchError( (error) => this.errorService.handleError(error))
      );
  }

}
