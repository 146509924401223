import { Component, Input, OnInit } from '@angular/core';
import { StorageService } from '@cosmos/angular/utils';
import { Stage } from '@cosmos/shared';
import { ColumnDefinition } from '../../data-table/column-definition.model';

@Component({
  selector: 'cosmos-stage-card',
  templateUrl: './stage-card.component.html',
  styleUrls: ['./stage-card.component.scss']
})
export class StageCardComponent implements OnInit {

  @Input()
  stages: Stage[];
  currentGroup: string;

  columns: ColumnDefinition[] = [
    {name: 'name', label: 'table.labels.name', visible: true, sortable: true, draggable: true},
    {name: 'description', label: 'table.labels.description', visible: true, sortable: true, draggable: true},
  ];

  constructor(
    private storageService: StorageService,
  ) { }

  ngOnInit(): void {
    this.currentGroup = this.storageService.getItem("groupDisplay");
  }

  updateStorage(selectedGroup: string){
    this.storageService.setItem('groupDisplay',selectedGroup);
    this.currentGroup = selectedGroup;
  }

}
