<!-- <cosmos-dynamic-form [fields]="fields | async" (formValue)="handleEvent($event)"></cosmos-dynamic-form> -->


<!-- <div *ngIf="simulation_results">
  <h4>Resultados de la simulación</h4>
  <p>Inicio de la primera fase {{simulation_results.stages[0].start.date | date: 'fullDate'}}</p>
  <p>Fin de la última fase {{simulation_results.stages[simulation_results.stages.length-1].start.date | date: 'fullDate'}}</p>
  {{ simulation_results | json}} -->
  <!-- <mat-accordion>
    <mat-expansion-panel *ngFor="let stage of simulation_results.stages">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Fase {{stage.stage}}
        </mat-panel-title>
        <mat-panel-description>
          {{stage.strength | number: '1.1-2'}} MPa
        </mat-panel-description>
      </mat-expansion-panel-header>
      {{stage | json}}
      Inicio de la fase: {{stage?.start?.date}}
      Fin de la fase: {{stage?.end?.date}}
    </mat-expansion-panel>

  </mat-accordion>

</div> -->

<!-- CONTENT -->
<h1>{{"simulations.title" | translate}}</h1>
<p>{{"simulations.subtitle" | translate}}</p>
<div *ngIf="this.user.roles.indexOf('superadmin')>-1" class="container-fluid">
  <div class="row">
    <!-- <button mat-raised-button color="primary" (click)="openDialog()">{{"simulations.button.new" | translate}}</button> -->
  </div>
</div>

<mat-list>
  <mat-divider></mat-divider>
</mat-list>

<cosmos-data-table *ngIf="simulations"
  [tableData]="simulations"
  [tableColumns]="columns"
  [isFilterable]="true"
  [isLinked]="'simulations'"
  [toggleColumnsVisibility]="true"
  [rowActions]="actions"
  [isPageable]="true"
  (rowAction)="rowAction($event)">
</cosmos-data-table>

