<!-- CONTENT -->
<h1>{{"stages.admin.title" | translate}}</h1>
<p>{{"stages.admin.subtitle" | translate}}</p>

<div class="container-fluid">
  <div class="row">
    <button mat-raised-button color="primary" (click)="openDialog()">{{"stages.button.new" | translate}}</button>
  </div>
</div>

<mat-list>
  <mat-divider></mat-divider>
</mat-list>

<cosmos-data-table
  *ngIf="stages"
  [tableData]="stages"
  [tableColumns]="columns"
  [isFilterable]="true"
  [isPageable]="true"
  [toggleColumnsVisibility]="true"
  [rowActions]="actions"
  [isLinked]="'stage-only'"
  [queryFields]="queryFields"
  (rowAction)="rowAction($event)"
  (queryChange)="queryEvent($event)">
</cosmos-data-table>

