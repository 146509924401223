import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { StagesService, UsersService, ProjectsService, CompaniesService, MeasurementsService } from '@cosmos/angular/api';
import { ActionDefinition, ColumnDefinition, FileUploadService } from '@cosmos/angular/ui';
import { ErrorService, StateService } from '@cosmos/angular/utils';
import { Company, Project, Stage, User } from '@cosmos/shared';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationDialogComponent } from '@cosmos/angular/ui';
import { StageDialogComponent } from '../dialogs/stage-dialog/stage-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'cosmos-stages',
  templateUrl: './stages.component.html',
  styleUrls: ['./stages.component.scss']
})
export class StagesComponent implements OnInit {

  stages: Stage[] = [];
  users: User[] = [];
  companies: Company[] = [];
  projects: Project[] = [];
  user: User;
  confirmDialog: MatDialogRef<ConfirmationDialogComponent>;
  queryFields = [];
  currentQuery = {};

   actions: ActionDefinition[] = [
     {name: 'edit', label: 'table.actions.edit', icon: 'edit', sticky: true},
     {name: 'delete', label: 'table.actions.delete', icon: 'delete', sticky: true},
     {name: 'download', label: 'table.actions.download', icon: 'download', sticky: true},
   ];

   columns: ColumnDefinition[] = [
     {name: 'name', label: 'table.labels.name', visible: true, sortable: true, draggable: true},
     {name: 'description', label: 'table.labels.description', visible: true, sortable: true, draggable: true},
     {name: 'project.name', label: 'table.labels.project', visible: true, sortable: true, draggable: true}

   ];

  constructor(
    private stagesService: StagesService,
    private stageDialog: MatDialog,
    private companiesService: CompaniesService,
    private usersService: UsersService,
    private projectsService: ProjectsService,
    private measurementsService: MeasurementsService,
    private filesService: FileUploadService,
    private snackBar: MatSnackBar,
    private stateService: StateService,
    private translateService: TranslateService,
    private errorService: ErrorService,
  ) { }

  ngOnInit(): void {
    this.user = this.stateService.getCurrentUser();

    this.getData(this.currentQuery);

    var queryC = ''; //Query for companies service
    var queryP = ''; //Query for projects service

    if(this.user.roles.indexOf("superadmin") <= -1) { //If user is NOT a superadmin
      queryC = '/?account_manager=' + this.user._id;
      queryP = '/?owner=' + this.user._id; //+ "&shared_w=" + this.user._id
      this.users.push(this.user);
    }

    else {
      this.usersService.find().subscribe({
        next: (response) => {this.users = response.body;},
        error: (e) => this.errorService.handleError(e),
      });
    }
    this.companiesService.find(queryC).subscribe({
      next: (response) => {this.companies = response.body;},
      error: (e) => this.errorService.handleError(e),
    });

    this.projectsService.find(queryP).subscribe({
      next: (response) => {this.projects = response.body; this.queryFields.push({field: "project", label: "fields.project", options: response.body});},
      error: (e) => this.errorService.handleError(e),
    });
  }

  openDialog(id?: string, object?: any): any {
    const stage = object;
    let create = true;
    const companies = this.companies;
    const users = this.users;
    const projects = this.projects;

    if (stage)  {
      create = false;
    }

     const dialogRef = this.stageDialog.open(StageDialogComponent, {
       data: { stage, create, companies, users, projects }
     });

     dialogRef.afterClosed().subscribe(async result => {
       if (result !== undefined) {
        if (create) {
          this.stagesService.create(result).subscribe({
            error: (e) => this.errorService.handleError(e),
            complete: () => { this.getData(this.currentQuery);}
          });
        } else {
          this.stagesService.update(result).subscribe({
            error: (e) => this.errorService.handleError(e),
            complete: () => this.getData(this.currentQuery),
          });
        }
      }
     });
  }

  getData(currentQuery): any {
    // var query = '?populate_project=name';
    // if(this.user.roles.indexOf('superadmin') <= -1) {// if NOT superadmin
    //   query = '/?company=' + this.user.company;
    // }
    let query = ''
    Object.keys(currentQuery).forEach((key) => {
      if(currentQuery[key] != undefined) {
        query = `${query}${key}=${currentQuery[key]}&`
      }
    });

    query =`/?populate_project=name&${query}`;

    this.stagesService.find(query).subscribe({
      next: (response) => {this.stages = response.body},
      error: (e) => this.errorService.handleError(e),
    });
  }
  deleteElement(row: any): any {
    this.confirmDialog = this.stageDialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });

    this.confirmDialog.componentInstance.confirmMessage = this.translateService.instant("dialog.subtitle.delete.stage") + ' ' + row.name;

    this.confirmDialog.afterClosed().subscribe(result => {
      if(result) {
        this.stagesService.delete(row._id).subscribe({
          error: (e) => this.errorService.handleError(e),
          complete: () => this.getData(this.currentQuery)
        });
      }
    });
  }

  downloadCSV(row: any) {
    // this.measurementsService.downloadCSV('_id=' + row._id).subscribe({
    this.measurementsService.downloadCSV('stage=' + row._id).subscribe({
      next: (response) => { this.filesService.downloadCSVFromBlob(response); },
      error: (e) => {
        this.snackBar.open(this.translateService.instant("measurement.download.error"),"OK")
      }
    })
  }


  rowAction(event: any): any {
    if (event.action === 'edit') {
      this.openDialog(event.row._id, event.row);
    } else if (event.action === 'delete') {
      this.deleteElement(event.row);
    } else if (event.action === "download") {
      this.downloadCSV(event.row);
    }
  }

  queryEvent(event: any) {
    this.currentQuery[event.field] = event.$event.value;
    // if(event.field == "project" && event.$event.value != undefined) {
    //   this.currentQuery['stage'] = undefined;
    //   this.stagesService.find('?project='+ event.$event.value).subscribe({
    //     next: (response) => {
    //       this.queryFields.forEach((field, index) => {
    //         if(field.field == 'stage') {
    //           this.queryFields[index].options = response.body;
    //         }
    //       });
    //     },
    //     error: (e) => this.errorService.handleError(e),
    //   });
    // }
    this.getData(this.currentQuery);
  }

}
