import { Component, OnInit } from '@angular/core';
import { StorageService } from '@cosmos/angular/utils';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'cosmos-language-dropdown',
  templateUrl: './language-dropdown.component.html',
  styleUrls: ['./language-dropdown.component.scss']
})
export class LanguageDropdownComponent implements OnInit {

  constructor(
    private translate: TranslateService,
    private storage: StorageService,
  ) { }

  ngOnInit(): void {

  }

  changeLanguage(lang: string): void {
    this.translate.use(lang);
    this.storage.setItem('language', lang);

  }

}
