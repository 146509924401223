import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormFieldBase } from '@cosmos/angular/ui';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { FormFieldsClass } from '../../billing-accounts/billing-accounts.forms';

@Component({
  selector: 'cosmos-billingaccount-dialog',
  templateUrl: './billingaccount-dialog.component.html',
  styleUrls: ['./billingaccount-dialog.component.sass']
})
export class BillingaccountDialogComponent implements OnInit {

  title = '';
  billingaccountForm: UntypedFormGroup;
  fields: Observable<FormFieldBase<string>[]>;

  constructor(
    public dialogRef: MatDialogRef<BillingaccountDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translateService: TranslateService,
  ) { }

  ngOnInit(): void {

    const users: {key: string, value: string}[] = [];
    const companies: {key: string, value: string}[] = [];

    if(this.data.users) {
      for(const user of this.data.users) users.push({key: user['_id'], value: user['name'] });
    }
    if(this.data.companies) {
      for(const company of this.data.companies) companies.push({key: company['_id'], value: company['name'] });
    }

    if (this.data.create) {


      this.fields = new FormFieldsClass(true, this.data.billing_account, users, companies).getCreateFields();
      this.title = this.translateService.instant('dialog.title.create.billing');

      /*
      this.billingaccountForm = this.formBuilder.group({
        name: ['', [Validators.required]],
        owner: ['', [Validators.required]],
        company: ['', [Validators.required]],
      });*/

    } else {

      this.fields = new FormFieldsClass(false, this.data.billing_account, users, companies).getCreateFields();
      this.title = this.translateService.instant('dialog.title.edit.billing') + ' ' + this.data.billing_account._id;
      /*
      this.billingaccountForm = this.formBuilder.group({
        name: [this.data.billing_account.name, [Validators.required]],
        owner: [this.data.billing_account.owner, [Validators.required]],
        company: [this.data.billing_account.company, [Validators.required]],
      });*/
    }
  }

  handleEvent($event) {

    if(this.data.create){
      this.dialogRef.close($event);
    }
    else{
      this.dialogRef.close([this.data.billing_account._id, $event]);
    }
  }
}
