import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { StateService } from '../services/state.service';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard  {

  constructor(
    private router: Router,
    private stateService: StateService,
    private snackBar: MatSnackBar,
    private authService: AuthService,
  ) { }

  canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
      const role = route.data.role as string;
      const currentUser = this.stateService.getCurrentUser();
      if (currentUser.roles.indexOf(role)> -1) return true;
      else {
        this.snackBar.open("You do not have permission to enter","OK", {duration:5000})
        this.authService.purgeAuth();
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
      }
  }
  
}
