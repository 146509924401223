import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DynamicFormComponent } from './dynamic-form.component';
import { DynamicFormFieldComponent } from './dynamic-form-field/dynamic-form-field.component';
import { MaterialModule } from '../material/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { FileUploadComponent } from '../file-upload/file-upload.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';




@NgModule({
  declarations: [
    DynamicFormComponent,
    DynamicFormFieldComponent,
    FileUploadComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    TranslateModule,
    ZXingScannerModule
  ],
  exports: [
    FileUploadComponent,
    DynamicFormComponent,
    DynamicFormFieldComponent
  ]
})
export class DynamicFormModule { }
