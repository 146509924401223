<div class="flex">
<mat-toolbar color="primary" class="d-flex">
  <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="toggleNav()" class="d-flex align-items-center">
    <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
  </button>

  <h1 class="h5 my-0">{{ 'dashboard.title-admin' | translate}}</h1>

  <span class="nav-spacer"></span>

  <cosmos-user-dropdown></cosmos-user-dropdown>
  
</mat-toolbar>
<cosmos-sidenav [openNav]="isOpen"></cosmos-sidenav>
<!-- <app-sidenav ></app-sidenav> -->
</div>
