import { FormFieldBase, FormFieldDropdown, FormFieldTextbox } from "@cosmos/angular/ui";
import { of } from "rxjs";

export class FormFieldsClass {
  formFields: FormFieldBase<string>[];

  constructor(create: boolean, data:any, companies: any[], currentUser: any, translations: any) {
    // COMMON FIELDS
    this.formFields = [
      // NAME
      new FormFieldTextbox({
        key: 'name',
        label: 'fields.name',
        value: create?'':data.name,
        required: true,
        order: 1
      }),
      new FormFieldTextbox({
        key: 'surname',
        label: 'fields.surname',
        value: create?'':data.surname,
        order: 2,
        required: true,
      }),
      new FormFieldDropdown({
        key: 'company',
        label: 'fields.company',
        value: create?'':data.company?._id?data.company._id:null,
        options: companies,
        order: 3,
        required: true,
      }),
      new FormFieldDropdown({
        key: 'roles',
        label: 'fields.roles',
        value: create?['sim']:data.roles,
        options:
          currentUser.roles.indexOf('superadmin')>-1 ? [
            {key: 'sim', value: translations.sim},
            {key: 'user', value: translations.user},
            {key: 'admin', value: translations.admin},
            {key: 'superadmin', value: translations.superadmin}
          ]:[
            {key: 'sim', value: translations.sim},
            {key: 'user', value: translations.user},
            {key: 'admin', value: translations.admin}
          ],
        order: 5,
        required: false,
        multiple: true,
      })
    ]

    // EDIT ONLY FIELDS
    if(create) {
      this.formFields.push(

      new FormFieldTextbox({
        key: 'email',
        label: 'fields.email',
        value: create?'':data.email,
        order: 4,
        required: true,
      }),
      new FormFieldTextbox({
        key: 'password',
        label: 'fields.password',
        value: create?'':data.password,
        order: 5,
        required: true,
      }),

      );
    }
  }

  getCreateFields() {
    return of(this.formFields.sort((a, b) => a.order - b.order));
  }
}
