<button mat-icon-button [matMenuTriggerFor]="menuLanguage">
    <mat-icon>palette</mat-icon>
  </button>
  <mat-menu #menuLanguage="matMenu">


      <!-- <button mat-menu-item routerLink="/account">
        <mat-icon>account_circle</mat-icon>
        <span>Account</span>
      </button> -->
      <button (click)="changeToDark()" mat-menu-item>
        <span>{{'theme.dark' | translate}} <mat-icon>bedtime</mat-icon></span>
      </button>

      <button (click)="changeToLight()" mat-menu-item>
        <span>{{'theme.light' | translate}} <mat-icon>sunny</mat-icon></span>
      </button>


  </mat-menu>
