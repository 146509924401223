import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CompaniesService, ConcretesService, ProjectsService, UsersService} from '@cosmos/angular/api';
import { ActionDefinition, ColumnDefinition } from '@cosmos/angular/ui';
import { ErrorService, StateService } from '@cosmos/angular/utils';
import { Company, Concrete, Project, User } from '@cosmos/shared';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationDialogComponent } from '@cosmos/angular/ui';
import { ConcreteDialogComponent } from '../dialogs/concrete-dialog/concrete-dialog.component';

@Component({
  selector: 'cosmos-concretes',
  templateUrl: './concretes.component.html',
  styleUrls: ['./concretes.component.scss']
})
export class ConcretesComponent implements OnInit {

  concretes: Concrete[] = [];
  users: User[] = [];
  companies: Company[] = [];
  projects: Project[] = [];
  user: User;
  confirmDialog: MatDialogRef<ConfirmationDialogComponent>;

  actions: ActionDefinition[] = [
    {name: 'edit', label: 'table.actions.edit', icon: 'edit', sticky: true, role: "superadmin"},
    {name: 'delete', label: 'table.actions.delete', icon: 'delete', sticky: true, role: "superadmin"},
  ];;

  columns: ColumnDefinition[] = [
    {name: 'name', label: 'table.labels.name', visible: true, sortable: true, draggable: true},
    {name: 'company.name', label: 'table.labels.company', visible: true, sortable: true, draggable: true, type: 'stringArray'},
    {name: 'project.name', label: 'table.labels.project', visible: true, sortable: true, draggable: true, type: 'stringArray'},
    {name: 'A_VAL', label: 'table.labels.value_a', visible: true, sortable: true, draggable: true},
    {name: 'B_VAL', label: 'table.labels.value_b', visible: true, sortable: true, draggable: true},
  ];

  constructor(
    private concretesService: ConcretesService,
    public concreteDialog: MatDialog,
    private usersService: UsersService,
    private companiesService: CompaniesService,
    private projectsService: ProjectsService,
    private stateService: StateService,
    private translateService: TranslateService,
    private errorService: ErrorService,
  ) { }

  ngOnInit(): void {
    this.user = this.stateService.getCurrentUser();

    if(this.user.roles.indexOf("superadmin") > -1) {//If superadmin
      this.usersService.find().subscribe({
        next: (response) => {this.users = response.body;},
        error: (e) => this.errorService.handleError(e),
      });
    }
    this.getData();
  }

  openDialog(id?: string, object?: any): void {
    var concrete = object;
    // if(object !== undefined){
    //   var tempcomp = [];//UN-populated the array to only display string, so it can proparly show in dialog
    //   concrete.company.forEach(element => {
    //     tempcomp.push(element._id);
    //   });
    //   concrete.company = tempcomp;
    // }
    let create = true;
    const users = this.users;
    const companies = this.companies;
    const projects = this.projects;


    if (concrete)  {
      create = false;
      concrete.company = concrete.company.map( element => {return element._id});
      concrete.project = concrete.project.map( element => {return element._id});
    }

     const dialogRef = this.concreteDialog.open(ConcreteDialogComponent, {
       data: { concrete, create, users, companies, projects }
     });

     dialogRef.afterClosed().subscribe(async result => {
       if (result !== undefined) {
        if (create) {
          this.concretesService.create(result).subscribe({
            error: (e) => this.errorService.handleError(e),
            complete: () => { this.getData();}
          });
        } else {
          console.log(result)
          this.concretesService.update(result).subscribe({
            error: (e) => this.errorService.handleError(e),
            complete: () => { this.getData();}
          });
     }
     }
     });
  }

  getData(): void {
    if(this.user.roles.indexOf("superadmin") > -1) {
      this.concretesService.find('/?populate_company=name&populate_project=name').subscribe({
        next: (response) => {this.concretes = response.body;},
        error: (e) => this.errorService.handleError(e),
      });
    }
    else {
      this.concretesService.find('/?company=' + this.user.company + '&populate_company=name&populate_project=name').subscribe({
        next: (response) => {this.concretes = response.body;},
        error: (e) => this.errorService.handleError(e),
      });
    }
    this.companiesService.find().subscribe({
      next: (response) => {this.companies = response.body;},
      error: (e) => this.errorService.handleError(e),
    });
    this.projectsService.find().subscribe({
      next: (response) => {this.projects = response.body;},
      error: (e) => this.errorService.handleError(e),
    });
  }

  deleteElement(row: any): void {
    this.confirmDialog = this.concreteDialog.open(ConfirmationDialogComponent, {
      disableClose: false,
    });
    this.confirmDialog.componentInstance.confirmMessage = this.translateService.instant("dialog.subtitle.delete.concrete") + ' ' + row.name;

    this.confirmDialog.afterClosed().subscribe(result => {
      if(result) {
        this.concretesService.delete(row._id).subscribe({
          error: (e) => this.errorService.handleError(e),
          complete: () => this.getData()
        });
      }
    });
  }

  rowAction(event: any): void {
    if (event.action === 'edit') {
      this.openDialog(event.row._id, event.row);
    } else if (event.action === 'delete') {
      this.deleteElement(event.row);
    }
  }
}
