<mat-card  appearance="outlined"  class="my-2">
  <mat-card-header>
      <div mat-card-avatar class="text-center card-avatar">
          <mat-icon>event_note</mat-icon>
      </div>
      <mat-card-title class="mb-1">{{ "measurement.insight.title" | translate }}</mat-card-title>
      <mat-card-subtitle>{{ "measurement.insight.subtitle" | translate }}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
<!-- MATURITY: CURRENT -->
<div *ngIf="config.visualizationConfig?.maturityThreshold.app">
    <p *ngIf="currentMaturity != null">
      <span class="h2">
        <span>
          {{currentMaturity*24 | number : '1.2-2'}} {{ "measurement.insight.maturity" | translate }}
        </span>
        
      </span>
      <small>
        {{currentMaturity | number : '1.2-2'}} {{ "measurement.insight.maturity_days" | translate }} - 
        {{ "measurement.insight.curr_maturity" | translate }}</small>
    </p>

    <!-- STRENGTH SPECIFICATIONS -->
    <div *ngIf="specifications_maturity != null && specifications_maturity.length">
      <!-- STRENGTH: INSIGHTS -->
      <p class="h6">
          <span style="color:red;" *ngIf="currentMaturity && currentMaturity < specifications_maturity[specifications_maturity.length - 1]">{{ "measurement.insight.not_reached" | translate }}</span>
          <!-- ADD INTERMEDIATE STEP FOR PARTIALLY REACHED  if spec.length > 1 && s >spec[0] && s < spec[-1] ///// on previous, replace spec[-1] by spec[0]-->
          <span style="color:green;" *ngIf="currentMaturity && currentMaturity > specifications_maturity[specifications_maturity.length - 1]">{{ "measurement.insight.reached" | translate }}</span>
      </p>

      <!-- STRENGTH: PROGRESS BAR -->
      <p class="h6">
          {{ "measurement.insight.maturity_at" | translate }}
          <span *ngIf="currentMaturity">{{currentMaturity | number : '1.2-2'}}</span>
          {{ "measurement.insight.out_of" | translate }} {{specifications_maturity[specifications_maturity.length - 1] | number : '1.0-2'}} {{ "measurement.insight.maturity_days" | translate }}
      </p>

      <!-- ONLY ONE STRENGTH THRESHOLD -->
      <mat-progress-bar *ngIf="specifications_maturity.length == 1"
        class="mb-3"
        color="primary"
        mode="buffer"
        [value]="100 * currentMaturity / specifications_maturity[specifications_maturity.length-1]"
      ></mat-progress-bar>

      <!-- MORE THAN ONE STRENGTH THRESHOLDS -->
      <mat-progress-bar *ngIf="specifications_maturity.length > 1"
        class="mb-3"
        color="primary"
        mode="buffer"
        [value]="100 * currentMaturity / specifications_maturity[specifications_maturity.length-1]"
        [bufferValue]="getNextMaturityCheckpoint(currentMaturity)"
      ></mat-progress-bar>

      <div class="strength-checkpoints-container">
    <!-- LAST -> FLEX GROW 1 -->
    <!-- FIRST AND NOT LAST -> width = CHECKPOINT / CHECKPOINT[last] % -->
    <!-- NOT FIRST AND NOT LAST -> width = (CHECKPOINT - CHECKPOINT[prev]) / CHECKPOINT[last] % -->
        <ng-container *ngFor="let checkpoint of specifications_maturity; let i = index; let first = first; let last=last">
          <div  class="strength-checkpoint"
                [ngClass]="{'reached': currentMaturity > checkpoint}"
                [style.width.%]="100 * checkpoint/specifications_maturity.at(-1)"
                *ngIf="first && !last">
            {{checkpoint}} {{ "measurement.insight.maturity_days" | translate }}
          </div>
          <div  class="strength-checkpoint"
                [ngClass]="{'reached': currentMaturity > checkpoint}"
                [style.width.%]="100 * (checkpoint-specifications_maturity[i-1])/specifications_maturity.at(-1)"
                *ngIf="!first">
            {{checkpoint}} {{ "measurement.insight.maturity_days" | translate }}
          </div>
          <!-- <div  class="strength-checkpoint last-checkpoint"
                *ngIf="last">
            {{checkpoint}}MPa
          </div> -->
          <div  class="strength-checkpoint last-checkpoint"
                [ngClass]="{'reached': currentMaturity > checkpoint}"
                *ngIf="first && last">
            {{checkpoint}} {{ "measurement.insight.maturity_days" | translate }}
          </div>
        </ng-container>
      </div>

    </div>
  
<!-- DIVIDER -->
<mat-list>
    <mat-divider></mat-divider>
</mat-list>

</div>

<!-- /////////////////////////////////////////////////////////////////////////////////////// -->

<!-- STRENGTH: CURRENT -->
      <p>
        <span class="h2">
          <span *ngIf="currentStrength != null">{{currentStrength | number :'1.1-1'}}</span>
          {{ "measurement.insight.mpa" | translate }}
        </span>
        <small>{{ "measurement.insight.curr_strength" | translate }}</small>
      </p>

<!-- STRENGTH SPECIFICATIONS -->
      <div *ngIf="specifications != null && specifications.length">
        <!-- STRENGTH: INSIGHTS -->
        <p class="h6">
            <span style="color:red;" *ngIf="currentStrength && currentStrength < specifications[specifications.length - 1]">{{ "measurement.insight.not_reached" | translate }}</span>
            <!-- ADD INTERMEDIATE STEP FOR PARTIALLY REACHED  if spec.length > 1 && s >spec[0] && s < spec[-1] ///// on previous, replace spec[-1] by spec[0]-->
            <span style="color:green;" *ngIf="currentStrength && currentStrength > specifications[specifications.length - 1]">{{ "measurement.insight.reached" | translate }}</span>
        </p>

        <!-- STRENGTH: PROGRESS BAR -->
        <p class="h6">
            {{ "measurement.insight.strength_at" | translate }}
            <span *ngIf="currentStrength">{{currentStrength | number : '1.1-1'}}</span>
            {{ "measurement.insight.out_of" | translate }} {{specifications[specifications.length - 1] | number : '1.1-1'}} {{ "measurement.insight.mpa" | translate }}
        </p>

        <!-- ONLY ONE STRENGTH THRESHOLD -->
        <mat-progress-bar *ngIf="specifications.length == 1"
          class="mb-3"
          color="primary"
          mode="buffer"
          [value]="100 * currentStrength / specifications[specifications.length-1]"
        ></mat-progress-bar>

        <!-- MORE THAN ONE STRENGTH THRESHOLDS -->
        <mat-progress-bar *ngIf="specifications.length > 1"
          class="mb-3"
          color="primary"
          mode="buffer"
          [value]="100 * currentStrength / specifications[specifications.length-1]"
          [bufferValue]="getNextStrengthCheckpoint(currentStrength)"
        ></mat-progress-bar>

        <div class="strength-checkpoints-container">
<!-- LAST -> FLEX GROW 1 -->
<!-- FIRST AND NOT LAST -> width = CHECKPOINT / CHECKPOINT[last] % -->
<!-- NOT FIRST AND NOT LAST -> width = (CHECKPOINT - CHECKPOINT[prev]) / CHECKPOINT[last] % -->
          <ng-container *ngFor="let checkpoint of specifications; let i = index; let first = first; let last=last">
            <div  class="strength-checkpoint"
                  [ngClass]="{'reached': currentStrength > checkpoint}"
                  [style.width.%]="100 * checkpoint/specifications.at(-1)"
                  *ngIf="first && !last">
              {{checkpoint}}MPa
            </div>
            <div  class="strength-checkpoint"
                  [ngClass]="{'reached': currentStrength > checkpoint}"
                  [style.width.%]="100 * (checkpoint-specifications[i-1])/specifications.at(-1)"
                  *ngIf="!first">
              {{checkpoint}}MPa
            </div>
            <!-- <div  class="strength-checkpoint last-checkpoint"
                  *ngIf="last">
              {{checkpoint}}MPa
            </div> -->
            <div  class="strength-checkpoint last-checkpoint"
                  [ngClass]="{'reached': currentStrength > checkpoint}"
                  *ngIf="first && last">
              {{checkpoint}}MPa
            </div>
          </ng-container>
        </div>

      </div>
      <!-- DIVIDER -->
      <mat-list>
          <mat-divider></mat-divider>
      </mat-list>

<!-- TEMPORAL INFO ALWAYS SHOWN -->
      <p class="mt-4">
          {{ 'measurement.lastSeen' | translate }}:
          <span *ngIf="currentTime">{{ currentTime | date:'dd/MM/yyyy HH:mm:ss' }}</span>
          <span *ngIf="!currentTime">{{ 'measurement.insight.no_data' | translate }}</span>
      </p>
  </mat-card-content>
</mat-card>
