import { Component, Input, OnInit } from '@angular/core';

import 'chartjs-adapter-moment';
import { Interaction, InteractionModeFunction } from 'chart.js';
import { CrosshairPlugin } from '../plugins/chartjs-plugin-crosshair.esm';
import { interpolate } from '../plugins/interpolate';
import { TranslateService } from '@ngx-translate/core';

declare module 'chart.js' {
  interface InteractionModeMap {
    interpolate: InteractionModeFunction;
  }
}

Interaction.modes.interpolate = interpolate;

@Component({
  selector: 'cosmos-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit {

  @Input()
  measurement: any;

  @Input()
  threshold?: number | null = null;

  @Input()
  fields?: Array<string> = ['temperature', 'strength'];

  // PLUGIN - HORIZONTAL ARBITRARY LINE
  // horizontalThreshold = {
  //   id: 'horizontalThreshold',
  //   beforeDraw(chart, args, options) {
  //     const { ctx, chartArea: { top, right, bottom, left, width, height}, scales:
  //       {x, y} } = chart;
  //     ctx.save();
  //     const yScale = chart.scales[chart.getDatasetMeta(0).yAxisID];
  //     ctx.fillStyle = options.lineColor;
  //     ctx.fillRect(left, yScale.getPixelForValue(options.yValue), width, 2);
  //     ctx.restore();
  //   }
  // }

  //CHART
  // chartType = 'scatter';
  chartType = 'line';
  chartPlugins = [CrosshairPlugin];
  chartData = {
    datasets: [],
    labels: []
  };

  // CONFIG FOR THE TIME (ABSOLUTE) AND LINEAR (RELATIVE) AXIS
  // ADD TOGGLE TO SWITCH BETWEEN THE 2 OF THEM
  xTime =  {
    type: 'time',
    ticks: {
      //Enables Days in legend
      major: {
         enabled: true,
      },
    },
  };

  xLinear = {
    type: 'linear',
    ticks: {
      callback: function(value, index, ticks) {
        // console.log(value, index, ticks);
        return value + ' h.';
      },
    }
  }

  chartOptions = {
    scales: {
      // Define the X axis and its components.
      x: this.xTime,
    },
    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          footer: function(tooltipItems) {
            let relativeTime = ((new Date(tooltipItems[0].parsed.x).getTime() - new Date(tooltipItems[0].dataset.data[0].x).getTime())/1000/60/60).toFixed(2);
            return relativeTime + ' horas desde el inicio';
          }
        },
        mode: 'index',
        intersect: false
      },
      crosshair: {
        zoom: {
          enabled: true,
        }
      },
      legend: { display: true },
    }
  };

  constructor(
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {

    // LOADING CHART DATA - INTERPOLATE / DIFFERENT TIME AXIS
      const time = [];
      const timeRelative = []
      this.measurement.time.forEach(element => {
        time.push(new Date(element).getTime());
        
        // USE THIS VARIABLE TOGETHER WITH THE XLINEAR AXIS, TO SWITCH THE X IN THE DATASETS TO DISPLAY RELATIVE TIME
        timeRelative.push(((new Date(element).getTime() - new Date(this.measurement.time[0]).getTime())/1000/60/60).toFixed(2))
      });

      this.fields.forEach(field => {
        this.chartData.datasets.push(this.generateDataset(time, this.measurement[field], this.translateService.instant('chart.legend.' + field))); 
      })

      if (this.threshold) {
        // this.chartPlugins.push(this.horizontalThreshold);
        // this.chartOptions.plugins['horizontalThreshold'] = {
        //   lineColor: '#FFA03E',
        //   yValue: 5
        // }
        let threshold_suffix = ' (MPa)';

        if (this.fields.indexOf('maturity') >= 0) {
          threshold_suffix = ' (ºC d)';  
        }
        
        this.chartData.datasets.push({
          
            backgroundColor: 'rgb(255, 160, 62, 0.6)',
            borderColor: 'rgb(255, 160, 62)',
            showLine: true,
            fill: false,
            pointRadius: 2,
            label: this.translateService.instant('chart.legend.threshold') + threshold_suffix,
            data: [{ x: time[0], y: this.threshold }, { x: time[time.length - 1], y: this.threshold }],
            lineTension: 0,
            interpolate: true,
            cubicInterpolationMode: 'monotone'
        })
      }

      // this.chartData.datasets.push(this.generateDataset(timeRelative, this.measurement.temperature, this.translateService.instant('chart.legend.temperature')));
      // this.chartData.datasets.push(this.generateDataset(time, this.measurement.strength, this.translateService.instant('chart.legend.strength')));

  }


  // TO GENERATE SCATTER DATA (DIFFERENT TIME AXIS)

  generateDataset(dataX, dataY, label) {
    const data = [];

    dataX.forEach((element,index) => {
      data.push({ x: element, y: dataY[index] });
    });

    const dataset = {
      // backgroundColor: color,
      // borderColor: color,
      showLine: true,
      fill: false,
      pointRadius: 2,
      label: label,
      data: data,
      lineTension: 0,
      interpolate: true,
      cubicInterpolationMode: 'monotone'
    };
    return dataset;
  }

}
