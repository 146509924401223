import { FormFieldBase, FormFieldDropdown, FormFieldNumberChips, FormFieldTextbox } from "@cosmos/angular/ui";
import { of } from "rxjs";

export class FormFieldsClass {
  formFields: FormFieldBase<string>[];

  constructor(create: boolean, data:any, users:any[], projects: any[], devices: any[], concretes: any[], stages: any[], companies: any[]) {
    // COMMON FIELDS
    this.formFields = [
        new FormFieldTextbox({
            key: 'name',
            label: 'fields.name',
            value: create?'':data.name,
            type:'text',
            required: true,
            order: 1
        }),
        new FormFieldTextbox({
            key: 'description',
            label: 'fields.description',
            value: create?'':data.description,
            type:'text',
            required: false,
            order: 2
        }),
        new FormFieldDropdown({
            key: 'owner',
            label: 'fields.owner',
            value: create?'':data.owner,
            options: users,
            required: true,
            order: 5
        }),
        new FormFieldDropdown({
            key: 'project',
            label: 'fields.project',
            value: create?'':data.project._id,
            options: projects,
            required: true,
            order: 6
        }),
        new FormFieldDropdown({
          key: 'company',
          label: 'fields.company',
          value: create?'':data.company._id,
          options: companies,
          required: true,
          order: 6
        }),
        new FormFieldDropdown({
          key: 'stage',
          label: 'fields.stage',
          value: create?'':data.stage._id,
          options: stages,
          required: true,
          order: 7
      }),
      new FormFieldNumberChips({
        key: 'specifications',
        label: 'fields.specifications',
        value: create?[0]:data.specifications,
        type: "number",
        required: false,
        order: 9
    }),
    new FormFieldNumberChips({
      key: 'specifications_maturity',
      label: 'fields.specifications_maturity',
      value: create?[0]:data.specifications_maturity,
      type: "number",
      required: false,
      order: 9
  }),

    ]

    // CREATE ONLY FIELDS
    if(create) {
      this.formFields.push(
        new FormFieldDropdown({
          key: 'concrete',
          label: 'fields.concrete',
          value: create?'':data.concrete._id,
          options: concretes,
          required: true,
          order: 3
      }),
      new FormFieldDropdown({
          key: 'device',
          label: 'fields.device',
          value: create?'':data.device._id,
          options: devices,
          required: true,
          order: 4
      }),
      );
    }
  }

  getCreateFields()
  {
    return of(this.formFields.sort((a, b) => a.order - b.order));
  }
}
