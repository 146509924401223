import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { environment } from '../../../../../../environments/environment';
import { StateService } from './state.service';
import { JwtService } from './jwt.service';
import { Router } from '@angular/router';
import { catchError, map, mergeMap, of, throwError } from 'rxjs';
import { ErrorService } from './error.service';
import { User } from '@cosmos/shared';

@Injectable({ providedIn: 'root' })
export class AuthService {

  constructor(
    private http: HttpClient,
    private stateService: StateService,
    private jwtService: JwtService,
    private router: Router,
    private errorService: ErrorService,

  ) {}

  signOut(): void {
    this.purgeAuth();
    this.router.navigate(['/login']);
  }

  // Verify JWT in localstorage with server & load user's info.
  // This runs once on application startup.
  // populate() {
    // If JWT detected, attempt to get & store user's info

  // }

  setAuth(token: string): any {
    // Save JWT sent from server in localstorage
    this.jwtService.saveToken(token);
    // update the current user data using jwt token.
    return this.updateCurrentUserUsingToken()

  }

  purgeAuth(): void {
    // Remove JWT from localstorage
    this.jwtService.destroyToken();
    this.stateService.setCurrentUser(null);
  }

  updateCurrentUserUsingToken() {
    return this.http
      .get<User>(`${environment.API_URL}/users/current`, {observe: 'response'})
        .pipe(
          map(response => {
            if (response.body) {
              this.stateService.setCurrentUser(response.body);
              return true;
            } else {
              this.purgeAuth();
              return false;
            }
          }),
          catchError(error => {return this.errorService.handleError(error)})
        );
  }

  login(email: string, password: string) {

    return this.http
      .post<any>(`${environment.API_URL}/auth/email/login`, { email, password }, {observe: 'response'})
        .pipe(
          mergeMap( (response) => {
            if(response.body.data.jwt) {
              return this.setAuth(response.body.data.jwt);
            } else {
              this.purgeAuth();
              return false;
            }
          }),
          // HAS TO BE REMOVED SO THE ERROR IS HANDLED AT THE COMPONENT LEVEL, TO DISPLAY LOGIC
          // catchError( (error) => this.errorService.handleError(error))
        );
  }

  signUpSim(email: string, password: string, name: string, surname: string, company_text: string, phone: string, terms: boolean) {

    return this.http
      .post<any>(`${environment.API_URL}/auth/email/signup/simulator`, { email, password, name, surname, company_text, phone, terms}, {observe: 'response'})
        .pipe(
          map(response => {
            return response;
          }),
          catchError( (error) => this.errorService.handleError(error))
        );
  }
}

