import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormFieldBase } from '@cosmos/angular/ui';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { FormFieldsClassDevices } from '../forms/devices.forms';
import { FormFieldsClassMeasurement } from '../forms/measurement.forms';
import { FormFieldsClassProject } from '../forms/projects.forms';
import { FormFieldsClassProjectsShared } from '../forms/projectsShared.forms';
import { FormFieldsClassStage } from '../forms/stage.forms';
import { FormFieldsClassFileManager } from '../forms/fileManager.forms';

@Component({
  selector: 'cosmos-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {

  public save = true;
  concretes: any;
  devices: any;
  title = '';
  fields: Observable<FormFieldBase<string>[]>;

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translateService: TranslateService,
  ) { }

  ngOnInit(): void {
    switch(this.data.type){
      case "changeDevice":{
        this.title = this.translateService.instant('dialog.title.device');
        const devices: {key: string, value: string}[] = [];
        for(const device of this.data.devices) devices.push({key: device['_id'], value: device['name'] });

        this.fields = new FormFieldsClassDevices(this.data, devices).getCreateFields();
        break;
      }
      case "project": {
        if(this.data.create) this.title = this.translateService.instant('dialog.title.create.project');
        else this.title = this.translateService.instant('dialog.title.edit.project') + ' ' + this.data.project.name;
        this.fields = new FormFieldsClassProject(this.data).getCreateFields();
        break;
      }
      case "stage": {
        if(this.data.create) this.title = this.translateService.instant('dialog.title.create.stage');
        else this.title = this.translateService.instant('dialog.title.edit.stage') + ' ' + this.data.stage.name;
        this.fields = new FormFieldsClassStage(this.data).getCreateFields();
        break;
      }
      case "measurement": {
        if(this.data.create)this.title = this.translateService.instant('dialog.title.create.measurement');
        else this.title = this.translateService.instant('dialog.title.edit.measurements') + ' ' + this.data.measurement.name;
        const concretes: {key: string, value: string, specifications: number[]}[] = [];
        const devices: {key: string, value: string, lora: string}[] = [];

        if(this.data.concretes) {
          for(const concrete of this.data.concretes) concretes.push({key: concrete['_id'], value: concrete['name'], specifications: concrete['specifications'] });
          this.data.concretes = concretes;
        }

        if(this.data.devices) {
          for(const device of this.data.devices) devices.push({key: device['_id'], value: device['name'], lora: device['loramac'] });
          this.data.devices = devices;
        }

        this.fields = new FormFieldsClassMeasurement(this.data, concretes, devices).getCreateFields();
        break;
      }
      case "shareProject": {
        this.title = this.translateService.instant('dialog.title.share-project');
        this.fields = new FormFieldsClassProjectsShared(this.data.project).getCreateFields();
        break;
      }
      case "fileManager": {
        this.save = false;
        this.title = this.translateService.instant('dialog.title.files');
        this.fields = new FormFieldsClassFileManager(this.data).getCreateFields();
        break;
      }
    }
  }

  handleEvent($event){
    this.dialogRef.close($event);
  }

}
