import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';


import { AuthService } from '@cosmos/angular/utils';
import { StateService } from '@cosmos/angular/utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'cosmos-angular-ui-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: UntypedFormGroup;
  email: string;
  password: string;
  loading = false;
  error = false;
  signupAllowed = false;


  constructor(
    private translateService: TranslateService,
    private authService: AuthService,
    private stateService: StateService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private snackBar: MatSnackBar,
  ) {
    if (this.stateService.getCurrentUser()) {
      this.router.navigate(['/']);
    }
    this.route.data.subscribe(data => {
      this.signupAllowed=data['signupAllowed'];
  })
  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  // convenience getter for easy access to form fields
  get f(): any { return this.loginForm.controls; }

  async onSubmit(): Promise<any> {
    this.loading = true;

    this.authService.login(this.f.email.value, this.f.password.value).subscribe({
      next: (response) => {
        if(response) {
          const params = 'returnUrl';
          const returnUrl = this.route.snapshot.queryParams[params] || '/';
          this.loading = false;
          this.router.navigate([returnUrl]);
        } else {
          this.loading = false;
        }
      },
      error: (e) => {
        this.snackBar.open(this.translateService.instant('login.error'),"OK",{duration: 5000});
        this.loading = false;
        this.error = true;
      },
    });
    // const success = await this.authService.login(this.f.email.value, this.f.password.value);
    //const success = true;
    // if (success) {
    //   const params = 'returnUrl';
    //   const returnUrl = this.route.snapshot.queryParams[params] || '/';
    //   this.router.navigate([returnUrl]);
    // } else {
    //   this.loading = false;
    //   // this.changeDetectorRef.markForCheck();
    // }
  }

  // async updateCurrentUserUsingToken(): Promise<any> {
  //   return await this.authService.updateCurrentUserUsingToken();
  // }

}
