<button mat-icon-button [matMenuTriggerFor]="menu">
  <mat-icon>person</mat-icon>
</button>
<mat-menu #menu="matMenu">
    <!-- <button *ngIf="app !== 'admin'" mat-menu-item routerLink="/devices">
      <mat-icon>memory</mat-icon>
      <span >My devices</span>
    </button>
    <button *ngIf="app !== 'admin'" mat-menu-item routerLink="/reports">
      <mat-icon>insert_drive_file</mat-icon>
      <span >Reports</span>
    </button> -->

    <button mat-menu-item routerLink="/user">
      <mat-icon>account_circle</mat-icon>
      <span>{{'account.nav-link' | translate}}</span>
    </button>
    <!-- <button mat-menu-item *ngIf="appworker.showBtn" (click)="add_to_home($event)">
      <mat-icon>cloud_download</mat-icon>
      <span>Download app</span>
    </button> -->
    <button mat-menu-item (click)="logOut()">
      <mat-icon>exit_to_app</mat-icon>
      <span>{{'button.logout' | translate}}</span>
    </button>
</mat-menu>
