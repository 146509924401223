import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorService, StateService } from '@cosmos/angular/utils';
import { environment } from 'environments/environment';
import { catchError, map } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  constructor(
    //@InjectModel(File.name) private readonly fileModel: Model<File>,
    private http: HttpClient,
    private errorService: ErrorService,
    private stateService: StateService,
  ) { }

  uploadFile(uploadDestination: string, file: File[], id:string , services:string, filetype: string, company: string, context: string){
    const formData = new FormData();

    Array.from(file).forEach(element => {
      formData.append("file", element);
      formData.append("filename", element.name)
      const type = element.name.split('.')
      formData.append("filetype", type[type.length - 1])
    });


    formData.append("company", company)
    formData.append("owner", this.stateService.getCurrentUser()._id)
    formData.append("serviceId", id);
    formData.append("service", services);
    formData.append("context", context);
    formData.append("path", uploadDestination);
    return this.http
    .post(`${environment.API_URL}` + '/upload', formData, {reportProgress: true, observe: 'events'})
      .pipe(
        map(response => {return response}),
        catchError( (error) => this.errorService.handleError(error))
      );
  }

  getFile( uploadDestination: string, id: string, type: string) {
    if(type === "pdf") { //As the pdf is displayed differently from the images, they must be processed differently, with an array buffer instead of a blob
      return this.http.get(`${environment.API_URL}/upload/${uploadDestination}/${id}`, {responseType :'arraybuffer', observe: "response"})
      .pipe(
        map(response => {
          return response;
        }),
        catchError( (error) => this.errorService.handleError(error))
      )
    }
    else {
      return this.http.get(`${environment.API_URL}/upload/${uploadDestination}/${id}`, {responseType :'blob', observe: "response"})
      .pipe(
        map(response => {
          const objectUrl = URL.createObjectURL(response.body);
          return objectUrl;
        }),
        catchError( (error) => this.errorService.handleError(error))
      )
    }

  }
  find(query='') {
    return this.http.get(`${environment.API_URL}/upload/${query}`, {observe: 'response'})
    .pipe(
      map(response => {return response;}),
      catchError( (error) => this.errorService.handleError(error))
    );
  }

  delete (filePath: string, id: string, name: string) {
    return this.http.delete(`${environment.API_URL}/upload/${filePath}/${id}/${name}`, {observe: "response"})
      .pipe(
        map(response => {
          return response;
        }),
        catchError( (error) => this.errorService.handleError(error))
      )
  }

  downloadCSVFromBlob(response) {
    const url = window.URL.createObjectURL(response.body);
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // Browsers that support HTML5 download attribute
      link.setAttribute('href', url);
      link.setAttribute('download', response.headers.get('Content-Disposition').split('"')[1]);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      // browser does not support download!
    }
  }

  downloadFromBlob(response) {
    const url = window.URL.createObjectURL(response.body);
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // Browsers that support HTML5 download attribute
      link.setAttribute('href', url);
      link.setAttribute('target', 'blank');
      link.setAttribute('download', response.headers.get('Content-Disposition').split('=')[1]);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      // browser does not support download!
    }
  }
}


