import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, Sanitizer, ViewChild } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ActionDefinition, ColumnDefinition } from './column-definition.model';
import { DatePipe, DecimalPipe, JsonPipe } from '@angular/common';
import { ConversionService, StateService } from '@cosmos/angular/utils';
import { MatPaginator } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'cosmos-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.css'],
})
export class DataTableComponent implements OnInit, AfterViewInit {

  public tableDataSource = new MatTableDataSource([]);
  public displayedColumns: string[];
  public displayedPreHeaders: string[];
  @ViewChild(MatPaginator, {static: false}) matPaginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) matSort: MatSort;

  @Input() isPageable = false;
  @Input() defaultSort: string;
  @Input() isLinked: string = '';
  @Input() isFilterable = false;
  @Input() toggleColumnsVisibility = false;
  @Input() tableFooter = false;
  @Input() tableColumns: ColumnDefinition[];
  @Input() rowActions: ActionDefinition[] = [];
  @Input() preHeader: any[] = [];
  @Input() paginationSizes: number[] = [10, 25, 50];
  @Input() defaultPageSize = this.paginationSizes[1];
  @Input() paginatorCount: number;
  @Input() queryFields = [];

  @Output() sort: EventEmitter<Sort> = new EventEmitter();
  @Output() rowAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() pageChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() queryChange: EventEmitter<any> = new EventEmitter<any>();

  // this property needs to have a setter, to dynamically get changes from parent component
  @Input() set tableData(data: any[]) {
    this.setTableDataSource(data);
  }

  constructor(
    private router: Router,
    private conversionService: ConversionService,
    private datePipe: DatePipe,
    private decimalPipe: DecimalPipe,
    private jsonPipe: JsonPipe,
    private stateService: StateService,
    private translateService: TranslateService,
    private sanitizer: DomSanitizer,
  ) {
  }

  ngOnInit(): void {
    this.updateColumns();
    this.tableDataSource.filterPredicate = (data: any, filter) => {
      const dataStr =JSON.stringify(data).toLowerCase();
      return dataStr.indexOf(filter) != -1; 
    }
  }

  // we need this, in order to make pagination work with *ngIf
  ngAfterViewInit(): void {
    if(this.paginatorCount == undefined) {
      this.tableDataSource.paginator = this.matPaginator;
    } else {
      this.tableDataSource.paginator = undefined;
      this.matPaginator.length = this.paginatorCount;
    }

    this.tableDataSource.filterPredicate = (data: any, filter) => {
      const dataStr =JSON.stringify(data).toLowerCase();
      return dataStr.indexOf(filter) != -1; 
    }

    this.tableDataSource.sortingDataAccessor = (obj, property) =>{
      return property.split('.').reduce((o, p) => o && o[p], obj);
    }
    this.tableDataSource.sort = this.matSort;
  }

  setTableDataSource(data: any): void {
    this.tableDataSource = new MatTableDataSource<any>(data);
    
    if(this.paginatorCount == undefined) {
      this.tableDataSource.paginator = this.matPaginator;
    } else {
      this.tableDataSource.paginator = undefined;
      this.matPaginator.length = this.paginatorCount;
    }

    this.tableDataSource.sortingDataAccessor = (obj, property) =>{
      return property.split('.').reduce((o, p) => o && o[p], obj);
    }

    this.tableDataSource.filterPredicate = (data: any, filter) => {
      const dataStr =JSON.stringify(data).toLowerCase();
      return dataStr.indexOf(filter) != -1; 
    }
    
    this.tableDataSource.sort = this.matSort;
  }

  applyFilter(filterValue: string): void {
    this.tableDataSource.filter = filterValue.trim().toLowerCase();
  }


  emitRowAction(action: string, row: any): void {
    this.rowAction.emit({action, row});
  }

  paginatorEvent($event): void {
    this.pageChange.emit($event);
  }

  queryEvent($event, field): void {
    this.queryChange.emit({$event, field: field});
  }

  updateColumns(i?, $event?): any {
    if(i != null) this.tableColumns[i].visible = $event.checked;
    this.displayedColumns = this.tableColumns.map( el => {if (el.visible) { return el.name; }} ).filter(el => {return el !== undefined;});
    
    this.displayedPreHeaders = this.preHeader.map( (el, index) => {return el.name; } ).filter(el => {return el !== undefined;});
    
    if(this.rowActions)
    for (const action of this.rowActions) {
      if(this.stateService.getCurrentUser().roles.indexOf(action.role) > -1 || action.role == undefined)this.displayedColumns.unshift(action.name);//Only adds the action if the role is correct
    }
  }

  findColumnValue(element:unknown, column):string {
    switch(column.type){
      //Returns the last number of an array
      case "numArray": {
        //Checks if the array is not empty, and return the LAST number with 2 decimals
        const dp = this.decimalPipe;
        return <string>column.name.split('.').reduce(function(acc, cur) {
          if (acc[cur].length == 0){ return "-";}
          else return dp.transform(acc[cur][acc[cur].length - 1], "1.0-2");// 1.0-2: 1 means minimum numbers before decimal, 0 mean the min digits after decimal, and 2 mean max decimals after fraction
        } , element);
      }

      case "arrayLength": {
        //Checks if the array is not empty, and return the LAST number with 2 decimals
        const lastElement = column.name.split('.').reduce(function(acc, cur) {
            if (acc[cur]?.length == 0 || !acc[cur]){ return "-";}
            else {return acc[cur];}
          } , element);
        return lastElement=='-'?'-':lastElement.length // 1.0-2: 1 means minimum numbers before decimal, 0 mean the min digits after decimal, and 2 mean max decimals after fraction
      }

      case "stringArray":{
        //Takes all elements of the array, and puts them into an array to display it
        //We use "currentIndex" as reduce does two things, depending on the iteration
        return <string>column.name.split('.').reduce(function(prev, curr, currentIndex) {
          //The code below is executed on iterations after the initial one
          if(currentIndex != 0){
            let result = [];
            //For each element of the array obtained in the first iteration, we obtain the requested element, and push it to the array to return.
            prev.forEach((element) => {
              result.push(element[curr])
            });
            return result;
          }
          //The code below is executed on the first iteration, and it returns the array we want to show
          return prev[curr];
        } , element);
      }
      case "date": {
        //Formats the date to show correctly
        const dp = this.datePipe;
        return <string>column.name.split('.').reduce(function(acc, cur) {
          if(acc == null) return "-";
          return dp.transform(acc[cur],'dd-MM-YYYY HH:mm:ss');
        }, element);
      }
      case "dateArray": {
        //Formats the date to show correctly
        const dp = this.datePipe;
        return <string>column.name.split('.').reduce(function(acc, cur) {
          if (acc[cur].length == 0){ return "-";}
          else return dp.transform(acc[cur][acc[cur].length - 1], 'dd-MM-YYYY HH:mm:ss');
        }, element);
      }
      case "date-simple": {
        //Formats the date to show correctly
        const dp = this.datePipe;
        return <string>column.name.split('.').reduce(function(acc, cur) {
          if(acc == null) return "-";
          return dp.transform(acc[cur],'dd-MM-YYYY');
        }, element);
      }

      case 'transmission': {
        const cs = this.conversionService;
        return <string>column.name.split('.').reduce(function(acc, cur) {
          if (!Array.isArray(acc[cur]) || acc[cur]?.length == 0){ return "-";}
          else {
            const transmission = acc[cur][acc[cur].length - 1];
            return cs.getTransmissionInfo(transmission)[2] + ' - (RSSI: ' + transmission.rssi + ' - SNR: ' + transmission.snr + ')';
          }
        } , element);
      }
      case "boolean": {
        const ts = this.translateService
        return <string>column.name.split('.').reduce(function(acc, cur) {
          if(acc == null) return "?";
          else if(acc[cur]) return ts.instant('boolean.yes');
          else return ts.instant('boolean.no');
        }, element);
      }
      case "highlight-negative": {
        const dp = this.decimalPipe;
        return <string>column.name.split('.').reduce(function(acc, cur) {
          if(acc[cur] < 0) return dp.transform(acc[cur], '1.0-2');
          else return dp.transform(acc[cur], '1.0-2');
        }, element);
      }
      case 'tooltip': {
        return <string>column.name.split('.').reduce(function(acc, cur) {
          if(acc == null) return "-";
          return acc[cur]['value'];
        }, element);
      }
      case 'translate': {
        const ts = this.translateService
        return <string>column.name.split('.').reduce(function(acc, cur) {
          if(acc == null) return "-";
          return ts.instant(acc[cur]);
        }, element);
      }
      default: {
        return <string>column.name.split('.').reduce(function(acc, cur) {
          if(acc == null) return "-"
          return acc[cur];
        }, element);
      }
    }

  }

  drop(event: CdkDragDrop<string[]>): any {
    moveItemInArray(this.displayedColumns, event.previousIndex + this.rowActions.length, event.currentIndex + this.rowActions.length);
  }

  getTooltipValue(element, columnName) {
      return <string>columnName.split('.').reduce(function(acc, cur) {
        if(acc == null) return "-"
        return acc[cur]['tooltip'];
      }, element);
  }

  route(row: any) {
    switch(this.isLinked) {
      case "active_measurements" : {
        this.router.navigate(['/projects/' + row.project._id + '/' + row.stage._id + '/' + row._id]);
        break;
      }
      case "project" : {
        this.router.navigate(['/projects/' + row._id]);
        break;
      }
      case "device" : {
        this.router.navigate(['/devices/d/' + row._id]);
        break;
      }
      case "gateway" : {
        this.router.navigate(['/devices/g/' + row._id]);
        break;
      }
      case "stage" : {
        this.router.navigate(['/projects/' + row.project + '/' + row._id]);
        break;
      }
      case "stage-only" : {
        this.router.navigate(['/stages/' + row._id]);
        break;
      }
      case "measurement" : {
        this.router.navigate(['/projects/' + row.project + '/' + row.stage + '/' + row._id]);
        break;
      }
      case "measurement-only" : {
        this.router.navigate(['/measurements/' + row._id]);
        break;
      }
      case "simulations" : {
        this.router.navigate(['/simulations/' + row._id ]);
        break;
      }
    }
  }

  isRead(row: any): boolean{
    if(row.hasOwnProperty('read')) {
      if(row.read) return true;
      else return false;
    }
    return false;
  }

  isNegative(element, column, footer: boolean = false) {
    if(!footer) {
      if (parseFloat(this.findColumnValue(element, column)) < 0) return true;
      else return false;
    } else if (footer) {
      if (parseFloat(element) < 0) return true;
      else return false;
    }
  }

  getIconColor(element, column) {
      const value = this.findColumnValue(element, column);
      switch(value){
        case 'info': return 'LimeGreen' 
        case 'warning': return 'Gold'
        case 'error': return 'OrangeRed'
        default: {
          return '';
        }
      }
      
  }

  canClear(row: any, name: string): boolean{
    if(row.hasOwnProperty('strength') && name == "clear"){//Can use any of the measurements, as they are all updated at the same time.

      if(row.strength.length == 0) {
        return true;
      }
      if(this.stateService.getCurrentUser().roles.indexOf("superadmin") > -1){
        return false;
      }
      else if( row.strength.length > 6) {
        return true;
      }
    }
    return false;
  }

}
