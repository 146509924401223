<div class="mb-4">
  <mat-button-toggle-group *ngIf="measurements.length > 0" class="right_header" #group="matButtonToggleGroup" (change)="updateStorage(group.value)" value="{{currentGroup}}">
      <mat-button-toggle value="table" aria-label="Table">
          <mat-icon>list</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle value="cards" aria-label="Cards">
          <mat-icon>apps</mat-icon>
          </mat-button-toggle>
  </mat-button-toggle-group>
</div>

<div class="row mb-4" *ngIf="measurements.length > 0 && currentGroup === 'cards'">
    <div class="col-md-4" *ngFor="let measurement of measurements">
        <mat-card  appearance="outlined"  class="my-2">
            <mat-card-header>
                <div mat-card-avatar class="text-center card-avatar">{{measurement.name[0]}}</div>
                <mat-card-title class="mb-1">{{measurement.name}}</mat-card-title>
                <mat-card-subtitle>{{"cards.measurement.subtitle" | translate}}</mat-card-subtitle>
                <div style="margin-left: auto; font-size: 1.2rem;"><b>{{measurement.strength[measurement.strength.length-1] | number: '1.1'}} {{"cards.measurement.mpa" | translate}}</b></div>
            </mat-card-header>
            <mat-card-content>
              <mat-list>
                <mat-list-item>
                  <mat-icon matListItemIcon>update</mat-icon>
                  <div matListItemTitle>{{measurement.updatedAt | date:'dd/MM/yyyy HH:mm:ss'}}</div>
                  <div matListItemLine>{{"cards.measurement.updated" | translate}}</div>
                </mat-list-item>
              </mat-list>
                <p></p>
            </mat-card-content>
            <mat-card-actions>
                <button mat-button color="primary" routerLink="/projects/{{measurement?.project}}/{{measurement?.stage}}/{{measurement._id}}">{{"cards.measurement.view" | translate}}</button>
            </mat-card-actions>
        </mat-card>
    </div>
</div>

<cosmos-data-table
    *ngIf="measurements.length > 0 && currentGroup === 'table'"
    [isLinked]="'measurement'"
    [tableData]="measurements"
    [tableColumns]="columns"
    [isFilterable]="true"
    [toggleColumnsVisibility]="true"
    (rowAction)="rowAction($event)">
</cosmos-data-table>

<!-- NO STAGES INFO MESSAGE -->
<div class="row mb-4" *ngIf="measurements.length === 0">
    <div class="col-md">
        {{"measurement.not-found" | translate}}
    </div>
</div>
