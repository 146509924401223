import { FormFieldBase, FormFieldDropdown, FormFieldTextbox } from "@cosmos/angular/ui";
import { of } from "rxjs";

export class FormFieldsClass {
  formFields: FormFieldBase<string>[];

  constructor(create: boolean, data:any, companies: any[], projects: any[], superadmin: boolean) {
    // COMMON FIELDS
    this.formFields = [
        new FormFieldTextbox({
            key: 'name',
            label: 'fields.name',
            value: create?'':data.name,
            type:'text',
            required: true,
            order: 1
        }),
        new FormFieldDropdown({
            key: 'project',
            label: 'fields.project',
            value: create?null:(data.project == null?null:data.project._id),
            options: projects,
            required: false,
            order: 4,
        }),
    ]

    // CREATE ONLY FIELDS
    if(create && superadmin) {
      this.formFields.push(

        new FormFieldTextbox({
          key: 'loramac',
          label: 'fields.loramac',
          value: create?'':data.loramac,
          type:'text',
          required: true,
          order: 2
      }),
      );
    }
    //Superadmin only
    if(superadmin) {
      this.formFields.push(
        new FormFieldDropdown({
          key: 'company',
          label: 'fields.company',
          value: create?null:data.company._id,
          options: companies,
          required: true,
          order: 3,
        }),
      );
    }
  }

  getCreateFields()
  {
    return of(this.formFields.sort((a, b) => a.order - b.order));
  }
}
