import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../material/material.module';


import { LoginComponent } from './login.component';
import { AuthService, StateService } from '@cosmos/angular/utils';
import { TranslateModule } from '@ngx-translate/core';
import { SignupComponent } from './signup/signup.component';
import { ValidateComponent } from './signup/validate/validate.component';



@NgModule({
  declarations: [
    LoginComponent,
    SignupComponent,
    ValidateComponent
  ],
  providers: [
    AuthService,
    //StateService,
  ],
  imports: [
    TranslateModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule
  ],
  exports: [
    LoginComponent
  ]
})
export class LoginModule { }
