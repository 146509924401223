import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormFieldBase } from '@cosmos/angular/ui';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { FormFieldsClass } from '../../users/users.form';


@Component({
  selector: 'cosmos-user-dialog',
  templateUrl: './user-dialog.component.html',
  styleUrls: ['./user-dialog.component.scss']
})
export class UserDialogComponent implements OnInit {

  title = '';
  fields: Observable<FormFieldBase<string>[]>;

  constructor(
    public dialogRef: MatDialogRef<UserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translateService: TranslateService,
  ) { }

  ngOnInit(): void {

    const companies: {key: string, value: string}[] = []

    if(this.data.companies) {
      for(const company of this.data.companies) companies.push({key: company['_id'], value: company['name'] });
    }
    console.log(this.data)

    if (this.data.create) {
      this.fields = new FormFieldsClass(true, this.data.user, companies, this.data.currentUser, this.data.translations).getCreateFields();
      this.title = this.translateService.instant('dialog.title.create.user');

    } else {
      this.title = this.translateService.instant('dialog.title.edit.user') + ' ' + this.data.user._id;
      this.fields = this.fields = new FormFieldsClass(false, this.data.user, companies, this.data.currentUser, this.data.translations).getCreateFields();
    }

  }

  handleEvent($event) {

    if(this.data.create){ this.dialogRef.close($event);}
    else{ this.dialogRef.close([this.data.user._id, $event]);}
  }

}
