import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProjectsService, UsersService, CompaniesService, MeasurementsService} from '@cosmos/angular/api';
import { ActionDefinition, ColumnDefinition, FileUploadService} from '@cosmos/angular/ui';
import { ErrorService, StateService } from '@cosmos/angular/utils';
import { Company, Project, User } from '@cosmos/shared';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationDialogComponent } from '@cosmos/angular/ui';
import { ProjectDialogComponent } from '../dialogs/project-dialog/project-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'cosmos-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {

  users: User[] = [];
  companies: Company[] = [];
  projects: Project[] = [];
  user: User;
  confirmDialog: MatDialogRef<ConfirmationDialogComponent>;

  actions: ActionDefinition[] = [
    {name: 'edit', label: 'table.actions.edit', icon: 'edit', sticky: true},
    {name: 'delete', label: 'table.actions.delete', icon: 'delete', sticky: true},
    {name: 'download', label: 'table.actions.download', icon: 'download', sticky: true},
  ];

  columns: ColumnDefinition[] = [
    {name: '_id', label: 'table.labels.id', visible: true, sortable: true, draggable: true},
    {name: 'name', label: 'table.labels.name', visible: true, sortable: true, draggable: true},
    {name: 'description', label: 'table.labels.description', visible: true, sortable: true, draggable: true}
  ];

  constructor(
    private projectsService: ProjectsService,
    public projectDialog: MatDialog,
    private usersService: UsersService,
    private companiesService: CompaniesService,
    private stateService: StateService,
    private translateService: TranslateService,
    private errorService: ErrorService,
    private measurementsService: MeasurementsService,
    private filesService: FileUploadService,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.user = this.stateService.getCurrentUser();

    this.getData();

    var queryU = '';//Query for users
    var queryC = '';//Query for company

    if(this.user.roles.indexOf("superadmin") <= -1) {// If user IS NOT superadmin
      this.users.push(this.user);
      // queryC = "/?account_manager=" + this.user._id;
      // queryU = "/?company=" + this.user.company;
    }

    this.usersService.find(queryU).subscribe({
      next: (response) => {this.users = response.body;},
      error: (e) => this.errorService.handleError(e),
    });

    this.companiesService.find(queryC).subscribe({
      next: (response) => {this.companies = response.body;},
      error: (e) => this.errorService.handleError(e),
    });
  }

  openDialog(id?: string, object?: any): any {
    const project = object;
    let create = true;
    const companies = this.companies;
    const users = this.users;

    if (project)  {
      create = false;
    }

      const dialogRef = this.projectDialog.open(ProjectDialogComponent, {
      data: { project, create, users, companies}
     });

     dialogRef.afterClosed().subscribe(async result => {
       if (result !== undefined) {
        if (create) {
          this.projectsService.create(result).subscribe({
            error: (e) => this.errorService.handleError(e),
            complete: () => { this.getData();}
          });
        } else {
          if (result[1].bim == "") result[1].bim = false;
          if (result[1].premium == "") result[1].premium = false;
          this.projectsService.update(result).subscribe({
            error: (e) => this.errorService.handleError(e),
            complete: () => this.getData(),
          });
        }
      }
     });
  }

  getData(): any {
    var query = '';

    // if(this.user.roles.indexOf("superadmin") <= -1) { //If NOT superadmin
    //   query="/?owner=" + this.user._id + "";
    // }

    this.projectsService.find(query).subscribe({
      next: (response) => {this.projects = response.body;},
      error: (e) => this.errorService.handleError(e),
    });
  }
  deleteElement(row: any): any {

    this.confirmDialog = this.projectDialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });

    this.confirmDialog.componentInstance.confirmMessage = this.translateService.instant("dialog.subtitle.delete.project") + row.name + "?";

    this.confirmDialog.afterClosed().subscribe(result => {
      if(result) {
        this.projectsService.delete(row._id).subscribe({
          error: (e) => this.errorService.handleError(e),
          complete: () => this.getData()
        });
      }
    });
  }

  downloadCSV(row: any) {
    // this.measurementsService.downloadCSV('_id=' + row._id).subscribe({
    this.measurementsService.downloadCSV('project=' + row._id).subscribe({
      next: (response) => { this.filesService.downloadCSVFromBlob(response); },
      error: (e) => {
        this.snackBar.open(this.translateService.instant("measurement.download.error"),"OK")
      }
    })
  }

  rowAction(event: any): any {
    if (event.action === 'edit') {
      this.openDialog(event.row._id, event.row);
    } else if (event.action === 'delete') {
      this.deleteElement(event.row);
    } else if (event.action === "download") {
      this.downloadCSV(event.row);
    }
  }

}
