export class FormFieldBase<T> {
  value: T|undefined;
  key: string;
  label: string;
  required: boolean;
  order: number;
  controlType: string;
  type: string;
  suffix: string;
  save: boolean;
  multiple: boolean;
  long: boolean;
  context: string;
  reflect: {formField: string, foreignField: string, dataset: any};
  options: {key: string, value: string, lora?: string}[];

  constructor(options: {
      value?: T;
      key?: string;
      label?: string;
      required?: boolean;
      order?: number;
      controlType?: string;
      type?: string;
      save?: boolean;
      multiple?: boolean;
      suffix?: string;
      long?: boolean;
      context?: string;
      reflect?: {formField: string, foreignField: string, dataset: any};
      options?: {key: string, value: string, lora?: string}[];
    } = {}) {
    this.value = options.value;
    this.key = options.key || '';
    this.label = options.label || '';
    this.required = !!options.required;
    this.order = options.order === undefined ? 1 : options.order;
    this.controlType = options.controlType || '';
    this.type = options.type || '';
    this.suffix = options.suffix || '';
    this.save = options.save || true;
    this.long = options.long || false;
    this.context = options.context || 'default';
    this.multiple = options.multiple;
    this.reflect = options.reflect || null;
    this.options = options.options || [];
  }
}
