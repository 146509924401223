<!-- CONTENT -->
<h1>{{"measurement.admin.title" | translate}}</h1>
<p>{{"measurement.admin.subtitle" | translate}}</p>

<div class="container-fluid">
  <div class="row">
    <button mat-raised-button color="primary" (click)="openDialog()">{{"measurement.button" | translate}}</button>
  </div>
</div>

<mat-list>
  <mat-divider></mat-divider>
</mat-list>

<cosmos-data-table
  *ngIf="measurements"
  [tableData]="measurements"
  [tableColumns]="columns"
  [isFilterable]="true"
  [isPageable]="true"
  [toggleColumnsVisibility]="true"
  [rowActions]="actions"
  [paginatorCount]="count"
  [queryFields]="queryFields"
  [isLinked]="'measurement-only'"
  (rowAction)="rowAction($event)"
  (pageChange)="paginatorEvent($event)"
  (queryChange)="queryEvent($event)">
</cosmos-data-table>
<!-- <mat-paginator [length]="count" [showFirstLastButtons]="true"  [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 25]" (page)="paginatorEvent($event)"></mat-paginator> -->
