import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ConcretesService, DevicesService, MeasurementsService, ProjectsService, StagesService } from '@cosmos/angular/api';
import { ErrorService, StateService } from '@cosmos/angular/utils';
import { Concrete, Device, Measurement, Project, Stage, User } from '@cosmos/shared';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { DialogComponent } from '../../dialog/dialog.component';

@Component({
  selector: 'cosmos-stage',
  templateUrl: './stage.component.html',
  styleUrls: ['./stage.component.scss']
})
export class StageComponent implements OnInit {

  user: User;
  project: Project;
  stage: Stage;
  measurements: Measurement[] = [];
  //----------- Always called, ineficient
  concretes: Concrete[];
  devices: Device[];
  //-------

  constructor(
    private errorService: ErrorService,
    private translateService: TranslateService,
    private readonly measurementsService: MeasurementsService,
    private readonly stagesService: StagesService,
    private readonly concretesService: ConcretesService,
    private readonly devicesService: DevicesService,
    private stateService: StateService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    ) { }

  ngOnInit(): void {

    this.stateService.currentUser$.subscribe(x => {this.user = x;});
    const url = (this.router.url).split("/");//Gets the current url, using the last part for the object
    this.stagesService.findById(url[url.length - 1], '/?populate_project').subscribe({
      next: (response) => {
        this.project = response.body.project;
        this.stage = response.body;
      },
      error: (e) => this.errorService.handleError(e),
      complete: () => this.getData(),
    })

  }

  newMeasurement() {
    const concretesGetter = this.concretesService.find('/?project=' + this.project._id);
    const devicesGetter = this.devicesService.find('/?project=' + this.project._id + '&available=true');

    forkJoin([concretesGetter, devicesGetter]).subscribe({
      next: (response) => this.openDialog(response),
      error: (e) => {
        if(e.error.message == "Devices not Found"){
          this.snackBar.open(this.translateService.instant('devices.not-found'),"OK",{duration: 5000})
        }
      },
    });
  }

  openDialog(variables: any[]){
    const type = "measurement"
    const concretes = variables[0].body;
    const devices = variables[1].body;
    const create = true;
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {create, type, devices, concretes }
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result !== undefined){
        result = {
          name: result.name,
          description: result.description,
          concrete: result.concrete,
          device: result.device,
          stage: this.stage._id,
          project: this.project._id,
          company: this.project.company,
          owner : this.user._id,
          specifications: result.specifications,
        }
        this.measurementsService.create(result).subscribe({
          error: (e) => this.errorService.handleError(e),
          complete: () =>  this.getData()
        })
      }
    })
  }

  editStage(){
    const type = "stage"
    const stage = this.stage;
    const create = false;
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {create, type, stage }
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result !== undefined){
        this.stagesService.update([this.stage._id,result]).subscribe({
          next: (response) => this.stage = response.body,
          error: (e) => this.errorService.handleError(e),
          complete: () => this.getData()
        });
      }
    })
  }

  getData(): void {
    this.measurementsService.find('/?stage=' + this.stage._id).subscribe({
      next: (event:any) => this.measurements = event.body,
      error:(e) => this.errorService.handleError(e),
    });

  }

}
