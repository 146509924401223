<div class="mb-4">
  <mat-button-toggle-group *ngIf="stages.length > 0" #group="matButtonToggleGroup" (change)="updateStorage(group.value)" value="{{currentGroup}}">
      <mat-button-toggle value="table" aria-label="Table">
          <mat-icon>list</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle value="cards" aria-label="Cards">
          <mat-icon>apps</mat-icon>
          </mat-button-toggle>
  </mat-button-toggle-group>
</div>

<div class="row mb-4" *ngIf="stages.length > 0 && currentGroup === 'cards'">
    <div class="col-md-4" *ngFor="let stage of stages">
        <mat-card appearance="outlined">
            <mat-card-header>
                <div mat-card-avatar class="text-center card-avatar">{{stage.name[0]}}</div>
                <mat-card-title class="mb-1">{{stage.name}}</mat-card-title>
                <mat-card-subtitle>{{ "stages.card.description" | translate }}</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
              <mat-list>
                <mat-list-item>
                  <mat-icon matListItemIcon>square_foot</mat-icon>
                  <div matListItemTitle>{{stage.number_measurements?stage.number_measurements:'N/A'}}</div>
                  <div matListItemLine>{{ "stages.card.number-measurements" | translate }}</div>
                </mat-list-item>
              </mat-list>
            </mat-card-content>
            <mat-card-actions>
                <button mat-button color="primary" routerLink="/projects/{{this.stage.project}}/{{stage._id}}">{{ "button.open" | translate }}</button>
            </mat-card-actions>
        </mat-card>
    </div>
</div>

<cosmos-data-table
    *ngIf="stages.length > 0 && currentGroup === 'table'"
    [isLinked]="'stage'"
    [tableData]="stages"
    [tableColumns]="columns"
    [isFilterable]="true"
    [toggleColumnsVisibility]="true"
    (rowAction)="rowAction($event)">
</cosmos-data-table>

<div class="row mb-4" *ngIf="stages.length === 0">
    <div class="col-md">
        {{ "stages.not-found" | translate }}
    </div>
</div>
