<div class="container-login mat-drawer-container">
  <mat-card  appearance="outlined"  class="login-card">
    <mat-card-content>
      <img src="" class="login-img">

      <form class="login-form" [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="d-flex flex-column">
        <mat-form-field class="input-full-width">
            <input matInput type="email" [placeholder]="'fields.email' | translate" formControlName="email">
            <!-- <input matInput type="email" placeholder="Email" name="email" [(ngModel)]="email"> -->
        </mat-form-field>

        <mat-form-field class="input-full-width">
          <input matInput type="password" [placeholder]="'fields.password' | translate" formControlName="password" >
          <!-- <input matInput type="password" placeholder="Password" name="password" [(ngModel)]="password"> -->
        </mat-form-field>

        <button *ngIf="!loading" class="input-full-width" type="submit" mat-raised-button color="primary">{{'button.login' | translate}}</button>
        <!-- <mat-spinner *ngIf="loading" [diameter]="20"></mat-spinner> -->

        <!-- FOR DEVELOPMENT PURPOSES, DELETE ONCE LINKED WITH THE DASHBOARD AND LOGOUT EXISTS -->
        <!-- <button mat-stroked-button color="primary" (click)="logOut()">Log out</button> -->
      </form>
      <div class="d-flex flex-column">
        <button *ngIf="!loading && error" class="w-100 mt-2" mat-stroked-button color="primary" routerLink="/recovery">{{'login.password-reset' | translate}}</button>
        <button *ngIf="signupAllowed" class="w-100 mt-2" mat-stroked-button color="primary" routerLink="/signup">{{'login.signup' | translate}}</button>
      </div>
    </mat-card-content>

  </mat-card>
  <!-- <a class="linked" href="/recovery">{{'login.password-reset' | translate}}</a> -->
</div>
