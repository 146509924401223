import { FormFieldBase, FormFieldDropdown, FormFieldTextbox } from "@cosmos/angular/ui";
import { of } from "rxjs";

export class FormFieldsClass {
  formFields: FormFieldBase<string>[];

  constructor(create: boolean, data:any, users: any[], companies: any[], roles?: string[]) {
    // COMMON FIELDS
    this.formFields = [
      // NAME
      new FormFieldTextbox({
        key: 'name',
        label: 'fields.name',
        value: create?'':data.name,
        required: true,
        order: 1
      }),
      // DROPDOWN - USER - CREATED BY
      /*new FormFieldDropdown({
        key: 'owner',
        label: 'Creado por',
        value: create?'':data.owner,
        options: users,
        order: 2,
        required: true,
      }),*/
      // DROPDOWN - USER - ACCOUNT MANAGER
      new FormFieldDropdown({
        key: 'account_manager',
        label: 'fields.account-manager',
        value: create?'':data.account_manager._id,
        options: users,
        order: 2,
        required: true,
      }),
      // DROPDOWN - COMPANY - PARENT COMPANY
      new FormFieldDropdown({
        key: 'parent_company',
        label: 'fields.parent-company',
        value: create?'':data.parent_company?._id,
        options: companies,
        order: 3,
        required: (roles.indexOf('superadmin')>0)?false:true,
      }),
    ]

    // EDIT ONLY FIELDS
    if(!create) {
      this.formFields.push(

      );
    }
  }

  getCreateFields()
  {
    return of(this.formFields.sort((a, b) => a.order - b.order));
  }
}
