import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorService } from '@cosmos/angular/utils';
import { BillingAccount } from '@cosmos/shared';
import { catchError, map, Observable } from 'rxjs';
import { environment } from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BillingAccountsService {

  constructor(
    private http: HttpClient,
    private errorService: ErrorService,
  ) { }

  find(query=''): Observable<HttpResponse<BillingAccount[]>> {
    return this.http
      .get<BillingAccount[]>(`${environment.API_URL}/billingaccounts${query}`, {observe: 'response'})
        .pipe(
          map(response => {
          return response;
        }),
        catchError( (error) => this.errorService.handleError(error))
      );
  }

  findById(id: string): Observable<HttpResponse<BillingAccount>> {
    return this.http
      .get<BillingAccount>(`${environment.API_URL}/billingaccounts/${id}`, {observe: 'response'})
        .pipe(
          map(response => {return response;}),
          catchError( (error) => this.errorService.handleError(error))
        );
  }

  create(body: BillingAccount) {
    return this.http
      .post(`${environment.API_URL}/billingaccounts`, body, {observe: 'response'})
        .pipe(
          map(response => {return response;}),
          catchError( (error) => this.errorService.handleError(error))
        );
  }

  update(body: any) {
    return this.http
      .patch(`${environment.API_URL}/billingaccounts/${body[0]}`, body[1], {observe: 'response'})
        .pipe(
          map(response => {return response;}),
          catchError( (error) => this.errorService.handleError(error))
        )
  }

  delete(id: string) {
    return this.http
      .delete(`${environment.API_URL}/billingaccounts/${id}`, {observe: 'response'})
        .pipe(
          map(response => {return response;}),
          catchError( (error) => this.errorService.handleError(error))
        );
  }

}
