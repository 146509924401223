import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})

export class JwtService {

  constructor(
    private storageService: StorageService,
  ) {}

  getToken(): string {
    return this.storageService.getItem('jwtToken');
  }

  saveToken(token: string): void {
    this.storageService.setItem('jwtToken', token);
  }

  destroyToken(): void {
    this.storageService.removeItem('jwtToken');
  }
}
