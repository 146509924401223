<h1 mat-dialog-title>{{title}}</h1>

<mat-dialog-content>
  <cosmos-dynamic-form [fields]="fields | async" (formValue)="handleEvent($event)"></cosmos-dynamic-form>
</mat-dialog-content>

<!-- <mat-dialog-content [formGroup]="companyForm" class="d-flex flex-column">
  <mat-form-field appearance="standard" class="input-full-width">
    <input matInput type="text" placeholder="Name" formControlName="name">
  </mat-form-field>


  <mat-form-field appearance="standard" class="input-full-width">
    <mat-select formControlName="owner" placeholder="Creado por">
      <mat-option [value]='null'>-</mat-option>
      <mat-option *ngFor="let user of data.users" [value]="user._id">{{ user.name }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="standard" class="input-full-width">
    <mat-select formControlName="account_manager" placeholder="Gestor">
      <mat-option [value]='null'>-</mat-option>
      <mat-option *ngFor="let user of data.users" [value]="user._id">{{ user.name }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="standard" class="input-full-width">
    <mat-select formControlName="parent_company" placeholder="Empresa propietaria">
      <mat-option [value]='null'>-</mat-option>
      <mat-option *ngFor="let company of data.companies" [value]="company._id">{{ company.name }}</mat-option>
    </mat-select>
  </mat-form-field> -->

  <!-- <mat-spinner *ngIf="loading" [diameter]="20"></mat-spinner> -->

  <!-- FOR DEVELOPMENT PURPOSES, DELETE ONCE LINKED WITH THE DASHBOARD AND LOGOUT EXISTS -->
<!-- </mat-dialog-content> -->

<!-- <mat-dialog-actions class="justify-content-center">
  <button mat-raised-button color="primary" (click)="save()" [disabled]="!companyForm.valid">{{ data.create?'Crear':'Actualizar'}}</button>
</mat-dialog-actions> -->
