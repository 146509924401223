<h1 mat-dialog-title>{{title}}</h1>

<mat-dialog-content>
  <cosmos-dynamic-form [fields]="fields | async" (formValue)="handleEvent($event)"></cosmos-dynamic-form>
</mat-dialog-content>

<!--
<mat-dialog-content [formGroup]="userForm" class="d-flex flex-column">
  <mat-form-field appearance="standard" class="input-full-width">
    <input matInput type="text" placeholder="Name" formControlName="name">
  </mat-form-field>

  <mat-form-field appearance="standard" class="input-full-width">
    <input matInput type="text" placeholder="Surname" formControlName="surname">
  </mat-form-field>

  <mat-form-field appearance="standard" class="input-full-width">
    <mat-select formControlName="company" placeholder="Empresa">
      <mat-option [value]='null'>-</mat-option>
      <mat-option *ngFor="let company of data.companies" [value]="company._id">{{ company.name }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="standard" class="input-full-width" *ngIf="data.create">
      <input matInput type="email" placeholder="Email" formControlName="email">
  </mat-form-field>

  <mat-form-field appearance="standard" class="input-full-width" *ngIf="data.create">
    <input matInput type="password" placeholder="Contraseña" formControlName="password" >
  </mat-form-field>
-->
  <!-- <mat-spinner *ngIf="loading" [diameter]="20"></mat-spinner> -->

  <!-- FOR DEVELOPMENT PURPOSES, DELETE ONCE LINKED WITH THE DASHBOARD AND LOGOUT EXISTS -->
<!-- </mat-dialog-content>

<mat-dialog-actions class="justify-content-center">
  <button mat-raised-button color="primary" (click)="save()" [disabled]="!userForm.valid">{{ data.create?'Crear':'Actualizar'}}</button>
</mat-dialog-actions> -->
