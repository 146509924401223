import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ConcretesService, DevicesService, MeasurementsService } from '@cosmos/angular/api';
import { StateService } from '@cosmos/angular/utils';
import { Concrete, Device, Measurement, Project, User } from '@cosmos/shared';
import { DialogComponent } from '../../dialog/dialog.component';
import { ErrorService } from '@cosmos/angular/utils';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationDialogComponent } from '@cosmos/angular/ui';

@Component({
  selector: 'cosmos-measurement',
  templateUrl: './measurement.component.html',
  styleUrls: ['./measurement.component.scss']
})
export class MeasurementComponent implements OnInit {

  measurement: Measurement;
  concrete: Concrete;
  device: Device;
  project: Project;
  user: User;
  predictions: any;

  confirmDialog: MatDialogRef<ConfirmationDialogComponent>;


  constructor(
    private errorService: ErrorService,
    private translateService: TranslateService,
    private readonly measurementsService: MeasurementsService,
    private readonly concretesService: ConcretesService,
    private readonly devicesService: DevicesService,
    private stateService: StateService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    public finishDialog: MatDialog,

    ) { }

  ngOnInit(): void {
    this.stateService.currentUser$.subscribe(x => {this.user = x;});

    const url = (this.router.url).split("/");//Gets the current url, using the last part for the object
    this.measurementsService.findById(url[url.length - 1], '/?populate_project').subscribe({
      next: (response) => {this.project = response.body.project; this.measurement = response.body},
      error: (e) => this.errorService.handleError(e),
      complete: () => this.getData(),
    });

    this.measurementsService.getPredictions(url[url.length - 1]).subscribe({
      next: (response) => {this.predictions = response.body;},
      error: (e) => this.errorService.handleError(e),
      complete: () => {},
    });

    // CALCULATE PREDICTION FOR CONCRETE
    // if(this.project.premium) {
    //   const
    // }
  }

  getData() {
      this.concretesService.findById(this.measurement.concrete).subscribe({
        next: (event:any) => { this.concrete = event.body;}
      });
      this.devicesService.findById(this.measurement.device).subscribe({
        next: (event:any) => { this.device = event.body;}
      });
  }

  editMeasurement() {

    this.concretesService.find('/?project=' + this.project._id).subscribe({
      next: (response) =>{ this.openDialog(response.body)},
    })
  }

  finishMeasurement(id: string) {
    this.confirmDialog = this.finishDialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });
    this.confirmDialog.componentInstance.confirmMessage = this.translateService.instant("dialog.subtitle.finish-measurement") + this.measurement.name;

    this.confirmDialog.afterClosed().subscribe(result => {
      if(result) {
        this.measurementsService.finish(id).subscribe({
          next: (response) => this.measurement = response,
          error: (e) => this.errorService.handleError(e),
          complete: () => this.getData(),
        })
      }
    });
  }

  changeDevice(measurement: Measurement){
    let devices;
    this.devicesService.find('/?project=' + this.measurement.project._id + '&available=true').subscribe({
      next: (response) => devices = response.body,
      error: (e) => {
        if(e.error.message == "Devices not Found"){
        this.snackBar.open(this.translateService.instant('devices.not-found'),"OK",{duration: 5000});
        }
      },
      complete: () =>{
        const type = "changeDevice";
        const dialogRef = this.dialog.open(DialogComponent, {
          data: {type, devices, measurement}
        });
        dialogRef.afterClosed().subscribe(async result => {
          if (result!== undefined) {
            this.changeDeviceSender(result);
          }
        })
      }
    });



  }

  changeDeviceSender(device: Device){
    this.measurementsService.changeDevice(this.measurement._id,device).subscribe({
      next: (response) => this.measurement = response.body,
      error: (e) => this.errorService.handleError(e),
      complete: () => this.getData(),
    })
  }

  openDialog(concretes: Concrete[]) {
    const type= "measurement";
    const measurement =  this.measurement;
    const create = false;

    const dialogRef = this.dialog.open(DialogComponent, {
      data: {create, type, concretes, measurement}
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result !== undefined){
        result.specifications = result.specifications;

        this.measurementsService.update([this.measurement._id,result]).subscribe({
          next: (response) => this.measurement = response.body,
          error: (e) => this.errorService.handleError(e),
          complete: () => this.getData(),
        })
      }
    })
  }

}
