import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UsersService } from '@cosmos/angular/api';
import { ErrorService, StateService } from '@cosmos/angular/utils';
import { User } from '@cosmos/shared';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'environments/environment';

@Component({
  selector: 'cosmos-user-generic',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserGenericComponent implements OnInit {

  user: User;
  changeName: string;
  changeSurname: string;
  changePhone: string;

  constructor(
    private translateService: TranslateService,
    private errorService: ErrorService,
    private stateService: StateService,
    private usersService: UsersService,
    private http: HttpClient,
    private snackBar: MatSnackBar,
  ) {
    this.stateService.currentUser$.subscribe(x => {this.user = x;});
   }

  ngOnInit(): void {
    this.changeName = this.user.name;
    this.changeSurname = this.user.surname;
    this.changePhone = this.user.phone;
  }

  updateUser(): void{
    this.user.name = this.changeName;
    this.user.surname = this.changeSurname;
    this.user.phone = this.changePhone;
    this.usersService.update([this.user._id, this.user]).subscribe({
      error: (e) => this.errorService.handleError(e),
    });
  }

  resetPassword(): void{
    this.http.post(`${environment.API_URL}/recoveryemail/`, {email: this.user.email},{observe: 'response'}).subscribe({
      next: () => {
        this.snackBar.open(this.translateService.instant('recovery.success'),"OK");
      },
      error: (e) => {this.snackBar.open(this.translateService.instant('recovery.error'),"OK"); this.errorService.handleError(e);}
    });
  }

}
