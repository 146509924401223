<div *ngIf="this.stage" class="row my-4">
    <div class="col-md">
        <mat-card appearance="outlined" >
            <mat-card-header>
                <div mat-card-avatar class="text-center card-avatar">{{stage.name[0]}}</div>
                <mat-card-title class="mb-1">{{stage.name}}</mat-card-title>
                <mat-card-subtitle> {{"stages.title" | translate}}</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
              <mat-list>
                <mat-list-item>
                  <mat-icon matListItemIcon>schedule</mat-icon>
                  <div matListItemTitle>{{ stage.createdAt | date:'dd/MM/yyyy HH:mm:ss' }}</div>
                  <div matListItemLine>{{ "stages.card.created-at" | translate }}</div>
                </mat-list-item>
                <mat-list-item>
                  <mat-icon matListItemIcon>update</mat-icon>
                  <div matListItemTitle>{{ stage.updatedAt | date:'dd/MM/yyyy HH:mm:ss' }}</div>
                  <div matListItemLine>{{ "stages.card.updated-at" | translate }}</div>
                </mat-list-item>
                <mat-divider></mat-divider>
                <mat-list-item>
                  <mat-icon matListItemIcon>description</mat-icon>
                  <div matListItemTitle>{{stage.description?stage.description:'-'}}</div>
                  <div matListItemLine>{{ "stages.card.description-label" | translate }}</div>
                </mat-list-item>
              </mat-list>
                <!-- <p>
                    <mat-icon class="small-icon mr-1">schedule</mat-icon><b> </b></p>
                <p>
                    <mat-icon class="small-icon mr-1">update</mat-icon><b> </b></p>
                <p>
                    <mat-icon class="small-icon mr-1">description</mat-icon></p> -->
            </mat-card-content>

            <mat-card-actions>
                <button *ngIf="project?.owner === user?._id || (project?.shared_w.indexOf(user?._id) + 1)" (click)="editStage()" mat-button color="primary">{{ "button.edit" | translate }}
                </button>
            </mat-card-actions>

        </mat-card>
    </div>
</div>

<mat-divider></mat-divider>

<p class="h4 mt-4">{{"stages.measurements" | translate}}</p>

<button mat-raised-button color="primary" *ngIf="project?.owner === user?._id || (project?.shared_w.indexOf(user?._id) + 1)" (click)="newMeasurement()">{{"stages.new_measurement" | translate}} </button>

<cosmos-measurement-card *ngIf="this.stage" [measurements]="measurements"></cosmos-measurement-card>
<!--
<P>MEASUREMENTS</P>

<div *ngFor="let measurement of this.measurements">
    <a routerLink="/projects/{{this.project._id}}/{{stage._id}}/{{measurement._id}}" > {{measurement.name}}</a>
</div>
-->
