import { Component, ViewEncapsulation} from '@angular/core';
import { Observable } from 'rxjs';

import { Breadcrumb } from './breadcrumb.model';
import { BreadcumbsService } from './breadcrumbs.service';

@Component({
  encapsulation: ViewEncapsulation.Emulated,
  selector: 'cosmos-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss',]
})
export class BreadcrumbsComponent {

  breadcrumbs$: Observable<Breadcrumb[]>;

  constructor(private readonly breadCrumbService: BreadcumbsService) {
    this.breadcrumbs$ = breadCrumbService.breadcrumbs$;
   }

}
