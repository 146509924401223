import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormFieldBase } from '@cosmos/angular/ui';
import { StateService } from '@cosmos/angular/utils';
import { User } from '@cosmos/shared';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { FormFieldsClass } from '../../gateways/gateways.forms';

@Component({
  selector: 'cosmos-gateway-dialog',
  templateUrl: './gateway-dialog.component.html',
  styleUrls: ['./gateway-dialog.component.scss']
})
export class GatewayDialogComponent implements OnInit {

  title = '';
  fields: Observable<FormFieldBase<string>[]>;
  user: User;

  constructor(
    public dialogRef: MatDialogRef<GatewayDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private stateService: StateService,
    private translateService: TranslateService,
  ) { }

  ngOnInit(): void {

    this.user = this.stateService.getCurrentUser();

    const companies: {key: string, value: string}[] = [];
    const projects: {key: string, value: string}[] = [];

    if(this.data.companies) {
      for(const company of this.data.companies) companies.push({key: company['_id'], value: company['name'] });
    }

    if(this.data.projects) {
      for(const project of this.data.projects) projects.push({key: project['_id'], value: project['name'] });
    }

    if (this.data.create) {

      this.title = this.translateService.instant('dialog.title.create.gateway');
      this.fields = new FormFieldsClass(true, this.data.gateway, companies, projects, this.user.roles.indexOf('superadmin') > -1).getCreateFields();


    } else {

      this.title = this.translateService.instant('dialog.title.edit.gateway') + ' ' + this.data.gateway._id;
      this.fields = new FormFieldsClass(false, this.data.gateway, companies, projects, this.user.roles.indexOf('superadmin') > -1).getCreateFields();
    }

  }

  handleEvent($event){
    if(this.data.create) this.dialogRef.close($event);
    else this.dialogRef.close([this.data.gateway._id, $event]);
  }
}
