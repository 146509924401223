import { FormFieldBase, FormFieldDropdown } from "@cosmos/angular/ui";
import { of } from "rxjs";

export class FormFieldsClassDevices {
  formFields: FormFieldBase<string>[];

  constructor(data:any, devices: any) {
    // COMMON FIELDS
    this.formFields = [
      new FormFieldDropdown({
        key: 'device',
        label: 'fields.device',
        value: data.create?'':data.measurement._id,
        options: devices,
        required: true,
        order: 1
      }),
    ]
  }

  getCreateFields()
  {
    return of(this.formFields.sort((a, b) => a.order - b.order));
  }
}
