import { Component, Input, OnInit } from '@angular/core';

import 'chartjs-adapter-moment';
import { Interaction, InteractionModeFunction } from 'chart.js';
import { CrosshairPlugin } from '../plugins/chartjs-plugin-crosshair.esm';
import { interpolate } from '../plugins/interpolate';
import { TranslateService } from '@ngx-translate/core';

declare module 'chart.js' {
  interface InteractionModeMap {
    interpolate: InteractionModeFunction;
  }
}

Interaction.modes.interpolate = interpolate;

@Component({
  selector: 'cosmos-predictive-chart',
  templateUrl: './predictive-chart.component.html',
  styleUrls: ['./predictive-chart.component.scss']
})
export class PredictiveChartComponent implements OnInit {

  @Input()
  realData: any;

  @Input()
  predictionData: any;

  //CHART
  // chartType = 'scatter';
  chartType = 'line';
  chartPlugins = [CrosshairPlugin];
  chartData = {
    datasets: [],
    labels: []
  };

  chartOptions = {
    scales: {
      // Define the X axis and its components.
      x: {
        type: 'time',
        ticks: {
          //Enables Days in legend
          major: {
             enabled: true,
          },
      },

      },
    },
    plugins: {
      tooltip: {
        enabled: true,
        // mode: 'interpolate',
        mode: 'index',
        intersect: false
      },
      crosshair: {
        zoom: {
          enabled: true,
        }
      },
      legend: { display: true },
    }
  };



  constructor(
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {

    // LOADING CHART DATA
    // const concreteData = [
    //   {data: this.measurement.temperature, label: 'Temperature', cubicInterpolationMode: 'monotone'},
    //   {data: this.measurement.strength, label: 'Strength', cubicInterpolationMode: 'monotone'}
    // ];

    // this.chartData.datasets = concreteData;
    // this.chartData.labels = this.measurement.time;

    // LOADING CHART DATA - INTERPOLATE / DIFFERENT TIME AXIS
      const realTime = [];
      this.realData.time.forEach(element => {
        realTime.push(new Date(element).getTime());
      });

      const predictionTime = [];
      this.predictionData.time.forEach(element => {
        predictionTime.push(new Date(element).getTime());
      });

      this.chartData.datasets.push(this.generateDataset(predictionTime, this.predictionData.strength, this.translateService.instant('chart.legend.strength-prediction')));
      this.chartData.datasets.push(this.generateDataset(realTime, this.realData.strength, this.translateService.instant('chart.legend.strength')));

  }


  // TO GENERATE SCATTER DATA (DIFFERENT TIME AXIS)

  generateDataset(dataX, dataY, label) {
    const data = [];

    dataX.forEach((element,index) => {
      data.push({ x: element, y: dataY[index] });
    });

    const dataset = {
      // backgroundColor: color,
      // borderColor: color,
      showLine: true,
      fill: false,
      pointRadius: 2,
      label: label,
      data: data,
      lineTension: 0,
      interpolate: true,
      cubicInterpolationMode: 'monotone'
    };
    return dataset;
  }

}
