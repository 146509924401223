<div class="mt-6">
    <div class="my-4">
      <h3>{{ "account.greeting" | translate}} {{user.name}} {{user.surname}}!</h3>
      <mat-card appearance="outlined">
          <mat-card-content>{{ "account.email" | translate}} {{this.user.email}}</mat-card-content>
      </mat-card>
    </div>
  
    <mat-divider></mat-divider>
  
    <div class="my-4">
      <h3>{{ "account.update.title" | translate}}</h3>
  
      <div class="row mb-3">
        <div class="col-md-4">
          <mat-card appearance="outlined">
            <mat-card-header>
              <mat-card-title>{{ "account.name" | translate}}</mat-card-title>
            </mat-card-header>

            <mat-card-content>
              <mat-form-field>
                <mat-label>{{ "account.update.name" | translate}}</mat-label>
                <input matInput [(ngModel)]="changeName" >
              </mat-form-field>
            </mat-card-content>
          </mat-card>
        </div>
        <div class="col-md-4">
          <mat-card appearance="outlined" >
            <mat-card-header>
              <mat-card-title>{{ "account.surname" | translate}}</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <mat-form-field>
                <mat-label>{{ "account.update.surname" | translate}}</mat-label>
                <input matInput [(ngModel)]="changeSurname">
              </mat-form-field>
            </mat-card-content>
          </mat-card>
        </div>
        <div class="col-md-4">
          <mat-card appearance="outlined">
              <mat-card-header>
                <mat-card-title>{{ "account.phone" | translate}}</mat-card-title>
              </mat-card-header>
              <!-- <mat-card-subtitle>{{ "account.update.phone" | translate}}</mat-card-subtitle> -->
              <mat-card-content>
                <mat-form-field>
                  <mat-label>{{ "account.update.phone" | translate}}</mat-label>
                  <input matInput [(ngModel)]="changePhone">
                </mat-form-field>
              </mat-card-content>
          </mat-card>
        </div>
      </div>
  
      <button mat-raised-button color="primary" [disabled]="user.name === changeName && user.surname === changeSurname && user.phone === changePhone"  class="" (click)="updateUser()">{{ "button.save" | translate}}</button>
    </div>
  
    <mat-divider></mat-divider>
  
    <div class="col-md my-4">
        <h3>{{ "account.reset-pass" | translate}}</h3>
        <button mat-raised-button color="primary" (click)="resetPassword()">{{ "button.reset" | translate}}</button>
    </div>