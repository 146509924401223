import { Component, OnInit, Input, OnChanges, ViewChild, AfterViewInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { StateService, StorageService } from '@cosmos/angular/utils';
import { User } from '@cosmos/shared';

@Component({
  selector: 'cosmos-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements AfterViewInit, OnInit, OnChanges {

  @ViewChild('sidenav') public sidenav: MatSidenav;

  @Input()
  openNav: boolean;

  user: User;

  viewInit = false;
  isExpanded = true;

  constructor(
    private storageService: StorageService,
    private stateService: StateService,
  ) {}

  ngOnInit(): void {
    if(this.storageService.getItem('navExpanded')) this.isExpanded = JSON.parse(this.storageService.getItem('navExpanded'));
    this.user = this.stateService.getCurrentUser();
  }

  ngAfterViewInit(): void {
    this.viewInit = true;
  }

  expandSidebar(expand: boolean): void {
    this.isExpanded = expand;
    this.storageService.setItem('navExpanded', expand);
  }

  ngOnChanges(): void {
    if(this.viewInit) {
      if (this.openNav) this.sidenav.open();
      else this.sidenav.close();
    }
  }
}
