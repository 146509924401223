import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorService } from '@cosmos/angular/utils';
import { CuringPool } from '@cosmos/shared';
import { catchError, map, Observable } from 'rxjs';
import { environment } from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CuringPoolssService {

  constructor(
    private http: HttpClient,
    private errorService: ErrorService,
  ) { }

  link(body: any) {
    return this.http
      .patch(`${environment.API_URL}/lab/curing-pools/link/${body[0]}`, body[1], {observe: 'response'})
      .pipe(
        map(response => {return response;}),
        catchError( (error) => this.errorService.handleError(error))
      );
  }

  unlink(body: any) {
    return this.http
      .patch(`${environment.API_URL}/lab/curing-pools/unlink/${body[0]}`, body[1], {observe: 'response'})
      .pipe(
        map(response => {return response;}),
        catchError( (error) => this.errorService.handleError(error))
      );
  }

  // ONLY FOR PHYSICAL DEVICE
  // ping(body: any) {
  //   return this.http
  //     .patch(`${environment.API_URL}/lab/curing-pools/ping/${body[0]}`, body[1], {observe: 'response'})
  //     .pipe(
  //       map(response => {return response;}),
  //       catchError( (error) => this.errorService.handleError(error))
  //     );
  // }

  find(query = ''): Observable<HttpResponse<CuringPool[]>> {
    return this.http
      .get<CuringPool[]>(`${environment.API_URL}/lab/curing-pools${query}`, {observe: 'response'})
        .pipe(
          map(response => {return response;}),
          catchError( (error) => this.errorService.handleError(error))
        );
  }

  findById(id: string, query = ''): Observable<HttpResponse<CuringPool>> {
    return this.http
      .get<CuringPool>(`${environment.API_URL}/lab/curing-pools/${id}${query}`, {observe: 'response'})
      .pipe(
        map(response => {return response;}),
        catchError( (error) => this.errorService.handleError(error))
      );
  }

  create(body: CuringPool) {
    return this.http
      .post(`${environment.API_URL}/lab/curing-pools`, body, {observe: 'response'})
      .pipe(
        map(response => {return response;}),
        catchError( (error) => this.errorService.handleError(error))
      );
  }

  update(body: any) {
    return this.http
      .patch(`${environment.API_URL}/lab/curing-pools/${body[0]}`, body[1], {observe: 'response'})
      .pipe(
        map(response => {return response;}),
        catchError( (error) => this.errorService.handleError(error))
      );
  }

  delete(id: string) {
    return this.http
      .delete(`${environment.API_URL}/lab/curing-pools/${id}`, {observe: 'response'})
      .pipe(
        map(response => {return response;}),
        catchError( (error) => this.errorService.handleError(error))
      );
  }

}
