import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UserDialogComponent } from '../dialogs/user-dialog/user-dialog.component';
import { CompaniesService, UsersService } from '@cosmos/angular/api';
import { ActionDefinition, ColumnDefinition } from '@cosmos/angular/ui';
import { Company, User } from '@cosmos/shared';
import { ErrorService, StateService } from '@cosmos/angular/utils';
import { ConfirmationDialogComponent } from '@cosmos/angular/ui';
import { TranslateService } from '@ngx-translate/core';



@Component({
  selector: 'cosmos-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  companies: Company[] = [];
  users: User[] = [];
  user: User;
  confirmDialog: MatDialogRef<ConfirmationDialogComponent>;

  actions: ActionDefinition[] = [
    {name: 'edit', label: 'table.actions.edit', icon: 'edit', sticky: true},
    {name: 'delete', label: 'table.actions.delete', icon: 'delete', sticky: true},
  ];

  columns: ColumnDefinition [] = [
    {name: '_id', label: 'table.labels.id', visible: true, sortable: true, draggable: true},
    {name: 'name', label: 'table.labels.name', visible: true, sortable: true, draggable: true},
    {name: 'surname', label: 'table.labels.surname', visible: true, sortable: true, draggable: true},
    {name: 'email', label: 'table.labels.email', visible: true, sortable: true, draggable: true},
    {name: 'company.name', label: 'table.labels.company', visible: true, sortable: true, draggable: true},
    {name: 'lastLogin', label: 'table.labels.lastLogin', visible: true, sortable: true, draggable: true, type: 'date'},
  ];


  constructor(
    private usersService: UsersService,
    public userDialog: MatDialog,
    private companiesService: CompaniesService,
    private stateService: StateService,
    private translateService: TranslateService,
    private errorService: ErrorService,
  ) { }

  ngOnInit(): void {
    this.user = this.stateService.getCurrentUser();

    if(this.user.roles.indexOf("superadmin")> -1){ //Gets all companies if superAdmin
      this.companiesService.find().subscribe({
        next: (response) => this.companies = response.body,
        error: (e) => this.errorService.handleError(e),
      });
    } else{ // Or only those of the user if just admin
      this.companiesService.findById(this.user.company).subscribe({
        next: (response) => this.companies.push(response.body),
        error: (e) => this.errorService.handleError(e),
      });
    }
    this.getData();
  }

  openDialog(userId?: string, object?: any): void {
    let create = true;
    const companies = this.companies;
    const user = object;
    const currentUser = this.user;

    if (userId)  {
      create = false;
    }

    const translations = {
      sim: this.translateService.instant('role.sim'),
      user: this.translateService.instant('role.user'),
      admin: this.translateService.instant('role.admin'),
      superadmin: this.translateService.instant('role.superadmin')
    }

    const dialogRef = this.userDialog.open(UserDialogComponent, {
       data: { user, create, companies, currentUser, translations }
    });

    dialogRef.afterClosed().subscribe(async result => {
       if (result !== undefined) {
        if (create) {
          this.usersService.create(result).subscribe({
            error: (e) => this.errorService.handleError(e),
            complete: () => { this.getData();}
          });
        } else {
          this.usersService.update(result).subscribe({
            error: (e) => this.errorService.handleError(e),
            complete: () => { this.getData();}
          });
     }
     }
     });
  }

  getData(): void {
    let query;
    if(this.user.roles.indexOf("superadmin") > -1) {// If superadmin, gest all users
      query = '?populate_company=name';
    }
    else { // If not, just the ones with the same company as the user's MB RETHINK
      query = "/?populate_company=name&company=" + this.user.company;
    }

    this.usersService.find(query).subscribe({
      next: (response) => {this.users = response.body;},
      error: (e) => this.errorService.handleError(e),
    });

  }
  deleteElement(row: any): void {
    this.confirmDialog = this.userDialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });

    this.confirmDialog.componentInstance.confirmMessage = this.translateService.instant("dialog.subtitle.delete.user") + ' ' + row.name;

    this.confirmDialog.afterClosed().subscribe(result => {
      if(result) {
        this.usersService.delete(row._id).subscribe({
          error: (e) => this.errorService.handleError(e),
          complete: () => this.getData()
        })
      }
      this.confirmDialog = null;
    });
  }
  rowAction(event: any): void {
    if (event.action === 'edit') {
      this.openDialog(event.row._id, event.row);
    } else if (event.action === 'delete') {
      this.deleteElement(event.row);
    }
  }

}
