import { Component, Input, OnInit } from '@angular/core';




@Component({
  selector: 'cosmos-strength-insights-card',
  templateUrl: './stength-insights-card.component.html',
  styleUrls: ['./stength-insights-card.component.scss']
})
export class StrengthInsightsCardComponent implements OnInit {

  @Input() currentMaturity?: number;
  @Input() specifications_maturity?: number[];
  @Input() specifications?: number[];
  @Input() currentStrength: number;
  @Input() currentTime: any;
  @Input() config: any;


  constructor(
  ) { }

  ngOnInit(): void {
  }

  // THRESHOLDS TIME LOOKUP
  // const array1 = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15];
  // var thresholds_times = [];
  // var thresholds = [5 , 10, 15];

  // thresholds.forEach( threshold => {
  //   thresholds_times.push(array1.findIndex(element => element >= threshold));
  // });


  getNextStrengthCheckpoint(strength) {
    for (const checkpoint of this.specifications) {
      if (strength < checkpoint ) {
        return 100 * checkpoint / this.specifications[this.specifications.length-1];
      }
    }
    return 100;
  }

  getNextMaturityCheckpoint(maturity) {
    for (const checkpoint of this.specifications_maturity) {
      if (maturity < checkpoint ) {
        return 100 * checkpoint / this.specifications_maturity[this.specifications_maturity.length-1];
      }
    }
    return 100;
  }


}
