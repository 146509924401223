import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Company } from '@cosmos/shared';
import { ErrorService } from '@cosmos/angular/utils';

import { map, Observable, catchError} from 'rxjs';
import { environment } from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {
  constructor( private http: HttpClient, private errorService: ErrorService ) { }

  find(query=''): Observable<HttpResponse<Company[]>> {
    return this.http
      .get<Company[]>(`${environment.API_URL}/companies${query}`, {observe: 'response'})
        .pipe(
          map(response => {
          return response;
        }),
        catchError( (error) => this.errorService.handleError(error))
      );
  }

  findById(id: string): Observable<HttpResponse<Company>> {
    return this.http
      .get<Company>(`${environment.API_URL}/companies/${id}`, {observe: 'response'})
        .pipe(
          map(response => {
          return response;
        }),
        catchError( (error) => this.errorService.handleError(error))
      );
  }

  create(body: Company) {
    return this.http
      .post(`${environment.API_URL}/companies`, body, {observe: 'response'})
        .pipe(
          map(response => {
            return response
          }),
          catchError( (error) => this.errorService.handleError(error))
        );
  }

  update(body: any) {
    return this.http
      .patch(`${environment.API_URL}/companies/${body[0]}`, body[1], {observe: 'response'})
        .pipe(
          map(response => {
            return response;
          }),
          catchError( (error) => this.errorService.handleError(error))
        );
  }

  delete(id: string) {
    return this.http
      .delete(`${environment.API_URL}/companies/${id}`, {observe: 'response'})
        .pipe(
          map(response => {
            return response;
          }),
          catchError( (error) => this.errorService.handleError(error))
        );
  }

}
